import GenericAction from '@/components/actions/GenericAction.tsx'
import { ImagePlus } from 'lucide-react'
import { ITERATION_STATUSES } from '@/const/const.js'
import MImagePreview from '../molecules/actions/MImagePreview.tsx'
import AActionContentFiles from './components/AActionContentFiles.tsx'
import { getOutputVersion } from '@/lib/action-utils'

export default function ExtractBrandLogotypeAction({
  action,
  isLast = false,
  isInteractive = false,
}: {
  action: ExtractBrandLogotypeActionPayload
  isLast?: boolean
  isInteractive?: boolean
}) {
  const { path } = action.args

  const isDone = action.status === ITERATION_STATUSES.DONE

  const successOutput = action.outputs.find(isSuccessOutput)
  const successOutputContent =
    getOutputVersion(successOutput) === 2 ? successOutput?.content : successOutput

  return (
    <GenericAction
      action={action}
      detailsContent={JSON.stringify(action, null, 2)}
      actionIcon={ImagePlus}
      isLast={isLast}
      isInteractive={isInteractive}
      title={action.args.comment ?? 'Generate image'}
    >
      {isDone && successOutputContent && successOutputContent.public_url_small && (
        <MImagePreview
          src={successOutputContent.public_url_small}
          alt={action.args.comment}
          fileName={path}
          downloadUrl={successOutputContent.public_url_original}
          className="mb-2"
        />
      )}
      <AActionContentFiles content={path} />
    </GenericAction>
  )
}

export type ExtractBrandLogotypeActionPayload = {
  actionId: string
  args: {
    url: string
    path: string
    brand: string
    comment: string
  }
  elementId: string
  stepId: string
  startSnapshotHash: any
  name: 'extract_brand_logotype'
  obsolete: boolean
  subtype: any
  index: number
  iterationId: string
  hideArgsFromPrompt: boolean
  id: string
  endSnapshotHash: any
  type: 'function'
  startCommitHash: string
  endCommitHash: string
  status: string
  outputs: ExtractLogotypeActionOutput[]
  createdAt: {
    _seconds: number
    _nanoseconds: number
  }
  endedAt: number
  startedAt: number
  elapsedTime: number
  updatedAt: {
    _seconds: number
    _nanoseconds: number
  }
  elementIndex: number
  stepIndex: number
  repoURI?: string
}

type FailedOutput = {
  error: unknown
  image_path: null
  public_url_large?: null
  public_url_small?: null
  public_url_original?: null
}

type SuccessOutput = {
  error: null
  path: string
  public_url_large: string
  public_url_small: string
  public_url_original: string
}

type ExtractLogotypeActionOutput = FailedOutput | SuccessOutput | null

function isSuccessOutput(output: ExtractLogotypeActionOutput): output is SuccessOutput {
  if (getOutputVersion(output) === 2) {
    return output?.status === 'success'
  } else {
    return output?.error === null
  }
}
