import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'
import { useLayoutEffect } from 'react'

const sizingVariants = cva(
  cn('min-h-[60px]', ' *:px-3 *:py-2 *:text-sm', 'after:px-3 after:py-2 after:text-sm'),
  {
    variants: {
      variant: {
        default: cn('*:border border-input', 'after:border'),

        ghost: cn('*:border-0 focus-visible:ring-0', 'after:border-0'),
      },
      resize: {
        true: '',
        false: 'resize-none',
      },
      autogrow: {
        true: '',
        false: 'after:invisible',
      },
    },
    defaultVariants: {
      variant: 'default',
      resize: true,
      autogrow: false,
    },
  }
)

const textareaVariants = cva(
  cn(
    'w-full outline-none rounded-md bg-transparent placeholder:text-muted-foreground focus:outline-none focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50'
  ),
  {
    variants: {
      variant: {
        default: cn('border border-input shadow-sm focus-visible:ring-1 focus-visible:ring-ring'),

        ghost: cn('border-0 focus-visible:ring-0', 'after:border-0'),
      },
      resize: {
        true: '',
        false: 'resize-none',
      },
    },
    defaultVariants: {
      variant: 'default',
      resize: true,
    },
  }
)

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    VariantProps<typeof textareaVariants> {
  autogrow?: boolean
}

/*
  Textarea has a wrapper, because we want to have it grow automatically.
  This is done via trick involving a pseudo-element that is invisible.
  When the textarea is autogrowing, we set the value of the textarea to the value of the pseudo-element.
  
  IMPORTANT: The pseudo-element must have the same padding/border and font-size as the textarea.
  That pseudoelement is basically a carbon copy of the textarea. Hence there's a dedicated `sizingVariants` config.
 */

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, variant, resize, autogrow, onChange, onInput, ...props }, ref) => {
    const [value, setValue] = React.useState(() => props.value ?? '') // could be uncontrolled

    // we need to track value of the input so we can shove it into the data-replicated-value attribute and then in the pseudo-element
    const onChangeWrapper = React.useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value)
        onChange?.(e)
      },
      [onChange, setValue]
    )

    const onInputWrapper = React.useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value)
        onInput?.(e)
      },
      [onInput, setValue]
    )

    useLayoutEffect(() => {
      if (autogrow && typeof ref === 'object') {
        const textarea = ref?.current
        if (textarea) {
          setValue(textarea.value)
        }
      }
    }, [autogrow, ref, props.value])

    useLayoutEffect(() => {
      if (props.value) {
        setValue(props.value)
      }
    }, [props.value])

    return (
      <div
        className={cn(
          autogrow && 'textarea-grow-wrap',
          sizingVariants({ variant, resize, autogrow })
        )}
        data-replicated-value={value}
      >
        <textarea
          className={cn(textareaVariants({ variant, resize }), className)}
          ref={ref}
          {...props}
          onChange={onChangeWrapper}
          onInput={onInputWrapper}
        />
      </div>
    )
  }
)
Textarea.displayName = 'Textarea'

export { Textarea }
