import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/16/solid'

import { Button } from '@/components/catalyst/button'

import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics'
import { useSearchParams } from 'react-router-dom'
import { formatIndex, safeParseInt } from '@/lib/params-utils.js'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import MStepsList from '@/components/molecules/iteration-details/MStepList.jsx'
import { cn } from '@/lib/utils.js'
import { ELEMENT_STATUSES } from '@/const/const'
import Logo from '@/assets/svg-components/Logo.jsx'
import { AlarmClock, XOctagon } from 'lucide-react'
import colors from 'tailwindcss/colors.js'

export function MElementListItem({
  element,
  expandAllDetails = false,
  filterOutLLMActions = true,
  isInteractive = false,
  children
}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedElementIndex = safeParseInt(searchParams.get('element'))
  const stepsCount = useMemo(() => {
    return Object.keys(element?.steps ?? {}).length
  }, [element.steps])

  const isDone = useMemo(() => {
    return element.status === 'DONE'
  }, [element])


  const [elementStatusComponent, textColor, backgroundColor] = useMemo(() => {
    if (isInteractive) {
      return [
        <ElementStatusWaiting key="element-status-waiting" />,
        'orange-500',
        'transparent',
      ]
    }
    switch (element.status) {
      case ELEMENT_STATUSES.AWAITING:
        return [
          <ElementStatusAwaiting key="element-status-awaiting" index={element.index} />,
          'zinc-500',
          'transparent',
        ]
      case ELEMENT_STATUSES.RUNNING:
        return [<ElementStatusRunning key="element-status-running" />, 'violet-500', 'white']
      case ELEMENT_STATUSES.DONE:
        return [
          <ElementStatusDone key="element-status-done" index={element.index} />,
          'green-500',
          'transparent',
        ]
      case ELEMENT_STATUSES.FAILED:
        return [<ElementStatusFailed key="element-status-failed" />, 'red-500', 'transparent']
      default:
        return [
          <ElementStatusAwaiting key="element-status-default" index={element.index} />,
          'zinc-500',
          'transparent',
        ]
    }
  }, [element.status, element.index, isInteractive])

  const [showSteps, setShowSteps] = useState(
    expandAllDetails || !isDone || selectedElementIndex === element?.index
  )
  const handleShowAllSteps = useCallback(() => {
    if (!showSteps) {
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_EXPAND_STAGE, {
        iterationId: element?.iterationId,
        elementIndex: element?.index,
        elementStatus: element?.status || 'N/A',
      })
    }

    setShowSteps(!showSteps)

    setSearchParams(
      prevParams => {
        const newParams = new URLSearchParams(prevParams)
        if (element?.index) {
          newParams.set('element', element.index.toString())
        } else {
          newParams.delete('element')
        }
        newParams.delete('step')
        newParams.delete('action')
        return newParams
      },
      { replace: false }
    )
  }, [element, showSteps])

  return (
    <Collapsible
      open={showSteps}
      onOpenChange={handleShowAllSteps}
      data-index={element.index}
      className=""
    >
      <CollapsibleTrigger asChild>
        <div
          className={cn(
            'grid w-[800px] grid-cols-[64px,1fr,max-content] items-center justify-start gap-4 rounded-lg border p-1 pr-4',
            isInteractive ? 'border-orange-200' : 'border-border',
            {
              'mb-2 bg-white': showSteps,

            }
          )}
        >
          {/* <div className="flex h-full w-full items-center justify-between space-x-4 py-1"> */}
          {elementStatusComponent}
          <div
            className={cn(
              'inline-flex  shrink grow basis-0 cursor-pointer flex-col items-start justify-center gap-1 overflow-hidden py-4'
            )}
          >
            <div
              className="w-full truncate font-['Inter'] text-base  font-medium leading-normal text-stone-900"
              title={element.name}
            >
              {element.name}
            </div>
            {showSteps && (
              <div className="line-clamp-2 w-full overflow-hidden text-ellipsis font-['Inter'] text-sm font-normal leading-tight text-stone-500">
                {element.description}
              </div>
            )}
          </div>

          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center justify-center gap-2.5 rounded-md border border-stone-200 px-2 py-1">
              <div className="text-xs font-medium leading-none text-stone-500">
                {stepsCount === 1 ? '1 Step' : `${stepsCount} Steps`}
              </div>
            </div>

            {stepsCount > 0 && (
              <Button plain>{showSteps ? <ChevronUpIcon /> : <ChevronDownIcon />}</Button>
            )}
          </div>
          {/* </div> */}
        </div>
      </CollapsibleTrigger>

      <CollapsibleContent className="">
        <div className="w-full flex-grow rounded-lg bg-white">
          <MStepsList
            expandAllDetails={expandAllDetails}
            filterOutLLMActions={filterOutLLMActions}
          >
            {children}
          </MStepsList>
        </div>
      </CollapsibleContent>
    </Collapsible>
  )
}


function ElementStatusAwaiting({ index }) {
  return (
    <div className="flex min-h-16 items-center justify-center place-self-stretch rounded-md bg-stone-200">
      <div className="text-center font-pp-supply-sans text-2xl font-normal leading-9 text-stone-400">
        {formatIndex(index)}
      </div>
    </div>
  )
}

function ElementStatusRunning() {
  return (
    <div className="flex min-h-16 items-center justify-center place-self-stretch rounded-md bg-[#f8f3fe]">
      <div className="inline-flex shrink grow basis-0 flex-col items-center justify-center self-stretch">
        <Logo key="element-running-logo" color={colors.violet[500]} className="size-5" />
      </div>
    </div>
  )
}

function ElementStatusDone({ index }) {
  return (
    <div className="flex min-h-16 items-center justify-center place-self-stretch rounded-md bg-green-500/10">
      <span className="font-pp-supply-sans text-2xl leading-9 text-green-500">
        {formatIndex(index)}
      </span>
    </div>
  )
}

function ElementStatusFailed() {
  return (
    <div className="flex min-h-16 items-center justify-center place-self-stretch rounded-md bg-red-50">
      <XOctagon className="size-5 text-red-500" />
    </div>
  )
}


function ElementStatusWaiting() {
  return (
    <div className="flex min-h-16 items-center justify-center place-self-stretch rounded-md bg-orange-50">
      <AlarmClock className="size-5 text-orange-500" />
    </div>
  )
}

export default function MElementsList({
  children,
}) {
  const [searchParams] = useSearchParams()
  const selectedElementIndex = safeParseInt(searchParams.get('element'))
  const listRef = useRef(null)

  useEffect(() => {
    if (searchParams.get('step') != null) {
      return
    }
    if (selectedElementIndex !== null && listRef.current) {
      const selectedElement = listRef.current.querySelector(
        `[data-index="${selectedElementIndex}"]`
      )
      if (selectedElement) {
        selectedElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }, [])

  return (
    <div ref={listRef} className="inline-flex w-[800px] flex-col items-start justify-start gap-4">
      {children}
    </div>
  )
}

MElementsList.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      updatedAtRelative: PropTypes.string,
      stack: PropTypes.arrayOf(PropTypes.string),
      steps: PropTypes.object,
      elementId: PropTypes.string,
      index: PropTypes.number,
      repoURI: PropTypes.string,
      iterationId: PropTypes.string,
    })
  ),
  lastTick: PropTypes.number,
  expandAllDetails: PropTypes.bool,
  filterOutLLMActions: PropTypes.bool,
}

MElementListItem.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    updatedAtRelative: PropTypes.string,
    stack: PropTypes.arrayOf(PropTypes.string),
    steps: PropTypes.object,
    elementId: PropTypes.string,
    index: PropTypes.number,
    repoURI: PropTypes.string,
    iterationId: PropTypes.string,
  }),
  lastTick: PropTypes.number,
  isLast: PropTypes.bool,
  expandAllDetails: PropTypes.bool,
  filterOutLLMActions: PropTypes.bool,
}
