import useStore from '@/stores/useStore.js'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  authorizeGithub,
  installGithub,
  fetchGithubRepositories,
  getIterationDefault,
  pushToGithub,
} from '@/services/Firebase'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAnalystEnvFields } from './useAnalystEnvFields.ts'
import { ConfigurationTemplate, EnvironmentField } from './types.ts'
import { z } from 'zod'

export function useGithubRepos(login: string | undefined) {
  return useQuery({
    queryKey: ['github-repos', login],
    enabled: typeof login === 'string',
    queryFn: async () => {
      if (!login) {
        throw new Error('Could not fetch repositories: missing login.')
      }
      const response = await fetchGithubRepositories({ login })
      return response.data
    },
    staleTime: 5_000,
  })
}

export function useConnectGithub() {
  const urlResult = useFetchGithubAuthorizationUrl()
  const updatedAt = useStore(state => state.user.updatedAt)
  const [lastUpdatedAt, setUpdatedAt] = useState(updatedAt)
  const popup = useAuthPopup()

  const onConnectGithub = useCallback(() => {
    if (!urlResult.data) {
      throw new Error('should be only able to run if data is present')
    }
    popup.openPopup(urlResult.data)
    setUpdatedAt(updatedAt)
  }, [urlResult, updatedAt, popup.openPopup])

  useEffect(() => {
    if (popup.isOpen && lastUpdatedAt !== updatedAt) {
      // user has been updated, i.e. auth completed.
      popup.closePopup()
    }
  }, [updatedAt, lastUpdatedAt, popup.isOpen, popup.closePopup])

  return onConnectGithub
}

export function useFetchGithubAuthorizationUrl() {
  const user = useStore(state => state.user)
  return useQuery({
    queryKey: ['github-auth-url', user.uid],
    queryFn: async () => {
      const response = await authorizeGithub()
      return response.data.installationUrl
    },
    staleTime: 600_000,
  })
}

export function useFetchGithubInstallationUrl() {
  const user = useStore(state => state.user)
  return useQuery({
    queryKey: ['github-install-url', user.uid],
    queryFn: async () => {
      const response = await installGithub()
      return response.data.installationUrl
    },
    staleTime: 3600_000,
  })
}

export function useInstallGithub() {
  const urlResult = useFetchGithubInstallationUrl()
  const popup = useAuthPopup()

  const onInstallGithub = useCallback(() => {
    if (!urlResult.data) {
      throw new Error('should be only able to run if data is present')
    }
    popup.openPopup(urlResult.data)
  }, [urlResult, popup.openPopup])

  return onInstallGithub
}

export function useAuthPopup() {
  const [popup, setPopup] = useState<Window | null>(null)

  const closePopup = useCallback(() => {
    if (popup && !popup.closed) {
      popup.close()
    }
  }, [popup])

  const openPopup = useCallback(
    (url: string) => {
      const popup = window.open(
        url,
        'Authorize Proofs App',
        'popup=yes,menubar=no,location=yes,width=800,height=600'
      )
      setPopup(popup)
    },
    [setPopup]
  )

  return { closePopup, openPopup, isOpen: Boolean(popup && !popup.closed) }
}

export function useEnvironmentFields(template: ConfigurationTemplate | null) {
  const adminFields = useAnalystEnvFields()

  const fields = useMemo(
    () => [...adminFields, ...(template?.iterationDefaultsTemplate?.environment?.fields ?? [])],
    [adminFields, template?.iterationDefaultsTemplate?.environment?.fields]
  )

  return fields
}

export function useEnvironmentValidator(fields: EnvironmentField[]) {
  const validator = useMemo(
    () =>
      z.object(
        fields.reduce((acc, field) => {
          const fieldSchema = z.string()
          return {
            ...acc,
            [field.key]: field.required ? fieldSchema.min(1) : fieldSchema.optional(),
          }
        }, {})
      ),
    [fields]
  )

  return validator
}

type Project = {
  id: string

  projectConfiguration: {
    name: string
    prospectName: string
    prospectWebsite: string
    techStack: string
  }
}

export const useProjectForStack = (techStack: string | null): null | Project => {
  const latestProject = useMemo(() => {
    if (!techStack) {
      return null
    }
    const store = useStore.getState()
    const currentUser = store.user
    const projects = store.projects

    return projects
      .filter(
        project =>
          project.projectConfiguration?.techStack === techStack &&
          project.createdBy === currentUser.id
      )
      .sort((a, b) => b.createdAt - a.createdAt)[0]
  }, [techStack])

  return latestProject
}

export const useProjectEnv = (projectId: string | null) => {
  const { isLoading, isError, data } = useQuery({
    queryKey: ['iteration-defaults', projectId],
    enabled: !!projectId,
    queryFn: async () => {
      if (!projectId) return null
      const res = await getIterationDefault({ projectId })
      return res.data
    },
  })

  const envValues = useMemo(
    () =>
      // Object.entries(data?.environment ?? {}).reduce(
      //   (acc, [key, value]) => ({ ...acc, [key]: value.value }),
      //   {}
      // ),
      data?.environment ?? {},
    [data]
  )

  return { isLoading, isError, data: envValues }
}

export function useConnectGithubToProject({
  projectId,
  iterationId,
}: {
  projectId: string
  iterationId: string
}) {
  return useMutation({
    mutationFn: (data: GitHubFormData) =>
      pushToGithub({ username: data.username, repoURI: data.repoURI, projectId, iterationId }),
  })
}

export const linkGithubToProjectSchema = z.object({
  username: z.string(),
  repoURI: z.string(),
})

export type GitHubFormData = z.infer<typeof linkGithubToProjectSchema>
