import React, { useMemo, useState } from 'react'
import useStore from '@/stores/useStore'

import { Link } from '@/components/catalyst/link'

import { ROUTES } from '@/const/routes'
import { stackTemplate } from '@/const/stackTemplates.ts'
import { ITERATION_STATUSES } from '@/const/const'
import {
  AlarmClock,
  CheckCheck,
  Loader,
  PauseCircle,
  TimerReset,
  TvMinimal,
  XOctagon,
} from 'lucide-react'
import Logo from '@/assets/svg-components/Logo.jsx'
import colors from 'tailwindcss/colors.js'
import { getPreviewUrl } from '@/lib/git-utils.js'
import { useNavigate } from 'react-router-dom'
import {
  formatIterationStatus,
  getIterationComputedStatus,
  isVmDead,
  isVMreadyForPreview,
} from '@/lib/iteration-utils.ts'
import { MProjectPrompt } from '../molecules/MProjectPrompt.tsx'
import { cn } from '@/lib/utils.ts'
import { Checkbox } from '../ui/checkbox.tsx'
import ALogoSpinner from '../atoms/ALogoSpinner.tsx'

export default function PHome() {
  const projects = useStore(state => state.projects)
  const user = useStore(state => state.user)

  const organizations = useStore(state => state.organizations)
  const isAfterInitialLoad = useStore(state => state.isAfterInitialLoad)
  const getProjectBrokenDownByOrganization = useStore(
    state => state.getProjectBrokenDownByOrganization
  )
  const navigate = useNavigate()

  const projectsByOrganization = useMemo(() => {
    if (projects && projects.length > 0 && organizations && organizations.length > 0) {
      return getProjectBrokenDownByOrganization()
    }
    return null
  }, [projects, getProjectBrokenDownByOrganization, organizations])

  const [filterMyProjects, setFilterMyProjects] = useState(true)

  if (!isAfterInitialLoad) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white/50">
        <ALogoSpinner className="size-8" />
      </div>
    )
  }

  const handleCardClick = (e, project) => {
    // Ignore clicks on or within interactive elements
    if (e.target.closest('button, [role="button"], a, [role="menu"], [role="menuitem"]')) {
      return
    }
    navigate(ROUTES.PROJECT_DETAILS.replace(':projectId', project.id))
  }

  return (
    <>
      <div className="flex w-[800px] flex-col gap-8 py-8">
        <div className="my-20">
          <MProjectPrompt />
        </div>
        {projectsByOrganization && projectsByOrganization
          .filter(
            organization =>
              organization.projects.length > 0 &&
              (filterMyProjects
                ? organization.projects.some(project => project.createdBy === user?.id)
                : true)
          )
          .map(organization => (
            <div
              key={organization.id}
              className="inline-flex w-full flex-col items-center justify-center gap-8 "
            >
              <div className="inline-flex w-full items-start justify-start gap-1">
                <div className="font-pp-supply-sans text-3xl font-normal leading-10 text-stone-900">
                  {projectsByOrganization.length === 1 ? 'Projects' : organization.name}
                </div>
                <div className="font-pp-supply-sans text-sm font-normal leading-none text-stone-500">
                  [{organization.projects.length}]
                </div>
                <label className="ml-auto flex cursor-pointer items-center gap-2 place-self-center text-secondary-foreground">
                  <span>Show my projects</span>
                  <Checkbox
                    checked={filterMyProjects}
                    onCheckedChange={() => setFilterMyProjects(!filterMyProjects)}
                  />
                </label>
              </div>

              <div className="flex w-full flex-col items-start justify-start gap-2">
                {organization.projects
                  .filter(project => (filterMyProjects ? project.createdBy === user?.id : true))
                  .map(project => {
                    const TechnologyIcon =
                      stackTemplate[project.projectConfiguration?.techStack]?.icon
                    const { status, vmStatus, active_role } =
                      project.iterations?.lastIteration ?? {}

                    const lastIterationStatus = getIterationComputedStatus(
                      status,
                      vmStatus,
                      active_role
                    )
                    const lastIterationPreview =
                      project.iterations?.lastIteration?.preview_urls ?? ''
                    const previewUrl = getPreviewUrl(lastIterationPreview)
                    const [iterationStatus, statusColor, statusIcon] =
                      getIterationInfo(lastIterationStatus)
                    return (
                      <div
                        key={project.id}
                        className="flex w-full cursor-pointer items-center justify-start gap-6 self-stretch rounded-lg border border-border bg-white p-6"
                        onClick={e => handleCardClick(e, project)}
                      >
                        <div className="flex h-8 shrink grow basis-0 items-start justify-start gap-0.5">
                          <Link
                            className={cn(
                              'truncate font-pp-supply-sans text-lg font-normal leading-loose',
                              project.name ? 'text-primary' : 'text-muted-foreground'
                            )}
                            title={project.name}
                            href={ROUTES.PROJECT_DETAILS.replace(':projectId', project.id)}
                          >
                            {project.name || 'Draft project'}
                          </Link>
                        </div>
                        <div className="flex h-5 w-[160px] max-w-[160px] items-center justify-start gap-2">
                          <div className="font-['Inter'] text-sm font-normal leading-tight text-stone-500">
                            {project.projectConfiguration?.prospectName}
                          </div>
                        </div>
                        <div className="flex h-8 w-[28px] max-w-[28px] items-start justify-start">
                          <div className="flex items-center justify-center rounded-full border border-stone-200 bg-white p-2">
                            <div className="flex size-4 items-center justify-center px-px">
                              <div className="relative flex h-4 w-3.5 flex-col items-center justify-start">
                                {TechnologyIcon && <TechnologyIcon />}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mr-auto flex h-8 items-center justify-end gap-2">
                          <div className="flex w-[108px] items-center justify-end gap-2 rounded-md p-2">
                            <div
                              className={`font-pp-supply-sans text-xs font-normal uppercase leading-none text-${statusColor} text-right`}
                            >
                              {iterationStatus}
                            </div>
                            <div className="relative size-4">{statusIcon}</div>
                          </div>
                          <IterationPreview
                            previewUrl={previewUrl}
                            lastIterationStatus={lastIterationStatus}
                            vmStatus={vmStatus}
                          />
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          ))}
      </div>
    </>
  )
}

function IterationPreview({ lastIterationStatus, previewUrl, vmStatus }) {
  if (lastIterationStatus === ITERATION_STATUSES.RUNNING) {
    return (
      <div className="inline-flex size-8 items-center justify-center gap-2 rounded-md bg-stone-900 px-4 py-2 opacity-50">
        <div className="flex size-4 items-center justify-center p-px">
          <Loader className="text-stone-50" />
        </div>
      </div>
    )
  } else if (previewUrl && isVMreadyForPreview(vmStatus)) {
    return (
      <Link
        href={previewUrl}
        target={'_blank'}
        className="flex size-8 items-center justify-center gap-2 rounded-md bg-stone-900 px-4 py-2"
        onClick={() => {
          // analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CLICK_PREVIEW, {
          //   iterationId: selectedIteration?.id,
          //   projectId: selectedIteration?.projectId,
          //   teamId: selectedIteration?.teamId,
          //   organizationId: selectedIteration?.organizationId,
          //   status: selectedIteration?.status,
          //   vmStatus: selectedIteration?.vmStatus,
          // })
        }}
      >
        <div className="flex size-4 items-center justify-center p-px">
          <TvMinimal className="text-white" />
        </div>
      </Link>
    )
  } else if (isVmDead(vmStatus)) {
    return (
      <div
        className="flex size-8 items-center justify-center gap-2 rounded-md bg-stone-900 px-4 py-2 opacity-50"
        title="Preview lifespan has expired"
      >
        <div className="flex size-4 items-center justify-center px-px py-[1.50px]">
          <TvMinimal className="text-white" />
        </div>
      </div>
    )
  } else {
    return (
      <div className="flex size-8 items-center justify-center gap-2 rounded-md bg-stone-900 px-4 py-2 opacity-50">
        <div className="flex size-4 items-center justify-center px-px py-[1.50px]">
          <TvMinimal className="text-white" />
        </div>
      </div>
    )
  }
}

function getIterationInfo(activeIterationStatus) {
  switch (activeIterationStatus) {
    case ITERATION_STATUSES.AWAITING:
      return [
        'Awaiting',
        'orange-500',
        <AlarmClock key="iteration-status-awaiting-icon" className="size-4 text-orange-500" />,
      ]
    case ITERATION_STATUSES.RUNNING:
      return [
        'Running',
        'violet-500',
        <Logo key="iteration-status-running-icon" color={colors.violet[500]} className="size-4" />,
      ]
    case ITERATION_STATUSES.PAUSED:
      return [
        'Paused',
        'zinc-500',
        <PauseCircle key="iteration-status-paused-icon" className="size-4 text-zinc-500" />,
      ]
    case ITERATION_STATUSES.DONE:
      return [
        'Done',
        'green-500',
        <CheckCheck key="iteration-status-done-icon" className="size-4 text-green-500" />,
      ]
    case ITERATION_STATUSES.FAILED:
      return [
        'Failed',
        'red-500',
        <XOctagon key="iteration-status-failed-icon" className=" size-4 text-red-500" />,
      ]
    case ITERATION_STATUSES.RUN_REQUESTED:
      return [
        'Requested',
        'zinc-500',
        <TimerReset key="iteration-status-requested-icon" className="size-4 text-zinc-500" />,
      ]
    default:
      return [
        formatIterationStatus(activeIterationStatus),
        'zinc-500',
        <Logo key="iteration-status-running-icon" color={colors.zinc[500]} className="size-4" />,
      ]
  }
}
