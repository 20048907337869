import { ELEMENT_STATUSES } from '@/const/const.ts'
import { getActiveRole } from '@/lib/iterations/useIterationActiveRole.ts'
import { cn } from '@/lib/utils.ts'
import { IterationElement } from '@/services/firebase_shared_types.ts'
import { ChevronDown } from 'lucide-react'
import { useState } from 'react'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import { useIsUserSuperAdmin } from '@/stores/useStore'
import { Button } from '@/components/ui/button.tsx'
import { IconStatus, MStatusIconBox, ROLE_REGISTRY_STATUS } from './MStatusIconBox.tsx'

export function MElement({ element }: MCompactElementProps) {
  const isSuperAdmin = useIsUserSuperAdmin()
  const [isExpanded, setIsExpanded] = useState(false)
  const roleRegistry = getActiveRole(element.role_registry)
  const isActiveRole = roleRegistry?.status === 'waiting_for_message'
  const status = (isActiveRole ? ROLE_REGISTRY_STATUS : element.status) as IconStatus

  if (!isSuperAdmin && element.status === ELEMENT_STATUSES.AWAITING) {
    return null
  }
  return (
    <Collapsible
      open={isExpanded}
      onOpenChange={setIsExpanded}
      className={cn(
        'animate-appear w-full rounded border text-base-foreground transition-colors',
        isExpanded ? 'breder-border bg-white' : 'bg-transparents border-transparent'
      )}
    >
      <CollapsibleTrigger asChild>
        <div className="flex w-full cursor-pointer flex-row items-center gap-4 p-1">
          <MStatusIconBox status={status} />
          <div className="flex flex-1 items-center justify-between">
            <h3 className="">
              <span
                className={cn(
                  'font-pp-supply-sans',
                  status === ELEMENT_STATUSES.FAILED ? 'text-red-400' : 'text-muted-foreground'
                )}
              >
                {element.index}.
              </span>{' '}
              <span
                className={cn(
                  'text-sm font-medium',
                  status === ELEMENT_STATUSES.FAILED
                    ? 'text-red-500'
                    : isExpanded || status === ELEMENT_STATUSES.RUNNING
                      ? 'text-base-foreground'
                      : 'text-stone-500'
                )}
              >
                {element.name}
              </span>
            </h3>
            {element.description && (
              <Button variant="ghost" size="icon">
                <ChevronDown
                  className={cn(
                    'size-4 text-muted-foreground transition-transform',
                    isExpanded && 'rotate-180'
                  )}
                />
              </Button>
            )}
          </div>
        </div>
      </CollapsibleTrigger>

      {element.description && (
        <CollapsibleContent>
          <div className="px-5 py-4 text-sm leading-tight">{element.description}</div>
        </CollapsibleContent>
      )}
    </Collapsible>
  )
}

type MCompactElementProps = {
  element: IterationElement
}
