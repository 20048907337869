import { INTERACTIVE_CHAT_ROLES } from '@/const/const.ts'
import {
  RoleRegistryWithContext,
  useAggregatedRoleRegistry,
} from '@/lib/iterations/useAggregatedRoleRegistry.ts'
import { useActiveRole } from '@/lib/iterations/useIterationActiveRole.ts'
import { RoleRegistry } from '@/services/firebase_shared_types.ts'
import { checkIsRoleActive } from '@/lib/iteration-utils.ts'
import { createContext, useContext, useMemo, useState } from 'react'

type RollbackAction = {
  id: string
  index: number
  elementIndex?: number
  stepIndex?: number
}

const InteractiveStateContext = createContext<{
  iterationRole: RoleRegistry[string] | null
  elementRole: RoleRegistryWithContext[string] | null
  stepRole: RoleRegistryWithContext[string] | null
  isIterationRoleActive: boolean
  isElementRoleActive: boolean
  isStepRoleActive: boolean
  rollbackAction: RollbackAction | null
  setRollbackAction: (action: RollbackAction | null) => void
}>({
  iterationRole: null,
  elementRole: null,
  stepRole: null,
  isIterationRoleActive: false,
  isElementRoleActive: false,
  isStepRoleActive: false,
  rollbackAction: null,
  setRollbackAction: () => {},
})

export function InteractiveStateContextProvider({
  children,
  iterationId,
}: {
  children: React.ReactNode
  iterationId: string | null
}) {
  const registries = useAggregatedRoleRegistry(iterationId)

  const iterationRole = useActiveRole(registries.iterationRegistry, INTERACTIVE_CHAT_ROLES)
  const elementRole = useActiveRole(registries.elementRegistry, INTERACTIVE_CHAT_ROLES)
  const stepRole = useActiveRole(registries.stepRegistry, INTERACTIVE_CHAT_ROLES)

  const [rollbackAction, setRollbackAction] = useState<RollbackAction | null>(null)

  const contextValue = useMemo(() => {
    const isIterationRoleActive = checkIsRoleActive(iterationRole)
    const isElementRoleActive = checkIsRoleActive(elementRole)
    const isStepRoleActive = checkIsRoleActive(stepRole)
    const isRoleActive = isIterationRoleActive || isElementRoleActive || isStepRoleActive
    return {
      iterationRole,
      elementRole,
      stepRole,
      isRoleActive,
      isIterationRoleActive,
      isElementRoleActive,
      isStepRoleActive,
      rollbackAction,
      setRollbackAction,
    }
  }, [iterationRole, elementRole, stepRole, rollbackAction])

  return (
    <InteractiveStateContext.Provider value={contextValue}>
      {children}
    </InteractiveStateContext.Provider>
  )
}

export function useInteractiveStateContext() {
  const context = useContext(InteractiveStateContext)
  if (!context) {
    throw new Error(
      'useInteractiveStateContext must be used within a InteractiveStateContextProvider'
    )
  }
  return context
}
