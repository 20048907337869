import { useQuery } from '@tanstack/react-query'
import { roleRepository } from '@/services/Firebase.ts'
import { onSnapshot, getDocs, Timestamp } from 'firebase/firestore'
import { useEffect } from 'react'

export const useRoleHistoryQuery = (iterationId: string | null, role: string | null) => {
  const query = useQuery({
    enabled: Boolean(iterationId && role),
    queryKey: ['iteration-role-history', iterationId, role],
    queryFn: async () => {
      if (!iterationId || !role) {
        throw new Error('iterationId is required')
      }

      const result = await getDocs(roleRepository(iterationId, role))
      const data: RoleHistoryEntry[] = result.docs
        .map(doc => doc.data() as RoleHistoryEntryInput)
        .sort((a, b) => a.index - b.index)
        .map(item => ({
          ...item,
          createdAt: new Date(item.createdAt.seconds * 1000),
          updatedAt: new Date(item.updatedAt.seconds * 1000),
        }))
      return data
    },
  })
  useLiveRoleHistory(iterationId, role, query.refetch)
  return query
}

function useLiveRoleHistory(iterationId: string | null, role: string | null, onChange: () => void) {
  useEffect(() => {
    if (!iterationId || !role) {
      return
    }
    const unsubscribe = onSnapshot(
      roleRepository(iterationId, role),
      snapshot => {
        snapshot.docChanges() // whatever, we just want to refetch.
        onChange()
      },
      error => {
        console.error(
          '[Error] listener encountered an error when querying live role history:',
          error.code,
          error.message
        )
      }
    )

    return unsubscribe
  }, [iterationId, role, onChange])
}

type RoleHistoryEntryInput = {
  role: string
  updatedAt: Timestamp
  createdAt: Timestamp
  existing: string | { markdown: string }
  request: string
  output: string | { markdown: string }
  id: string
  index: number
}

export type RoleHistoryEntry = {
  role: string
  updatedAt: Date
  createdAt: Date
  // it probably depends on the role, but we use the analyst for now.
  existing: AnalystOutput
  request: string
  output: AnalystOutput
  id: string
  index: number
}
type AnalystOutput =
  | string
  | { markdown: string; specifications: undefined; update_summary?: string }
  | { specifications: string; markdown: undefined; update_summary?: string }
