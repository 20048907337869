import { useMemo } from 'react'
import { RoleRegistry } from '@/services/firebase_shared_types.ts'
import { useIterationQuery } from './useIterationQuery.ts'
import { IterationRole } from '@/const/const'

// return active role of an iteration based on its status and name
export const useIterationActiveRole = (
  iterationId: string | null,
  rolesToListen?: IterationRole[]
) => {
  const { data: iteration } = useIterationQuery(iterationId)

  const role = useActiveRole(iteration?.role_registry ?? null, rolesToListen)

  return role
}

export function getActiveRole(role_registry: RoleRegistry | null, rolesToListen?: IterationRole[]) {
  const registry = role_registry ?? {}
  const roles = Object.values(registry).filter(role =>
    rolesToListen ? rolesToListen?.includes(role.name as IterationRole) : true
  )
  const activeRole = roles.find(role => role.status !== 'done') ?? roles[0]
  if (!activeRole) {
    return null
  }

  return activeRole
}

export const useActiveRole = (registry: RoleRegistry | null, rolesToListen?: IterationRole[]) => {
  const role = getActiveRole(registry, rolesToListen)

  return useMemo(() => {
    return role
  }, [role?.name, role?.status, role?.turn?.label])
}
