import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.tsx'
import { Search } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'
import { MarkdownContent } from '@/components/actions/components/MarkdownContent.tsx'
import { getOutputVersion } from '@/lib/action-utils'

export default function ResearchAction({ action, isLast = false, isInteractive = false }) {
  let detailsContent = ''
  let content = ''

  const question = action?.args?.question
  if (question) {
    detailsContent += `## QUESTION:\n\n${question}`
  }

  action?.outputs?.forEach(output => {
    const outputContent = getOutputVersion(output) === 2 ? output.content : output
    if (outputContent?.content) {
      content += `${outputContent.content} \n\n`
    }
  })

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={Search}
      isLast={isLast}
      isInteractive={isInteractive}
      title={action.args?.comment || 'Research'}
    >
      {content && (
        <AActionContentGenericLayout>
          <MarkdownContent detailsContent={content} />
        </AActionContentGenericLayout>
      )}
    </GenericAction>
  )
}

ResearchAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
  isInteractive: PropTypes.bool,
}
