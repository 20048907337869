import { useCallback, useEffect } from 'react'

import { Button } from '@/components/ui/button'
import { ArrowUp, Check } from 'lucide-react'
import { useIterationQuery } from '@/lib/iterations/useIterationQuery.ts'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import FormField from '@/components/ui/form-field.tsx'
import { ABorderBox } from '@/components/atoms/ABorderBox.tsx'
import ALogoSpinner from '@/components/atoms/ALogoSpinner.tsx'
import { useAnalystFlow } from '@/lib/iterations/useAnalystFlow.ts'
import { MarkdownContent } from '@/components/actions/components/MarkdownContent.tsx'
import { cn } from '@/lib/utils.ts'

type MContinuationChatV2Props = {
  iterationId: string
  className?: string
}

export default function MContinuationChatV2({ iterationId, className }: MContinuationChatV2Props) {
  const { userCommandMutation, isLoadingUserCommand, role } = useAnalystFlow(iterationId)

  const isLoading = userCommandMutation.isLoading

  const isDone = !role || role.status === 'done'

  const form = useForm({
    resolver: zodResolver(promptSchema),
    defaultValues: {
      prompt: '',
    },
  })

  const promptValue = form.watch('prompt')
  const isRequestingChanges = promptValue.trim().length > 0

  const onSubmit = useCallback(
    ({ prompt }: PromptFormData) => {
      if (isDone) {
        userCommandMutation.extend(prompt)
      } else if (isRequestingChanges) {
        userCommandMutation.mutate(prompt)
      } else {
        userCommandMutation.validate()
      }
    },
    [userCommandMutation, isDone, isRequestingChanges]
  )

  useEffect(() => {
    if (!isLoadingUserCommand) {
      form.setValue('prompt', '')
    }
  }, [isLoadingUserCommand, form])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
        e.preventDefault()
        if (form.formState.isValid && !isLoadingUserCommand) {
          form.handleSubmit(onSubmit)()
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [form.formState.isValid, isLoadingUserCommand, form.handleSubmit, onSubmit])

  const Icon = isLoadingUserCommand ? ALogoSpinner : isRequestingChanges || isDone ? ArrowUp : Check
  // isDone as in: the iteration is done. So either we don't have a role or role is done.

  return (
    <div className={className}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="">
        <ABorderBox
          isEnabled={isLoading}
          borderClasname="text-border"
          className="rounded-md bg-white"
        >
          <FormField.Root control={form.control} name="prompt">
            <FormField.Textarea
              {...form.register('prompt')}
              autogrow
              disabled={isLoadingUserCommand}
              variant="ghost"
              className="resize-none bg-white shadow-none focus-visible:ring-0"
              placeholder={
                isDone
                  ? 'What would you like to do next?'
                  : 'Give feedback or accept specifications...'
              }
            />
          </FormField.Root>
          <div className="p-4 text-right">
            <Button className="ml-auto" disabled={isLoadingUserCommand} type="submit">
              <Icon className="size-4" />
              {isDone ? 'Build' : isRequestingChanges ? 'Request changes' : 'Accept specifications'}
            </Button>
          </div>
        </ABorderBox>
      </form>
    </div>
  )
}

export function ProposedSpecifications({ iterationId }: { iterationId: string }) {
  const iteration = useIterationQuery(iterationId)

  // todo actually use specs from
  const currentSpecifications = iteration.data?.specifications ?? ''

  return (
    <div
      key={currentSpecifications}
      // max-h-[3000px] is tricky! it's so we can animate the height from 0, but it's going to bite us if it goes over it.
      className="max-h-[3000px] translate-y-0 opacity-100 transition-all duration-1000 starting:max-h-0 starting:translate-y-4 starting:opacity-0"
    >
      <h3 className="mb-2 text-xl tracking-tighter text-base-foreground">Proposed solution:</h3>
      <MarkdownContent detailsContent={currentSpecifications} />
    </div>
  )
}

const promptSchema = z.object({
  prompt: z.string().trim().default(''),
})
type PromptFormData = z.output<typeof promptSchema>
