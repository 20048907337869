import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.tsx'
import { Lightbulb } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'
import AActionContentFiles from '@/components/actions/components/AActionContentFiles.tsx'

export default function HintsAction({ action, isLast = false, isInteractive = false }) {
  const hints = action?.outputs || []
  let detailsContent = ''

  // check if hints is an array

  if (Array.isArray(hints)) {
    hints.forEach((hint, index) => {
      const { title, value, content_type, ...rest } = hint
      detailsContent += `## --- <Hint ${index + 1} ${title}> ---\n\n`
      detailsContent += `### Hint value:\n\n`
      detailsContent += '```' + content_type + '\n'
      detailsContent += value + '\n```\n\n'
      detailsContent += `### Hint params:\n\n`
      detailsContent += '```json\n'
      detailsContent += JSON.stringify(rest, null, 2)
      detailsContent += '\n```\n\n'
      detailsContent += `## --- </Hint ${index + 1}> ---\n\n--------------------\n\n\n`
    })
  }

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={Lightbulb}
      isLast={isLast}
      isInteractive={isInteractive}
      title="Hints search"
    >
      <AActionContentGenericLayout>
        <AActionContentDescription content={`Query: ${action?.args?.query}`} />
        <AActionContentFiles content={`< ` + `Hints matched: ${hints.length} `} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

HintsAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
  isInteractive: PropTypes.bool,
}
