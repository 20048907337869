import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.tsx'
import { HardDriveUpload } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentTerminal from '@/components/actions/components/AActionContentTerminal.jsx'
import { prettyPrintField } from '@/lib/iteration-utils.ts'

export default function HttpRequestAction({ action, isLast = false, isInteractive = false }) {
  let detailsContent = prettyPrintField(action?.outputs)

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={HardDriveUpload}
      isLast={isLast}
      isInteractive={isInteractive}
      title={action?.args?.comment ?? 'Http request'}
    >
      <AActionContentGenericLayout>
        <AActionContentTerminal
          content={action?.args?.url}
          terminalOutputs={detailsContent}
          headerRightText={action?.args?.method}
        />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

HttpRequestAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
  isInteractive: PropTypes.bool,
}
