import { ANALYTIC_EVENTS, analyticsTrackEvent } from '@/services/Analytics.js'
import {
  createIterationForProject,
  createProjectAndIterationFirebaseFunction,
  createProjectFirebaseFunction,
} from '@/services/Firebase.ts'
import { EnvironmentData, ProjectData, RepositoryData } from './schemas.ts'
import { IterationDefaults } from './types.ts'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EncryptedValue } from '@/services/firebase_shared_types.ts'

const START_GUNSLINGER_FOR_NEW_ITERATION = window?.location?.host?.startsWith('old.') ? false : true

export function useCreateProject() {
  const [isCreating, setIsCreating] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)
  const navigate = useNavigate()

  /**
   * Create a new project.
   */
  const createFn = async ({
    organizationId,
    teamId,
    formData,
    usecaseFields,
    templateId,
    prompt,
  }: CreateProjectPayload) => {
    if (isCreating) {
      return
    }
    setIsCreating(true)
    setError(null)

    try {
      // pick integer from 1 to 10 randomly
      const randomImage = Math.floor(Math.random() * 10) + 1
      // Payload should look something like this:

      const payload = {
        organizationId,
        teamId,
        projectData: {
          name: formData.name,
          projectConfiguration: formData.projectConfiguration,
          image: `${randomImage}`,
          iterationDefaults: {
            environment: formData.environment,
            repository: {
              ...formData.repository,
              githubUsername: formData.repository.githubUsername,
            },
            usecase: usecaseFields.reduce((acc, { key, value }) => {
              acc[key] = value
              return acc
            }, {}),
            templateId: templateId || null,
          },
        },
        prompt,
        dontStartGunslinger: !START_GUNSLINGER_FOR_NEW_ITERATION,
        configuration: formData.projectConfiguration,
      }

      // const response = await createProjectFirebaseFunction(payload)
      // const projectId = response.data.projectId
      // const iterationResponse = await createIterationForProject({
      //   projectId,
      //   prompt,
      //   dontStartGunslinger: !START_GUNSLINGER_FOR_NEW_ITERATION,
      //   configuration: formData.projectConfiguration,
      // })
      // setResponse(iterationResponse)

      const iterationResponse = await createProjectAndIterationFirebaseFunction(payload)
      const { projectId, iterationId } = iterationResponse.data
      setResponse(iterationResponse)
      // analyticsTrackEvent(ANALYTIC_EVENTS.PROJECT_CREATED, {
      //   organizationId,
      //   teamId,
      //   templateId,
      //   projectId: projectId || 'N/A',
      //   projectName: formData.name,
      // })
      // analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CREATE, {
      //   prompt,
      //   projectId,
      //   teamId: response?.data?.teamId || 'N/A',
      //   organizationId: response?.data?.organizationId || 'N/A',
      //   iterationId: response?.data?.iterationId || 'N/A',
      // })
      navigate(`/projects/${projectId}?iteration=${iterationId}`)
      return response
    } catch (error) {
      setError(error.message)
    } finally {
      setIsCreating(false)
    }
  }

  function resetCreateFn() {
    setIsCreating(false)
    setError(null)
    setResponse(null)
  }

  return { response, isCreating, error, createFn, resetCreateFn }
}

export type CreateProjectPayload = {
  formData: {
    name: string | null
    projectConfiguration: ProjectData
    repository: RepositoryData
    environment: Record<string, EncryptedValue>
  }
  prompt: string
  organizationId: string
  teamId: string
  usecaseFields: IterationDefaults['usecase']['fields']
}
