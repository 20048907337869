import { cn } from '@/lib/utils'

export function ABorderBox({
  isEnabled,
  children,
  className,
  borderClasname,
}: ARunningBorderProps) {
  return (
    <div className={cn('relative z-0')}>
      <div className={cn('z-10 rounded-[inherit] bg-inherit', className)}>{children}</div>
      <div
        className={cn(
          'ring-1 ring-inset ring-border',
          'absolute -inset-px -z-10 overflow-hidden',
          className,
          borderClasname,
          isEnabled && 'running-gradient-border'
        )}
      />
    </div>
  )
}

type ARunningBorderProps = {
  isEnabled: boolean
  className?: string
  children: React.ReactNode
  borderClasname?: string
}
