import AActionTextBox from './AActionTextBox.tsx'

export default function AActionPrompt({
  content,
  className,
}: {
  content: string
  className?: string
}) {
  return (
    <AActionTextBox title="Prompt" className={className}>
      {content}
    </AActionTextBox>
  )
}
