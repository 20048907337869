import { cn } from '@/lib/utils.ts'

export function ScrolledArea({
  children,
  stickyElement,
}: {
  children: React.ReactNode
  className?: string
  stickyElement?: React.ReactNode
}) {
  return (
    <div className={cn('relative flex h-full flex-col-reverse overflow-y-auto')}>
      <div className="min-h-full shrink-0 pb-4">
        {stickyElement}
        {children}
      </div>
    </div>
  )
}
