import PropTypes from 'prop-types'
import { actionShape } from '@/components/propTypes.js'
import { FileInput } from 'lucide-react'
import BaseFileAction from '@/components/actions/components/BaseFileAction.tsx'

export default function OverwriteFileAction({ action, isLast = false, isInteractive = false }) {
  return (
    <BaseFileAction
      action={action}
      actionIcon={FileInput}
      isLast={isLast}
      isInteractive={isInteractive}
    />
  )
}

OverwriteFileAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
  isInteractive: PropTypes.bool,
}
