import PropTypes from 'prop-types'
import MActionListItem from '@/components/molecules/iteration-details/MActionListItem.jsx'
import { actionShape } from '@/components/propTypes.js'
import { useSearchParams } from 'react-router-dom'
import { useMemo } from 'react'

/** @typedef {{
 content: string,
 title: string,
 detailsContent: string,
 }} ActionResultsDisplay */

export default function MActionsList({ actions = [], showAllActionsInStep = false, interactiveActionIdx = null }) {

  const actionsToDisplay = useMemo(() => {
    if (showAllActionsInStep) {
      return actions
    } else {
      return actions.slice(0, 3)
    }
  }, [actions, showAllActionsInStep])

  return (
      actionsToDisplay?.map((action, index) => (
        <MActionListItem
          key={`${action.actionId}-${action?.outputs?.length || 0}`}
          action={action}
          isLast={index === actions.length - 1}
          isInteractive={interactiveActionIdx === index}
        />
      ))
    
  )
}

MActionsList.propTypes = {
  //make array of action shapes
  actions: PropTypes.arrayOf(actionShape),
}
