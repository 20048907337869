import GenericAction from '@/components/actions/GenericAction.tsx'
import { Globe, Copy, ArrowUpRight, DownloadCloud, SquareArrowOutUpRight } from 'lucide-react'
import { Button } from '@/components/ui/button.tsx'
import React, { useCallback } from 'react'
import { useToast } from '@/components/ui/use-toast'
import { getOutputVersion } from '@/lib/action-utils'
import { cn } from '@/lib/utils.ts'

export default function BrowserAction({
  action,
  isLast = false,
  isInteractive = false,
}: {
  action: BrowserActionPayload
  isLast?: boolean
  isInteractive?: boolean
}) {
  const comment = action.args.comment
  const url = action.args.url
  const output = (action.outputs ?? []).find(isSuccessOutput)
  const isDone = action.status === 'DONE'
  const outputContent = getOutputVersion(output) === 2 ? output?.content : output
  const hasImage = Boolean(outputContent?.screenshot_public_url_large)

  return (
    <GenericAction
      title={comment ?? 'Browser testing'}
      action={action}
      detailsContent={comment}
      actionIcon={Globe}
      isLast={isLast}
      isInteractive={isInteractive}
    >
      <BrowserWindow>
        <AddressBar url={url} />
        {isDone && outputContent && hasImage && (
          <img
            src={outputContent.screenshot_public_url_large}
            alt={`Screenshot of ${url}`}
            loading="lazy"
          />
        )}
      </BrowserWindow>
      {isDone && outputContent && hasImage && (
        <Button variant="secondary" size="sm" className="mt-2.5 w-full" asChild>
          <a href={outputContent.screenshot_public_url_original} target="_blank" rel="noreferrer">
            <DownloadCloud className="size-4" />
            Download Screenshot
          </a>
        </Button>
      )}
    </GenericAction>
  )
}

export const BrowserWindow: React.FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div
      className={cn(
        'divide-y divide-border overflow-hidden rounded border border-border',
        className
      )}
    >
      {children}
    </div>
  )
}

export const AddressBar: React.FC<{ url: string; children?: React.ReactNode }> = ({
  url,
  children,
}) => {
  const toast = useToast()
  const copyUrl = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(url)
      toast.toast({ title: 'URL copied to clipboard' })
    } catch (_err) {
      // no access to clipboard
      toast.toast({ title: 'Could not copy the URL', variant: 'destructive' })
    }
  }, [url])

  return (
    <div className="grid grid-cols-[max-content,1fr,max-content] place-items-center gap-2 bg-base-background p-1 text-base-muted-foreground">
      <div className="ml-3 flex gap-1 *:size-1.5 *:rounded-full *:bg-border">
        <span />
        <span />
        <span />
      </div>
      <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-nowrap font-pp-supply-sans">
        {url}
      </p>
      <div>
        {children}
        <Button size="sm" variant="ghost" asChild className="w-8">
          <a href={url} target="_blank" rel="noreferrer">
            <SquareArrowOutUpRight className="size-4" />
          </a>
        </Button>
        <Button size="sm" variant="ghost" onClick={copyUrl} className="w-8">
          <Copy className="size-4" />
        </Button>
      </div>
    </div>
  )
}

type BrowserActionPayload = {
  actionId: string
  endCommitHash: string | null
  args: {
    url: string
    comment: string
  }
  startCommitHash: string | null
  elementId: string
  stepId: string
  startSnapshotHash: string | null
  name: string
  subtype: string | null
  index: number
  iterationId: string
  hideArgsFromPrompt: boolean
  id: string
  endSnapshotHash: string | null
  type: string
  status: string
  outputs: GenerateImageActionOutput[]
}

type FailedOutput_v1 = {
  error: unknown
  image_path: null
  screenshot_public_url_large?: null
  screenshot_public_url_small?: null
  screenshot_public_url_original?: null
}

type SuccessOutput_v1 = {
  success: true
  error: null
  image_path: string
  screenshot_public_url_large: string
  screenshot_public_url_small: string
  screenshot_public_url_original: string
}

type GenerateImageActionOutput_v1 = FailedOutput_v1 | SuccessOutput_v1

type FailedOutput = {
  content: null
  status: 'failed'
}

type SuccessOutput = {
  content: SuccessOutput_v1
  status: 'success'
}

type CurrentOutput = FailedOutput | SuccessOutput

type GenerateImageActionOutput = GenerateImageActionOutput_v1 | CurrentOutput

function isSuccessOutput(output: GenerateImageActionOutput) {
  if (getOutputVersion(output) === 2) {
    return output?.status === 'success'
  } else {
    return output?.error === null
  }
}
