import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.tsx'
import { CircleStop } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentTerminal from '@/components/actions/components/AActionContentTerminal'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout'
import { getOutputVersion } from '@/lib/action-utils'

export default function StopDaemonAction({ action, isLast = false, isInteractive = false }) {
  let detailsContent = `## COMMENT:\n${action.args?.comment}\n\n## PORT:\n${action.args?.port}\n\n`
  const terminalOutputs = []

  if (action.outputs) {
    detailsContent += '## OUTPUTS:\n'
    action?.outputs?.reduceRight((_, output, index) => {
      if (output == null) {
        return
      }
      let outputContent = output
      if (getOutputVersion(output) === 2) {
        outputContent = output?.content
        terminalOutputs.push(`${output?.message} \n\n`)
      }
      if (outputContent) {
        if (typeof outputContent === 'object') {
          const outputEntries = Object.entries(outputContent).map(
            ([key, value]) => `${key}: ${value}`
          )
          outputEntries.forEach(entry => {
            detailsContent += `${entry}`
            if (index < 10) {
              terminalOutputs.push(`- ${entry}\n`)
              index === 9 && terminalOutputs.push('... see details to see full output...')
            }
          })
        }
      } else {
        terminalOutputs.push(`\n${output?.content} \n`)
      }
    }, null)
  }

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={CircleStop}
      isLast={isLast}
      isInteractive={isInteractive}
      title={action?.args?.comment ?? 'Stop daemon'}
      shouldBeExpanded={true}
    >
      <AActionContentGenericLayout>
        <AActionContentTerminal
          content={'$ ' + action?.args?.cmd}
          terminalOutputs={terminalOutputs}
        />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

StopDaemonAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
  isInteractive: PropTypes.bool,
}
