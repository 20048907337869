import { userCommandsCollectionRef } from '@/services/Firebase.ts'
import { UserCommandSchema } from '@/services/firebase_shared_types.ts'
import { useQuery } from '@tanstack/react-query'
import { doc, getDoc, onSnapshot } from 'firebase/firestore'
import { useEffect } from 'react'

export function useUserCommandQuery(commandId: string | null) {
  const query = useQuery({
    queryKey: ['user-command', commandId],
    queryFn: async () => {
      if (!commandId) {
        throw new Error('commandId is required')
      }
      const query = queryUserCommand(commandId)
      const result = await getDoc(query)
      if (!result.exists()) {
        throw new Error('command not found')
      }
      const data = UserCommandSchema.parse(result.data())
      console.info('user command', data.id, data.command, data.status)
      return data
    },
  })

  useLiveUserCommand(commandId, query.refetch)

  return query
}

function useLiveUserCommand(commandId: string | null, onChange: () => void) {
  useEffect(() => {
    if (!commandId) {
      return
    }

    const userCommandsQuery = queryUserCommand(commandId)

    const unsubscribe = onSnapshot(userCommandsQuery, onChange, error => {
      console.error(
        '[Error] listener encountered an error  when querying live user command:',
        commandId,
        error.code,
        error.message
      )
    })

    return unsubscribe
  }, [commandId, onChange])
}

function queryUserCommand(commandId: string) {
  return doc(userCommandsCollectionRef, commandId)
}
