import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { Timestamp } from 'firebase/firestore'

import { useToast } from '@/components/ui/use-toast.js'
import {
  Check,
  Code,
  Columns2,
  ExternalLink,
  Loader,
  OctagonPause,
  Terminal,
  UserSearch,
} from 'lucide-react'

import OiterationDetailsSkeleton from '@/components/organisms/project-details/OiterationDetailsSkeleton.jsx'
import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics.js'
import {
  CONTINUATION_CHAT_ROLES,
  ITERATION_COMMANDS,
  ITERATION_ROLES_LIST,
  ITERATION_STATUSES,
} from '@/const/const'
import MElementsList, {
  MElementListItem,
} from '@/components/molecules/iteration-details/MElementList.jsx'
import MContinuationPrompt from '@/components/organisms/project-details/OIterationDetails/MContinuationPrompt.tsx'
import { UserInteractionForm } from '@/components/organisms/project-details/OIterationDetails/UserInteractionForm.tsx'
import useStore, { useIsUserSuperAdmin } from '@/stores/useStore.js'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { clsx } from 'clsx'
import { IterationInfo } from '@/components/organisms/project-details/OIterationDetails/IterationInfo.tsx'
import { useIterationQuery } from '@/lib/iterations/useIterationQuery.ts'
import MContinuationChat from './MContinuationChat.tsx'
import { useIterationActiveRole } from '@/lib/iterations/useIterationActiveRole.ts'
import {
  generateGCPLoggingLink,
  getGrafanaUrl,
  toFirestoreConsoleLink,
} from '@/lib/iteration-utils.ts'
import { Link } from '@/components/catalyst/link'
import MBaseContinuationChat from './MBaseContinuationChat.tsx'
import { useAggregatedRoles } from '@/lib/iterations/useAggregatedRoleRegistry.ts'
import { cn } from '@/lib/utils.ts'
import { RoleRegistry } from '@/services/firebase_shared_types.ts'
import { setInteractiveMode } from '@/services/Firebase.ts'
import { useMutation } from '@tanstack/react-query'
import { MStepsListItem } from '@/components/molecules/iteration-details/MStepList.jsx'

const SHOW_ALL_DETAILS_BY_DEFAULT = false

/**
 * A component for displaying details of an iteration.
 * @param {Object} props - The props for the component.
 * @param {Object} props.iterationMeta - The iteration to display.
 * @param {Boolean} props.isNewestIteration - Whether the iteration is the newest one.
 * @param {Number} props.lastTick - The last tick time.
 * @param {Function} props.onIterationCreated - The function to call when a new iteration is created.
 * @returns {JSX.Element} The JSX element for the component.
 */
export default function OIterationDetails({ iterationMeta, lastTick = Date.now() }) {
  const [lastIterationMeta, setLastIterationMeta] = useState(iterationMeta)

  const { isSplitPanel, setIsSplitPanel } = useStore(({ isSplitPanel, setIsSplitPanel }) => ({
    isSplitPanel,
    setIsSplitPanel,
  }))

  const iterationQuery = useIterationQuery(iterationMeta?.id)
  const { isLoading: isIterationLoading, data: iteration } = iterationQuery

  const [filterOutLLMActions, setFilterOutLLMActions] = useState(true)

  const isSuperAdmin = useIsUserSuperAdmin()
  const { toast } = useToast()

  const iterationRole = useIterationActiveRole(iterationMeta?.id)

  const [isAwaingCommand, isWorkingOnStatusChange] = useMemo(() => {
    const isAwaiting = iterationMeta?.awaitingCommand ? true : false
    const isDuringTransition = iterationMeta?.awaitingCommand?.active ? true : false
    return [isAwaiting, isDuringTransition]
  }, [iterationMeta])
  const getProjectById = useStore(state => state.getProjectById)

  const allowContinuationPrompt = useMemo(() => {
    const project = getProjectById(iterationMeta?.projectId)
    if (project.is_reference) {
      return false
    }
    return (
      (!isAwaingCommand &&
        !isWorkingOnStatusChange &&
        [ITERATION_STATUSES.DONE, ITERATION_STATUSES.FAILED].includes(iteration?.status)) ||
      iterationMeta?.awaitingCommand?.command === ITERATION_COMMANDS.EXTEND
    )
  }, [isAwaingCommand, isWorkingOnStatusChange, iteration?.status])

  // because when extending the iteration we can't tell if it's working on the analyst flow or just
  // working on a solution. We have to capture that state and keep it until the solution is validated.
  const [isAnalystFlow, setIsAnalystFlow] = useState(false)
  const allowContinuationChat = useMemo(() => {
    if (!iteration?.usesTechnicalAnalyst && !iteration?.usesBusinessAnalyst) {
      return false
    }

    const isContinuation = Object.keys(iteration?.elements ?? {}).length > 0
    const isDone = iteration?.status === 'DONE'

    // show the prompt when the iteration is finished
    if (isContinuation && isDone) {
      return true
    }

    const isRunning = iteration?.status === 'RUNNING'
    const isWaitingForMessage =
      CONTINUATION_CHAT_ROLES.includes(iterationRole?.name) &&
      iterationRole?.status === 'waiting_for_message'
    // show the prompt when the iteration is started and the analyst is waiting for a message
    return isRunning && isWaitingForMessage
  }, [
    iteration?.usesTechnicalAnalyst,
    iteration?.usesBusinessAnalyst,
    iteration?.status,
    iterationRole?.name,
    iterationRole?.status,
    iteration?.elements,
  ])

  useEffect(() => {
    if (allowContinuationChat) {
      setIsAnalystFlow(true) // will be turned off when the chat finished its work
    }
  }, [allowContinuationChat])

  const throttledRefetchIteration = useMemo(
    () =>
      _.throttle(() => {
        iterationQuery.refetch()
      }, 500),
    [iterationQuery.refetch]
  )

  useEffect(() => {
    if (!_.isEqual(iterationMeta, lastIterationMeta)) {
      setLastIterationMeta(iterationMeta)
      throttledRefetchIteration()
    }
  }, [iterationMeta, lastIterationMeta, iterationQuery])

  useEffect(
    () => {
      if (iterationQuery.status === 'success') {
        analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_OPEN, {
          iterationId: lastIterationMeta?.id,
          projectId: lastIterationMeta?.projectId || 'N/A',
          teamId: lastIterationMeta?.teamId || 'N/A',
          organizationId: lastIterationMeta?.organizationId || 'N/A',
          iterationStatus: lastIterationMeta.status,
          vmStatus: lastIterationMeta.vmStatus || 'N/A',
        })
      }
      if (iterationQuery.status === 'error') {
        analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_OPEN_ERROR, {
          iterationId: lastIterationMeta?.id,
          projectId: lastIterationMeta?.projectId || 'N/A',
          teamId: lastIterationMeta?.teamId || 'N/A',
          organizationId: lastIterationMeta?.organizationId || 'N/A',
          iterationStatus: lastIterationMeta.status,
          vmStatus: lastIterationMeta.vmStatus || 'N/A',
          error: iterationQuery.error.message,
        })
        toast({
          variant: 'destructive',
          title: 'Error loading iteration details 😔',
          description: 'Try refreshing the page or contact Proofs team.',
        })
      }
    },
    // run only when iterationQuery.status changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [iterationQuery.status, toast]
  )

  const elements = useMemo(() => {
    if (!iterationQuery.isSuccess) {
      return []
    }
    const elements = Object.entries(iterationQuery.data.elements).map(([key, value]) => {
      return { id: key, ...value }
    })
    // also order elements by index
    return elements.sort((a, b) => a.index - b.index)
  }, [iteration])

  const elementsWithRelativeTime = useMemo(() => {
    // lastTick is used to force a re-render every minute
    if (lastTick && elements) {
      return elements?.map(element => {
        const dateCreated = new Timestamp(element?.createdAt)?.toDate() || Date.now()
        const dateUpdated = new Timestamp(element?.updatedAt)?.toDate() || Date.now()
        return {
          ...element,
          updatedAt: dateUpdated,
          createdAt: dateCreated,
          updatedAtRelative: DateTime.fromJSDate(dateUpdated).toRelative(),
          repoURI: iteration?.repoURI,
        }
      })
    }
  }, [elements, iteration?.repoURI, lastTick])

  const groupedElements = useMemo(() => {
    const result = []
    if (iteration && elementsWithRelativeTime) {
      if (iteration?.continuationPrompts?.length ?? 0 > 0) {
        let lastContinuationPromptIndex = 0
        for (const continuationPrompt of iteration.continuationPrompts ?? []) {
          const afterElementIndex = continuationPrompt?.afterElementIndex
          // all elements with index less than the continuation prompt afterElementIndex should be grouped
          const elementsToGroup = elementsWithRelativeTime.filter(
            element =>
              element.index > lastContinuationPromptIndex && element.index <= afterElementIndex
          )
          result.push({
            elements: elementsToGroup,
            prompt: continuationPrompt?.prompt,
            createdBy: continuationPrompt?.createdBy,
          })
          lastContinuationPromptIndex = afterElementIndex
        }
      } else {
        // push all elements to first group
        result.push({ elements: elementsWithRelativeTime })
      }
    }

    return result
  }, [iteration, elementsWithRelativeTime])

  const logsUrl = useMemo(() => {
    if (iteration == null) {
      return generateGCPLoggingLink({})
    }

    const filters = {
      'jsonPayload.organization_id': iteration?.organizationId,
      'jsonPayload.project_id': iteration?.projectId,
      'jsonPayload.iteration_id': iterationMeta?.id,
    }
    return generateGCPLoggingLink(filters, iterationMeta?.createdAt)
  }, [iteration])

  const grafanaUrl = useMemo(() => {
    return getGrafanaUrl({
      iterationId: iterationMeta?.id,
      organizationId: iteration?.organizationId,
      fromTimestamp: iterationMeta?.createdAt,
    })
  }, [iteration, iterationMeta])

  const aggregatedRoles = useAggregatedRoles(iterationMeta?.id)

  function checkIsRoleActive(role: RoleRegistry[string] | null) {
    return Boolean(
      role?.turn?.label &&
        ['in_progress', 'waiting_for_message'].includes(role.status) &&
        role.turn.label !== 100
    )
  }

  const isIterationRoleActive = checkIsRoleActive(aggregatedRoles.iterationRole)
  const isElementRoleActive = checkIsRoleActive(aggregatedRoles.elementRole)
  const isStepRoleActive = checkIsRoleActive(aggregatedRoles.stepRole)

  const isRoleActive = isIterationRoleActive || isElementRoleActive || isStepRoleActive

  const isInteractive = iterationMeta?.isInteractiveMode ?? false

  const setInteractiveModeMutation = useMutation({
    mutationKey: ['setInteractiveMode', iterationMeta?.id],
    mutationFn: setInteractiveMode,
  })

  const enableRoles = async () => {
    setInteractiveModeMutation.mutate({
      iterationId: iterationMeta?.id,
      roles: ITERATION_ROLES_LIST.map(role => ({ name: role, interactive: true })),
    })
  }

  const disableRoles = async () => {
    setInteractiveModeMutation.mutate({
      iterationId: iterationMeta?.id,
      roles: ITERATION_ROLES_LIST.map(role => ({ name: role, interactive: false })),
    })
  }

  const [rolesDebugEnabled, setRolesDebugEnabled] = useState(false)

  if (!iteration || isIterationLoading) {
    return <OiterationDetailsSkeleton />
  }

  return (
    <div className="flex flex-col gap-12">
      <IterationInfo
        iteration={iteration}
        iterationMeta={iterationMeta}
        isRoleActive={isRoleActive}
      />

      {rolesDebugEnabled && (
        <details className="fixed left-[310px] top-10 z-50 w-1/3 rounded-lg border border-border bg-white p-2 font-dm-mono opacity-95 shadow-lg  open:w-[800px]">
          <summary>Roles</summary>

          <pre className="font-dm-mon max-h-[50vh] overflow-auto text-wrap ">
            <p>Iteration: </p>
            <p>{JSON.stringify(aggregatedRoles.iterationRole, null, 2)}</p>
            <p>Element: </p>
            <p>{JSON.stringify(aggregatedRoles.elementRole, null, 2)}</p>
            <p>Step: </p>
            <p>{JSON.stringify(aggregatedRoles.stepRole, null, 2)}</p>
          </pre>
        </details>
      )}

      <div className="">
        <div className="mb-12 flex flex-col gap-2">
          {allowContinuationPrompt &&
            !iteration.usesTechnicalAnalyst &&
            !iteration.usesBusinessAnalyst && (
              <MContinuationPrompt
                className=""
                iterationId={iterationMeta?.id}
                iterationUsecaseId={iteration?.usecaseId}
                command={iterationMeta?.awaitingCommand?.command}
                specifications={iteration.specifications ?? null}
              />
            )}

          {isAnalystFlow && (
            <MContinuationChat
              iterationId={iterationMeta?.id}
              iterationUsecaseId={iteration?.usecaseId}
              command={iterationMeta?.awaitingCommand?.command}
              specifications={iteration.specifications ?? null}
              onFinished={() => setIsAnalystFlow(false)}
            />
          )}
          {iteration.awaitingInputCommand?.command === ITERATION_COMMANDS.INPUT_REQUEST &&
            iteration.awaitingInputCommand?.active && (
              <UserInteractionForm
                initialData={iteration.awaitingInputCommand}
                iterationId={iterationMeta?.id}
              />
            )}
        </div>

        {groupedElements.map((group, index) => {
          return (
            <div key={index} className="mb-12">
              <MElementsList
                elements={group.elements}
                expandAllDetails={SHOW_ALL_DETAILS_BY_DEFAULT}
                filterOutLLMActions={filterOutLLMActions}
              >
                {group.elements.map((element, index) => {
                  const isElementRoleActive =
                    aggregatedRoles.elementRole?.elementIdx === index + 1 &&
                    checkIsRoleActive(aggregatedRoles.elementRole)
                  const isStepRoleActive =
                    aggregatedRoles.stepRole?.elementIdx === index + 1 &&
                    checkIsRoleActive(aggregatedRoles.stepRole)
                  const isElementChatEnabled =
                    isInteractive && isElementRoleActive && !isStepRoleActive

                  if (isStepRoleActive) {
                    console.log('step role active', index, aggregatedRoles.stepRole)
                  }

                  const steps = Object.entries(element?.steps ?? {})
                    .map(([key, value]) => {
                      return { stepId: key, ...value, repoURI: element.repoURI }
                    })
                    .sort((a, b) => a.index - b.index)
                  return (
                    <>
                      <MElementListItem
                        key={element.id}
                        element={element}
                        lastTick={lastTick}
                        isLast={index === elements.length - 1}
                        expandAllDetails={SHOW_ALL_DETAILS_BY_DEFAULT}
                        filterOutLLMActions={filterOutLLMActions}
                        isInteractive={isElementChatEnabled}
                      >
                        {steps.map((step, stepIndex) => {
                          const isStepChatEnabled =
                            isInteractive &&
                            isStepRoleActive &&
                            aggregatedRoles.stepRole?.stepIdx === stepIndex + 1
                          return (
                            <>
                              <MStepsListItem
                                key={step.id}
                                step={step}
                                isLast={stepIndex === element.steps.length - 1}
                                expandAllDetails={SHOW_ALL_DETAILS_BY_DEFAULT}
                                filterOutLLMActions={filterOutLLMActions}
                                isInteractive={isInteractive && isStepRoleActive}
                              />
                              {isStepChatEnabled && (
                                <div className="sticky bottom-0 left-0 right-0 w-full rounded-md border border-border bg-white p-1 shadow-lg">
                                  <MBaseContinuationChat
                                    iterationId={iterationMeta?.id}
                                    iterationUsecaseId={iteration?.usecaseId}
                                    command={iterationMeta?.awaitingCommand?.command}
                                    specifications={iteration.specifications ?? null}
                                    onFinished={() => {}}
                                    roleName={aggregatedRoles.stepRole?.name}
                                    role={aggregatedRoles.stepRole}
                                  />
                                </div>
                              )}
                            </>
                          )
                        })}
                      </MElementListItem>

                      {isElementChatEnabled && (
                        <div className="sticky bottom-0 left-0 right-0 w-full rounded-md border border-border bg-white p-1 shadow-lg">
                          <MBaseContinuationChat
                            iterationId={iterationMeta?.id}
                            iterationUsecaseId={iteration?.usecaseId}
                            command={iterationMeta?.awaitingCommand?.command}
                            specifications={iteration.specifications ?? null}
                            onFinished={() => {}}
                            roleName={aggregatedRoles.elementRole?.name}
                            role={aggregatedRoles.elementRole}
                          />
                        </div>
                      )}
                    </>
                  )
                })}
              </MElementsList>
              {group?.prompt && (
                <div className="flex flex-1 justify-end p-8 pr-0">
                  <div className="flex max-w-prose flex-col items-start rounded-2xl rounded-tr-none bg-zinc-800 px-4 py-4 text-zinc-50">
                    <div className="whitespace-pre-line text-base">{group.prompt}</div>
                    <div className="mt-4 text-sm font-semibold">{group.createdBy}</div>
                  </div>
                </div>
              )}
            </div>
          )
        })}

        {isInteractive && isIterationRoleActive && !isStepRoleActive && !isElementRoleActive && (
          <div className="sticky bottom-0 left-0 right-0 rounded-md border border-border bg-white p-1 shadow-lg">
            <MBaseContinuationChat
              iterationId={iterationMeta?.id}
              iterationUsecaseId={iteration?.usecaseId}
              command={iterationMeta?.awaitingCommand?.command}
              specifications={iteration.specifications ?? null}
              onFinished={() => {}}
              roleName={aggregatedRoles.iterationRole?.name}
              role={aggregatedRoles.iterationRole}
            />
          </div>
        )}
      </div>

      <div className="mb-24"></div>

      {isSuperAdmin && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              size="sm"
              className="fixed bottom-6 right-24 h-10 w-24 space-x-1.5 rounded-lg p-4 shadow-2xl drop-shadow-md"
            >
              <Code className="size-4" />
              <span>Debug</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-48" align="end" side="top">
            <DropdownMenuItem
              className="flex cursor-pointer items-center space-x-2"
              onSelect={e => {
                e.preventDefault()
                setFilterOutLLMActions(prev => !prev)
              }}
            >
              <Check className={clsx('size-4', filterOutLLMActions && 'invisible')} />
              <span>Show LLM Actions</span>
            </DropdownMenuItem>
            <DropdownMenuItem className="">
              <Link href={logsUrl} target={'_blank'} className="flex items-center space-x-2 ">
                <Terminal className="size-4" />
                <span>Logs</span>
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem className="">
              <Link href={grafanaUrl} target={'_blank'} className="flex items-center space-x-2 ">
                <Terminal className="size-4" />
                <span>Grafana Logs</span>
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem className="">
              <Link
                href={toFirestoreConsoleLink('iterations', iterationMeta?.id)}
                target={'_blank'}
                className="flex items-center space-x-2 "
              >
                <ExternalLink className="size-4" />
                <span>Iteration</span>
              </Link>
            </DropdownMenuItem>

            <DropdownMenuItem
              className={cn(
                'flex cursor-pointer items-center space-x-2',
                isInteractive && 'font-semibold'
              )}
              onSelect={e => {
                e.preventDefault()
                return isInteractive ? disableRoles() : enableRoles()
              }}
            >
              {setInteractiveModeMutation.isPending ? (
                <Loader className="size-4 animate-spin" />
              ) : (
                <OctagonPause
                  className={clsx(
                    'size-4',
                    isInteractive ? 'animate-pulse text-green-500' : 'text-slate-300'
                  )}
                />
              )}
              <span>Interactive</span>
            </DropdownMenuItem>

            <DropdownMenuItem
              className={cn(
                'flex cursor-pointer items-center space-x-2',
                rolesDebugEnabled && 'font-semibold'
              )}
              onSelect={() => setRolesDebugEnabled(prev => !prev)}
            >
              <UserSearch
                className={clsx(
                  'size-4',
                  rolesDebugEnabled ? 'animate-pulse text-green-500' : 'text-slate-300'
                )}
              />
              <span>Roles debug panel</span>
            </DropdownMenuItem>

            <DropdownMenuItem
              className={cn(
                'flex cursor-pointer items-center space-x-2',
                rolesDebugEnabled && 'font-semibold'
              )}
              onSelect={() => setIsSplitPanel(!isSplitPanel)}
            >
              <Columns2 className={clsx('size-4')} />
              <span>Split view</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  )
}

OIterationDetails.propTypes = {
  iterationMeta: PropTypes.shape({
    id: PropTypes.string,
    iterationId: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    inputTokens: PropTypes.number,
    outputTokens: PropTypes.number,
    elapsedTime: PropTypes.number,
    prompt: PropTypes.string,
    createdAt: PropTypes.instanceOf(Timestamp),
    updatedAt: PropTypes.instanceOf(Timestamp),
    elements: PropTypes.object,
    projectId: PropTypes.string,
    environmentId: PropTypes.string,
    environment: PropTypes.object,
    repoURI: PropTypes.string,
    awaitingCommand: PropTypes.object,
    teamId: PropTypes.string,
  }),
  lastTick: PropTypes.number,
  onIterationCreate: PropTypes.func,
  isIterationCreating: PropTypes.bool,
  isNewestIteration: PropTypes.bool,
  onIterationCreated: PropTypes.func,
}
