import { useEffect, useState } from 'react'
import { useIterationQuery } from '@/lib/iterations/useIterationQuery.ts'
import { useUserCommandQuery } from './userUserCommandQuery.ts'
import { useActiveRoleLoading } from './ActiveRoleLoadingContext.tsx'
import { useSendUserCommand } from '@/lib/sendUserCommand.ts'
import { ITERATION_STATUSES, IterationStatus } from '@/const/const'

export const useUserCommandFlow = (
  iterationId: string | null,
  nextIterationState: IterationStatus
) => {
  const [isLoading, setIsLoading] = useState(false)

  const { data: iteration } = useIterationQuery(iterationId)

  const sendCommandMutation = useSendUserCommand(iterationId)

  const commandId = sendCommandMutation.data?.id ?? null

  const userCommand = useUserCommandQuery(commandId)

  useEffect(() => {
    if (sendCommandMutation.isPending) {
      // user initialized a command
      setIsLoading(true)
    }
    const isUserCommandSuccessful =
      userCommand.isSuccess && userCommand.data && userCommand.data.status === 'SUCCESS'
    if (isUserCommandSuccessful && iteration?.status === nextIterationState) {
      setIsLoading(false)
    }
  }, [
    userCommand.isSuccess,
    userCommand.data,
    iteration?.specifications,
    sendCommandMutation.isPending,
    setIsLoading,
  ])

  return {
    sendCommandMutation,
    isLoadingUserCommand: isLoading,
  }
}
