import { CONTINUATION_CHAT_ROLES } from '@/const/const.ts'
import { useContext, useMemo, useState } from 'react'

import { createContext } from 'react'
import { useIterationActiveRole } from './useIterationActiveRole.ts'
import { AgentRole } from '@/services/firebase_shared_types.ts'

const ActiveRoleLoadingContext = createContext<ActiveRoleContextValue>({
  isInitializing: false,
  isLoading: false,
  isActive: false,
  iterationId: '',
  setIsLoading: () => {},
})

export const ActiveRoleLoadingProvider = ({
  iterationId,
  children,
}: {
  iterationId: string
  children: React.ReactNode
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const role = useIterationActiveRole(iterationId, CONTINUATION_CHAT_ROLES)

  const isInitializing = isRoleInitializing(role)

  const isActive = role?.status === 'in_progress' || role?.status === 'waiting_for_message'

  const value = useMemo(
    (): ActiveRoleContextValue => ({
      isInitializing: isInitializing,
      isLoading,
      setIsLoading,
      iterationId,
      isActive,
    }),
    [isInitializing, isLoading, setIsLoading, iterationId, isActive]
  )

  return (
    <ActiveRoleLoadingContext.Provider value={value}>{children}</ActiveRoleLoadingContext.Provider>
  )
}
export function useActiveRoleLoading() {
  return useContext(ActiveRoleLoadingContext)
}

/**
 * Returns true if role is before "workable" state, i.e.
 * - not started: it basically means it's starting, so it's initializing
 * - in_progress: it's in progress, so MAYBE it's initializing
 * - any other status: it's not initializing
 *
 * Additionally we need to see what is actually being done when it's in_progress.
 * This is determined by turns. When turn is 600, it's after serving initial message so it's not initializing.
 */
function isRoleInitializing(role: AgentRole | null) {
  if (!role) return false
  if (role.status === 'not_started') return true
  if (role.status !== 'in_progress') return false

  return Boolean(role.turn && role.turn?.label < 600)
}

type ActiveRoleContextValue = {
  isInitializing: boolean
  isLoading: boolean
  isActive: boolean
  iterationId: string
  setIsLoading: (isLoading: boolean) => void
}
