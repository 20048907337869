import { ITERATION_COMMANDS } from '@/const/const.ts'
import { UserInputCommand } from '@/lib/sendUserCommand.ts'
import { useMutationState } from '@tanstack/react-query'

// we're guaranteed to have just one analyst command processing at once.
export const useRoleMessageCommand = (
  iterationId: string | null,
  status?: 'pending' | 'success' | 'error' | 'idle'
) => {
  const pendingCommands = useMutationState({
    filters: {
      mutationKey: ['sendUserCommand', iterationId],
      status,
    },
    select: mutation => mutation.state.variables,
  }).filter(
    (variables): variables is UserInputCommand =>
      typeof variables === 'object' &&
      (variables as UserInputCommand)?.command === ITERATION_COMMANDS.ROLE_MESSAGE
  )

  const command = pendingCommands[pendingCommands.length - 1]

  return command ?? null
}
