import { browserLocalPersistence, initializeAuth } from 'firebase/auth'
import { collection, doc, getFirestore } from 'firebase/firestore'
import { initializeApp } from 'firebase/app'
import CONFIG from '@/config/config'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { GetIterationInput, GetIterationOutput, IterationDefaults } from './firebase_shared_types'

export const COLLECTIONS = {
  userCommands: 'userCommands',
  users: 'users',
  userRoles: 'userRoles',
  organizations: 'organizations',
  teams: 'teams',
  projects: 'projects',
  projectsViewableIterations: 'viewableIterations',
  projectsViewableIterationsMeta: 'viewableIterationsMeta',
  hints: 'hints',
  jobs: 'gunslingerJobs',
  iterationFeedback: 'iterationFeedback',
  configurationTemplates: 'configurationTemplates',
  iterations: 'iterations',
} as const

export const firebaseApp = initializeApp(CONFIG.firebase)
export const firestoreDb = getFirestore(firebaseApp)
export const auth = initializeAuth(firebaseApp, {
  persistence: browserLocalPersistence,
})

export const functions = getFunctions(firebaseApp)

// Connect to emulators if running locally
// if (window.location.hostname === 'localhost') {
//   connectAuthEmulator(auth, 'http://localhost:9099')
//   connectFirestoreEmulator(firestoreDb, 'localhost', 8080)
//   connectFunctionsEmulator(functions, 'localhost', 5001)
//   const database = getDatabase(firebaseApp)
//   connectDatabaseEmulator(database, 'localhost', 9000)
//   const storage = getStorage(firebaseApp)
//   connectStorageEmulator(storage, 'localhost', 9199)
// }

export const retryPromptFirebaseFunction = httpsCallable<
  { iterationId: string; prompt: string },
  { id: string }
>(functions, 'retryPrompt')
export const getConfigurationTemplatesFirebaseFunction = httpsCallable(
  functions,
  'getConfigurationTemplates'
)
export const onboardUserFirebaseFunction = httpsCallable(functions, 'onboardUser')
export const doesUserExistFirebaseFunction = httpsCallable<
  { email: string },
  { userExists: boolean }
>(functions, 'doesUserExist')
export const createIterationForProject = httpsCallable<
  unknown,
  {
    // there's more than that but that's what we need for now
    projectId: string
    iterationId: string
  }
>(functions, 'createIterationForProject')
export const createOrganization = httpsCallable(functions, 'createOrganization')
export const updateOrganization = httpsCallable(functions, 'updateOrganization')
export const createTeam = httpsCallable(functions, 'createTeam')
export const updateTeam = httpsCallable(functions, 'updateTeam')
export const createTemplate = httpsCallable(functions, 'createTemplate')

export const updateTemplate = httpsCallable(functions, 'updateTemplate')
export const deleteTemplate = httpsCallable(functions, 'deleteTemplate')
export const getIteration = httpsCallable<GetIterationInput, GetIterationOutput>(
  functions,
  'getIteration'
)
export const getIterationDefault = httpsCallable<{ projectId: string }, IterationDefaults>(
  functions,
  'getIterationDefault'
)
export const getHintsForProject = httpsCallable(functions, 'getHintsForProject')
export const updateHintFirebaseFunction = httpsCallable(functions, 'updateHint')
export const createHintFirebaseFunction = httpsCallable(functions, 'createHint')
export const deleteHintFirebaseFunction = httpsCallable(functions, 'deleteHint')

export const pushToGithub = httpsCallable<{
  username: string
  repoURI: string
  projectId: string
  iterationId: string
}>(functions, 'pushToGithub')

export const createIterationCommandFirebaseFunction = httpsCallable(
  functions,
  'createIterationCommand'
)

export const setInteractiveMode = httpsCallable<{
  iterationId: string
  roles: { name: string; interactive: boolean }[]
}>(functions, 'setInteractiveMode')

export const removeAllIterationCommandsFirebaseFunction = httpsCallable(
  functions,
  'removeAllIterationCommands'
)

export const stopVMJobForIterationFirebaseFunction = httpsCallable(
  functions,
  'stopVMJobForIteration'
)
export const createIterationFeedbackFirebaseFunction = httpsCallable(
  functions,
  'createIterationFeedback'
)
export const createProjectFirebaseFunction = httpsCallable<
  {
    organizationId: string
    teamId: string
    projectData: {
      name: string | null
      projectConfiguration: {
        repoURI: string | null
        name: string | null
        techStack: string
        organizationId: string
        teamId: string
        githubUsername?: string
        prospectName?: string | null | undefined
        prospectWebsite?: string | null | undefined
      }

      iterationDefaults: {
        environment: Record<
          string,
          { value: string; dontEncrypt?: boolean; copyFieldAsItIs?: boolean }
        >
        repository: {
          githubUsername: string | null
        }
        usecase: Record<string, string>
      }
    }
  },
  {
    projectId: string
    projectPayload: {
      name: string | null
      description: string | null
      image: string | null
      projectConfiguration: {
        name: string | null
        organizationId: string
        prospectName: string | null
        prospectWebsite: string | null
        techStack: string
        teamId: string
      }
    }
  }
>(functions, 'createProject')
export const updateProjectFirebaseFunction = httpsCallable(functions, 'updateProject')

export const createProjectAndIterationFirebaseFunction = httpsCallable<
  {
    organizationId: string
    teamId: string
    projectData: {
      name: string | null
      projectConfiguration: {
        repoURI: string | null
        name: string | null
        techStack: string
        organizationId: string
        teamId: string
        githubUsername?: string | null
        prospectName?: string | null | undefined
        prospectWebsite?: string | null | undefined
      }

      iterationDefaults: {
        environment: Record<
          string,
          { value: string; dontEncrypt?: boolean; copyFieldAsItIs?: boolean }
        >
        repository: {
          githubUsername: string | null
        }
        usecase: Record<string, string>
      }
    }
    prompt: string
    dontStartGunslinger: boolean
    configuration: any
    sourceIterationId?: any
    iterationId: string
    projectId: string
  },
  {
    // there's more than that but that's what we need for now
    projectId: string
    iterationId: string
  }
>(functions, 'createProjectAndIteration')

export const getLLMActionsForIterationElementStepFirebaseFunction = httpsCallable(
  functions,
  'getLLMActionsForIterationElementStep'
)

export const authorizeGithub = httpsCallable<never, { installationUrl: string }>(
  functions,
  'authorizeGithub'
)
export const installGithub = httpsCallable<never, { installationUrl: string }>(
  functions,
  'installGithub'
)

export const sendMagicLink = httpsCallable<{ email: string; devDomain?: string }, unknown>(
  functions,
  'sendMagicLink'
)

export const fetchGithubRepositories = httpsCallable<
  { login: string },
  {
    id: string
    name: string
    full_name: string
    url: string
    clone_url: string
    default_branch: string
    owner: { login: string; id: number; avatar_url: string }
  }[]
>(functions, 'fetchGithubRepositories')

export const usersCollectionRef = collection(firestoreDb, COLLECTIONS.users)
export const userRolesCollectionRef = collection(firestoreDb, COLLECTIONS.userRoles)
export const organizationsCollectionRef = collection(firestoreDb, COLLECTIONS.organizations)
export const userCommandsCollectionRef = collection(firestoreDb, COLLECTIONS.userCommands)
export const roleRepository = (iterationId: string, role: string) =>
  collection(firestoreDb, 'iterations', iterationId, 'roles', role, 'roles_updates')
export const iterationPromptsRepository = (iterationId: string) =>
  collection(firestoreDb, 'iterations', iterationId, 'iteration_prompts')
export const teamsCollectionRef = collection(firestoreDb, COLLECTIONS.teams)
export const projectsCollectionRef = collection(firestoreDb, COLLECTIONS.projects)

export const hintsCollectionRef = collection(firestoreDb, COLLECTIONS.hints)
export const jobsCollectionRef = collection(firestoreDb, COLLECTIONS.jobs)
export const iterationFeedbackCollectionRef = collection(firestoreDb, COLLECTIONS.iterationFeedback)

export const createAndInviteUserFirebaseFunction = httpsCallable<
  {
    firstName: string
    lastName: string
    email: string
    organizationId: string
    isExternal: boolean
    isOnboarding: boolean
    projectToOnboard?: string
  },
  {
    success: boolean
    userId: string
  }
>(functions, 'createAndInviteUser')

export function generateFirebaseId(collectionName: string) {
  return doc(collection(firestoreDb, collectionName)).id
}
