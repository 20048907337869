import { UserCommand } from '@/services/firebase_shared_types.ts'
import PromptBubble from '@/components/organisms/project-details/OIterationDetails/MPromptBubble.tsx'
import { cn } from '@/lib/utils.ts'
import { AlarmClock, CheckCheck, OctagonX } from 'lucide-react'
import ALogoSpinner from '@/components/atoms/ALogoSpinner.tsx'

const AWAITING_SUGGESTION_BUBBLE_CONFIG = {
  Icon: AlarmClock,
  className: 'border border-dashed border-border bg-transparent rounded-xl',
}
const ERRORED_SUGGESTION_BUBBLE_CONFIG = {
  Icon: (props: { className: string }) => (
    <OctagonX className={cn('text-red-500', props.className)} />
  ),
  className: 'bg-red-50',
}
const BUBBLE_CONFIG = {
  PENDING: {
    Icon: AlarmClock,
    className: 'border border-dashed border-border bg-transparent rounded-xl',
  },
  RECEIVED: AWAITING_SUGGESTION_BUBBLE_CONFIG,
  ACCEPTED: AWAITING_SUGGESTION_BUBBLE_CONFIG,
  REJECTED: ERRORED_SUGGESTION_BUBBLE_CONFIG,
  IN_PROGRESS: {
    Icon: (props: { className: string }) => (
      <ALogoSpinner className={cn('text-violet-500', props.className)} />
    ),
    className: 'bg-violet-50',
  },
  SUCCESS: { Icon: CheckCheck },
  FAILED: ERRORED_SUGGESTION_BUBBLE_CONFIG,
}

export function SuggestionPromptBubble(props: {
  suggestion: string
  status: UserCommand['status']
}) {
  const { Icon, className = '' } = BUBBLE_CONFIG[props.status] ?? AWAITING_SUGGESTION_BUBBLE_CONFIG
  return (
    <PromptBubble className={className} icon={Icon}>
      <span>{props.suggestion}</span>
    </PromptBubble>
  )
}
