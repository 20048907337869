import { cn } from '@/lib/utils.ts'
import { cva } from 'class-variance-authority'
import { AlarmClock, CheckCheck, PauseCircle } from 'lucide-react'
import ALogoSpinner from '@/components/atoms/ALogoSpinner.tsx'

const statusVariants = cva('text-xs rounded-lg px-3.5 py-2 inline-flex items-center gap-2 w-fit', {
  variants: {
    status: {
      WAITING_FOR_FEEDBACK: 'text-orange-600 bg-orange-100',
      WORKING: 'text-violet-600 bg-violet-100',
      PAUSED: 'text-stone-600 bg-stone-200',
      DONE: 'text-green-600 bg-green-100',
      PAUSING: 'text-stone-600 bg-stone-200',
    },
  },
})

const IconComponents: Record<Status, React.ComponentType<{ className?: string }>> = {
  WORKING: ALogoSpinner,
  WAITING_FOR_FEEDBACK: AlarmClock,
  PAUSED: PauseCircle,
  DONE: CheckCheck,
  PAUSING: PauseCircle,
}

// it's not VM, iteration, or any other status. It's all of it rolled into one.
export default function MIterationStatusPill({
  status,
  className,
}: {
  status: Status
  className?: string
}) {
  const Icon = IconComponents[status]
  return (
    <div className={cn(statusVariants({ status }), className)}>
      <Icon className="size-4" />
      {statusToLabel[status]}
    </div>
  )
}

type Status = 'WORKING' | 'WAITING_FOR_FEEDBACK' | 'DONE' | 'PAUSED' | 'PAUSING'

const statusToLabel: Record<Status, string> = {
  WORKING: 'Proofs is working',
  WAITING_FOR_FEEDBACK: 'Waiting for feedback',
  PAUSED: 'Paused',
  DONE: 'Done',
  PAUSING: 'Pausing...',
}
