import GenericAction from '@/components/actions/GenericAction.tsx'
import { detectProgrammingLanguageFromFilePath } from '@/components/actions/utils.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentTerminal from '@/components/actions/components/AActionContentTerminal.jsx'
import { getLanguageBlock } from '@/lib/iteration-utils.ts'
import React from 'react'

export default function BaseFileAction({
  action,
  actionIcon,
  isLast = false,
  isInteractive = false,
}: {
  action: any // TODO: fix this
  actionIcon: React.ComponentType<React.SVGProps<SVGSVGElement>>
  isLast: boolean
  isInteractive: boolean
}) {
  const filePath = action?.args?.path
  let detailsContent = `## PATH:\n${filePath}\n\n## COMMENT:\n${action?.args?.comment}\n\n`

  const language = detectProgrammingLanguageFromFilePath(filePath)
  const languageBlock = getLanguageBlock(language, action?.args?.content)
  detailsContent += '## FILE CONTENTS:\n' + languageBlock + '\n\n\n'
  detailsContent += '## OUTPUTS:\n'
  action?.outputs?.forEach((output, index) => {
    // if output is object
    let cleanOutput = output
    if (typeof output === 'object') {
      cleanOutput = `${output?.message} (Success?: ${output?.success}, error: ${output?.error}) \n`
    }
    detailsContent += `${index + 1}: ${cleanOutput}\n`
  })

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={actionIcon}
      isLast={isLast}
      isInteractive={isInteractive}
      title={action?.args?.comment}
    >
      <AActionContentGenericLayout>
        <AActionContentTerminal
          content={action?.args?.path}
          terminalOutputs={[languageBlock]}
          errored={action?.outputs?.error}
          language={language}
        />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}
