import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import useStore from '@/stores/useStore'

import OPageWrapper from '@/components/organisms/OPageWrapper'
import OProjectsTable from '@/components/organisms/OProjectsTable'
// import OUsersTable from '@/components/organisms/OUsersTable'
import MCardTableSkeleton from '@/components/molecules/MCardTableSkeleton'
import { CreateButton } from '@/components/atoms/ACreateButton.jsx'
import MCreateProjectDialog from '@/components/molecules/project-details/CreateProject/MCreateProjectDialog.tsx'

const FAKE_USERS = [
  {
    id: '1',
    firstName: 'John',
    lastName: 'Doe',
    email: 'john.doe@acmeenterprise.com',
    image: '1',
  },
  {
    id: '2',
    firstName: 'Jane',
    lastName: 'Doe',
    email: 'jane.doe@acmeenterprise.com',
    image: '2',
  },
  {
    id: '3',
    firstName: 'Jade',
    lastName: 'Smith',
    email: 'jade.smith@acmeenterprise.com',
    image: '3',
  },
  {
    id: '4',
    firstName: 'Jack',
    lastName: 'Newcomer',
    email: 'jack.newcomer@acmeenterprise.com',
    image: '4',
  },
]

export default function PTeamDetails() {
  const { teamId } = useParams()
  const projects = useStore(state => state.projects)
  const teams = useStore(state => state.teams)
  const getOrgById = useStore(state => state.getOrgById)
  const getTeamById = useStore(state => state.getTeamById)
  const getProjectsByTeamId = useStore(state => state.getProjectsByTeamId)
  const organizations = useStore(state => state.organizations)

  const navigate = useNavigate()

  const isLoading = useMemo(() => {
    return teams.length === 0
  }, [teams])

  const team = useMemo(() => {
    if (teams?.length > 0) {
      return getTeamById(teamId)
    }
    return null
  }, [teams, getTeamById, teamId])

  const teamProjects = useMemo(() => {
    if (projects.length > 0) {
      return getProjectsByTeamId(teamId)
    }
    return null
  }, [projects, getProjectsByTeamId, teamId])

  const organization = useMemo(() => {
    // we need to react on organizations changes
    if (organizations?.length > 0) {
      return getOrgById(team?.organizationId)
    }
    return null
  }, [organizations, getOrgById, team?.organizationId])

  const breadcrumbs = useMemo(() => {
    return [
      {
        name: organization?.name || 'Organization',
        href: `/organizations/${team?.organizationId}`,
      },
      {
        name: team?.name || 'Team',
        current: true,
      },
    ]
  }, [organization, team])

  if (isLoading) {
    return (
      <OPageWrapper>
        <div role="status" className="px-2 py-6 lg:px-24 lg:py-12 ">
          <div className="pb-8 pt-12">
            <h1 className="animate-pulse text-4xl font-semibold leading-6 text-gray-900">
              {team?.name || 'Team...'}
            </h1>
          </div>
          <MCardTableSkeleton
            title="Loading projects..."
            howManyProjectsToFake={7}
            labels={['Name', 'Team', 'Updated', 'Status']}
          />
        </div>
      </OPageWrapper>
    )
  }

  if (!team) {
    return (
      <OPageWrapper>
        <div>Team doesn&apos;t exist</div>
      </OPageWrapper>
    )
  }

  return (
    <OPageWrapper breadcrumbs={breadcrumbs}>
      <div className="px-2 py-6 lg:px-24 lg:py-12 ">
        <div className="pb-8 pt-12">
          <h1 className="text-4xl font-semibold leading-6 text-gray-900">{team.name}</h1>
        </div>
        <div className="overflow-hidden rounded-lg border border-gray-200 bg-white px-4 py-5 shadow-lg sm:px-6">
          <div className="-ml-4 -mt-2  flex flex-wrap items-center justify-between pb-6 pt-2 sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-xl font-semibold leading-6 text-gray-900">Projects</h3>
            </div>
          </div>
          <OProjectsTable projects={teamProjects} />
        </div>
      </div>
    </OPageWrapper>
  )
}

PTeamDetails.propTypes = {}
