import GenericAction from '@/components/actions/GenericAction.tsx'
import { ImagePlus } from 'lucide-react'
import AActionPrompt from '@/components/actions/components/AActionPrompt.tsx'
import { ITERATION_STATUSES } from '@/const/const.js'
import MImagePreview from '../molecules/actions/MImagePreview.tsx'
import AActionContentFiles from './components/AActionContentFiles.tsx'
import { cn } from '@/lib/utils.ts'
import { useMemo } from 'react'
import { getOutputVersion } from '@/lib/action-utils'

export default function GenerateImageListAction({
  action,
  isLast = false,
  isInteractive = false,
}: {
  action: GenerateImageListActionPayload
  isLast?: boolean
  isInteractive?: boolean
}) {
  const imagesAction = action as GenerateImageListActionPayload

  const imageList = imagesAction.args.item_list ?? []
  const filePath = imagesAction.args.output_image_dir

  const imagePrompt = imageList.map(item => `${item.name}\n`)

  const successfulOutput = imagesAction?.outputs && imagesAction?.outputs?.find(isSuccessOutput)
  const images =
    getOutputVersion(successfulOutput) === 2
      ? (successfulOutput?.content?.output_images ?? [])
      : (successfulOutput?.output_images ?? [])

  const renderedImages = useMemo(
    () =>
      images.map(outputImage => (
        <MImagePreview
          downloadUrl={outputImage.public_url_large}
          fileName={outputImage.item_name}
          src={outputImage.public_url_small}
          alt={outputImage.item_name}
          key={outputImage.image_path}
          className={cn(
            images.length === 1 && 'w-full',
            images.length === 2 && 'w-[calc(50%-4px)]',
            images.length > 2 && 'w-5/12'
          )}
        >
          <AActionPrompt
            className="rounded-b-none"
            content={promptForImage(outputImage.item_name)}
          />
        </MImagePreview>
      )),
    []
  )

  function promptForImage(name: string) {
    const prompt = imageList.find(item => item.name === name)
    if (!prompt) {
      console.error(`Prompt not found for image ${name}`, {
        actionId: action.actionId,
        iterationId: action.iterationId,
        elementId: action.elementId,
      })
      return ''
    }
    return prompt.description
  }

  const isDone = action.status === ITERATION_STATUSES.DONE
  const hasImages = images.length > 0

  return (
    <GenericAction
      action={action}
      detailsContent={`# Generate images\n${filePath}\n\n## Prompt:\n${imagePrompt}\n\n## Size: \n${action?.args?.size}\n\n## Comment: \n${action?.args?.comment}\n\n`}
      actionIcon={ImagePlus}
      isLast={isLast}
      title={action.args.comment ?? 'Generate images for products'}
      isInteractive={isInteractive}
      title="Generate images for products"
    >
      {isDone && hasImages && (
        <div className="-mr-6 flex snap-x snap-proximity snap-center gap-1.5 overflow-x-auto pb-4 pr-6 *:shrink-0">
          {renderedImages}
        </div>
      )}
      {(!isDone || !hasImages) &&
        imagePrompt.map(prompt => (
          <AActionPrompt key={prompt} className="rounded-b-none" content={prompt} />
        ))}
      <AActionContentFiles content={action.args.output_image_dir} />
    </GenericAction>
  )
}

export type GenerateImageListActionPayload = {
  actionId: string
  args: {
    image_type: string
    brand_name: string
    output_image_dir: string
    // sometimes item_list is not present. TBD if it's just bad data or that can really happen in the wild.
    item_list: {
      name: string
      description: string
    }[]
    size: string
    company_brief_filename: string
    image_style: string
    comment: string
  }
  elementId: string
  stepId: string
  startSnapshotHash: any
  name: string
  obsolete: boolean
  subtype: any
  index: number
  iterationId: string
  hideArgsFromPrompt: boolean
  id: string
  endSnapshotHash: any
  type: string
  startCommitHash: string
  endCommitHash: string
  status: string
  // when `item_list` is not present, outputs is [null]
  outputs: {
    output_image_paths: string[]
    output_images: {
      item_name: string
      public_url_small: string
      public_url_large: string
      public_url_original: string
      image_path: string
    }[]
    message: string
    error: any
    success: boolean
  }[]
  createdAt: {
    _seconds: number
    _nanoseconds: number
  }
  endedAt: number
  startedAt: number
  elapsedTime: number
  updatedAt: {
    _seconds: number
    _nanoseconds: number
  }
  elementIndex: number
  stepIndex: number
}

function isSuccessOutput(output) {
  if (getOutputVersion(output) === 2) {
    return output?.status === 'success'
  } else {
    return Boolean(output?.success)
  }
}
