import { RoleRegistry } from '@/services/firebase_shared_types.ts'
import { useIterationQuery } from './useIterationQuery.ts'
import { useActiveRole } from './useIterationActiveRole.ts'
import { INTERACTIVE_CHAT_ROLES } from '@/const/const.ts'

export type RoleRegistryWithContext = Record<
  string,
  RoleRegistry[string] & { elementIdx?: number; stepIdx?: number }
>

export function useAggregatedRoleRegistry(iterationId: string | null) {
  const queryResult = useIterationQuery(iterationId)
  if (!queryResult.isSuccess) {
    return { iterationRegistry: {}, elementRegistry: {}, stepRegistry: {} }
  }
  const iteration = queryResult.data
  const elements = Object.values(iteration.elements).sort((a, b) => a.index - b.index)

  let stepRegistry: RoleRegistryWithContext = {}

  const elementRegistry: RoleRegistryWithContext = elements.reduce((acc, element) => {
    const steps = Object.values(element.steps).sort((a, b) => a.index - b.index)
    const localStepRegistry = aggregateRegistries(
      steps.map(step => ({
        index: step.index,
        role_registry: step.role_registry,
        elementIdx: element.index,
      }))
    )
    stepRegistry = { ...stepRegistry, ...localStepRegistry }
    const elementRegistry = aggregateRegistries([
      { index: element.index, role_registry: element.role_registry },
    ])

    return { ...acc, ...elementRegistry }
  }, {})

  return { iterationRegistry: iteration.role_registry, elementRegistry, stepRegistry }
}

export function useAggregatedRoles(iterationId: string) {
  const { stepRegistry, elementRegistry, iterationRegistry } =
    useAggregatedRoleRegistry(iterationId)

  const iterationRole = useActiveRole(iterationRegistry, INTERACTIVE_CHAT_ROLES)

  const elementRole = useActiveRole(elementRegistry ?? {}, INTERACTIVE_CHAT_ROLES)
  const stepRole = useActiveRole(stepRegistry ?? {}, INTERACTIVE_CHAT_ROLES)

  return { iterationRole, elementRole, stepRole }
}

function aggregateRegistries(
  elements: { index: number; role_registry: RoleRegistry; elementIdx?: number }[]
): RoleRegistryWithContext {
  return elements.reduce((acc, { index, role_registry, elementIdx }) => {
    const registryActiveEntries = Object.values(role_registry ?? {}).filter(
      roleState => roleState.status !== 'done' && roleState.status !== 'not_started'
    )
    const filteredRegistry: RoleRegistryWithContext = registryActiveEntries.reduce(
      (acc, roleState) => {
        return {
          ...acc,
          [roleState.name]: {
            ...roleState,
            elementIdx: elementIdx ?? index,
            stepIdx: elementIdx ? index : undefined,
          },
        }
      },
      {}
    )
    return { ...acc, ...filteredRegistry }
  }, {})
}
