import classNames from 'classnames'
import { ActionSectionHeader } from '../ActionSectionHeader.tsx'

export default function AActionTextBox({
  title,
  className,
  children,
}: {
  title: string
  className?: string
  children?: React.ReactNode
}) {
  return (
    <div className={classNames(className)}>
      <ActionSectionHeader>{title}</ActionSectionHeader>
      <div className="mt-2.5 text-sm text-muted-foreground">{children}</div>
    </div>
  )
}
