import PromptBubble from '../OIterationDetails/MPromptBubble.tsx'
import { useRoleMessageCommand } from '@/lib/iterations/analyst/useRoleMessageCommands.ts'

export function LastUpdatePromptBubble({ iterationId }: { iterationId: string }) {
  const updates = useRoleMessageCommand(iterationId)
  const isUpdate =
    updates?.command === 'ROLE_MESSAGE' && updates.commandArgs.content.choice === 'update'
  if (!isUpdate) {
    return null
  }

  return <PromptBubble>{updates.commandArgs.content.message}</PromptBubble>
}
