import { useState, useCallback, useEffect, useRef } from 'react'

import { Textarea } from '@/components/ui/textarea'
import { Button } from '@/components/ui/button'

import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics'
import { useSendUserCommand } from '@/lib/sendUserCommand'
import { ITERATION_COMMANDS } from '@/const/const.ts'
import { useToast } from '@/components/ui/use-toast'

export default function MAddUserSuggestion({ action, shouldShow, setShouldShow }) {
  const { toast } = useToast()
  const sendCommand = useSendUserCommand(action.iterationId)

  const [userInput, setUserInput] = useState('')
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const sendIterationCommand = useCallback(async () => {
    sendCommand.mutate(
      {
        command: ITERATION_COMMANDS.SUGGESTION,
        commandArgs: { suggestion: userInput },
      },
      {
        onError(error) {
          console.error('Error creating user suggestion', error)
          toast({
            variant: 'destructive',
            title: 'Error adding user suggestion 😔',
            description: 'Try refreshing the page or contact Proofs team.',
          })
        },
        onSuccess() {
          setUserInput('')
          setShouldShow(false)
          analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_ADD_USER_SUGGESTION, {
            iterationId: action.iterationId,
            elementIndex: action.elementIndex,
            stepIndex: action.stepIndex,
            actionIndex: action.index,
            suggestion: userInput,
          })
        },
      }
    )
  }, [action, userInput, setShouldShow])

  useEffect(() => {
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus()
        const length = textareaRef.current.value.length
        textareaRef.current.setSelectionRange(length, length)
      }
    }, 200)
  }, [])

  return (
    <div className="mx-4 space-y-2 py-4 text-sm text-stone-500">
      <div className="space-y-0.5">
        <div className="font-medium text-stone-900">Add suggestion to next similar actions.</div>
        <div className="">
          We’ll take your instructions while working on similar action in the plan.
        </div>
      </div>

      <div className="space-y-2 rounded-lg border border-stone-200 bg-white px-6 py-5">
        <Textarea
          autoFocus={true}
          ref={textareaRef}
          autogrow
          variant="ghost"
          className="px-0"
          value={userInput}
          onChange={e => setUserInput(e.target.value)}
          placeholder="Instructions on what to do in the future..."
        />
        <div className="flex gap-2">
          <Button
            variant="outline"
            disabled={sendCommand.isPending}
            onClick={() => {
              setShouldShow(!shouldShow)
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={sendCommand.isPending || userInput === ''}
            className="w-full"
            onClick={sendIterationCommand}
          >
            {sendCommand.isPending ? 'Adding suggestion...' : 'Add Suggestion'}
          </Button>
        </div>
      </div>
    </div>
  )
}
