import { ITERATION_COMMANDS } from '@/const/const'
import { useMutation } from '@tanstack/react-query'
import { createIterationCommandFirebaseFunction } from '@/services/Firebase.ts'
import { z } from 'zod'

const ExtendCommand = z.object({
  command: z.literal(ITERATION_COMMANDS.EXTEND),
  commandArgs: z.object({
    prompt: z.string(),
    iteration_id: z.string(),
    blueprint_id: z
      .string()
      .nullish()
      .default(null)
      .optional()
      .transform(value => (value === '' ? null : value)),
  }),
})

const ResumeCommandSchema = z.object({
  command: z.literal(ITERATION_COMMANDS.RESUME),
  commandArgs: z.never().optional(),
})

const PauseCommandSchema = z.object({
  command: z.literal(ITERATION_COMMANDS.PAUSE),
  commandArgs: z.never().optional(),
})

const CancelCommandSchema = z.object({
  command: z.literal(ITERATION_COMMANDS.CANCEL),
  commandArgs: z.never().optional(),
})

const RestartCommandSchema = z.object({
  command: z.literal(ITERATION_COMMANDS.RESTART),
  commandArgs: z.never().optional(),
})

const SuggestionCommandSchema = z.object({
  command: z.literal(ITERATION_COMMANDS.SUGGESTION),
  commandArgs: z.object({
    suggestion: z.string(),
  }),
})

const RollbackCommandSchema = z.object({
  command: z.literal(ITERATION_COMMANDS.ROLLBACK),
  commandArgs: z.object({
    iteration_id: z.string(),
    element_index: z.number().positive().optional(),
    step_index: z.number().positive().optional(),
    action_index: z.number().positive().optional(),
  }),
})

const InputAnswerCommandSchema = z.object({
  command: z.literal(ITERATION_COMMANDS.INPUT_ANSWER),
  commandArgs: z.object({
    request_id: z.string(),
    requests: z.array(
      z.object({
        request: z.string(),
        type: z.enum(['text', 'secret']),
        user_value: z.string(),
        label: z.string(),
      })
    ),
  }),
  iterationId: z.string(),
})

// send update to get the plan. Send validate to indicate that the plan is correct
const RoleMessageCommandSchema = z.object({
  command: z.literal(ITERATION_COMMANDS.ROLE_MESSAGE),
  commandArgs: z.object({
    role: z.string(),
    content: z.object({
      choice: z.enum(['update', 'validate']),
      message: z.string(),
    }),
  }),
})

const AddBreakpointCommandSchema = z.object({
  command: z.literal(ITERATION_COMMANDS.ADD_BREAKPOINT),
  commandArgs: z
    .object({
      roles: z.string().array().optional(),
      element_index: z.number().nonnegative().nullish(),
      step_index: z.number().nonnegative().nullish(),
    })
    .default({}),
})

const DelBreakpointCommandSchema = z.object({
  command: z.literal(ITERATION_COMMANDS.DEL_BREAKPOINT),
  commandArgs: z
    .object({
      roles: z.string().array().optional(),
      element_index: z.number().nonnegative().nullish(),
      step_index: z.number().nonnegative().nullish(),
    })
    .default({}),
})

const RoleConfigCommandSchema = z.object({
  command: z.literal(ITERATION_COMMANDS.ROLE_CONFIG),
  commandArgs: z.object({
    role: z.string().nullish().default(null),
    content: z.object({
      interactive: z.boolean(),
    }),
  }),
})

const UserInputCommandSchema = z.discriminatedUnion('command', [
  ExtendCommand,
  ResumeCommandSchema,
  PauseCommandSchema,
  RestartCommandSchema,
  InputAnswerCommandSchema,
  SuggestionCommandSchema,
  RollbackCommandSchema,
  RoleMessageCommandSchema,
  AddBreakpointCommandSchema,
  DelBreakpointCommandSchema,
  RoleConfigCommandSchema,
  CancelCommandSchema,
])

export type UserInputCommand = z.input<typeof UserInputCommandSchema>

export type RoleMessageContent = z.infer<typeof RoleMessageCommandSchema>['commandArgs']['content']

export async function sendUserCommand(iterationId: string, command: UserInputCommand) {
  const payload = {
    iterationId: iterationId,
    dontStartGunslinger: !SHOULD_START_GUNSLINGER,
    ...UserInputCommandSchema.parse(command),
  }

  return createIterationCommandFirebaseFunction(payload)
}

const SHOULD_START_GUNSLINGER = !window?.location?.host?.startsWith('old.')

export function useSendUserCommand(iterationId: string | null, scope?: string) {
  return useMutation({
    mutationFn: async (command: UserInputCommand) => {
      if (!iterationId) {
        // hooks can be only top level, so we can't have if (iterationId) { useSendUserCommand() }
        throw new Error('iterationId is required')
      }
      const result = await sendUserCommand(iterationId, command)
      return result.data as { id: string } // user command, in reality
    },
    mutationKey: ['sendUserCommand', iterationId],
    scope: scope ? { id: scope } : undefined,
  })
}
