import { useRef, useEffect, useState } from 'react'
import { useIterationQuery } from '../useIterationQuery.ts'
import { useRoleMessageCommand } from './useRoleMessageCommands.ts'

// this goofball works only for `update` choice. With `validate` we don't really have to do this.
export function useIsUpdatingSpecifications(iterationId: string | null) {
  const iterationQuery = useIterationQuery(iterationId)
  const iterationSpecs = iterationQuery.data?.specifications ?? ''

  const command = useRoleMessageCommand(iterationId, 'pending')

  const isUpdateRoleCommand =
    command?.command === 'ROLE_MESSAGE' && command.commandArgs.content.choice === 'update'

  const [isLoading, setIsLoading] = useState(false)
  const currentSpecs = useRef('')

  useEffect(() => {
    if (isUpdateRoleCommand) {
      setIsLoading(true)
      currentSpecs.current = iterationSpecs
      return
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isUpdateRoleCommand,
    // iteration specs are not being tracked here by design.
    // iterationSpecs,
  ])

  useEffect(() => {
    setIsLoading(false)
  }, [iterationSpecs])

  return isLoading
}
