import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.tsx'
import { LandPlot } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import { insertNewlinesIfNeeded } from '@/components/actions/utils.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'

export default function ReflectAndPlanAction({ action, isLast = false, isInteractive = false }) {
  const reflection = action?.args?.reflection
  const plan = action?.args?.plan
  let isPlanArray = Array.isArray(plan)
  let firstPlan = plan
  if (isPlanArray) {
    firstPlan = plan[0]
  }

  // const reflectionWithNewLines = insertNewlinesIfNeeded(reflection)
  // const planWithNewLines = insertNewlinesIfNeeded(plan)

  const reflectionWithNewLines = reflection
  let content = reflection || firstPlan || action.name
  let detailsContent = `## REFLECTION:\n${reflectionWithNewLines}\n\n`

  if (isPlanArray) {
    detailsContent += '## PLAN:\n'
    plan?.forEach((planItem, index) => {
      detailsContent += `${index + 1}. ${insertNewlinesIfNeeded(planItem)}\n`
    })
  } else {
    detailsContent += '## PLAN:\n' + insertNewlinesIfNeeded(plan) + '\n'
  }

  detailsContent += '\n\n## ARTIFACTS:\n'
  /** @type {object | array | string}  */
  const artifacts = action?.args?.artifacts
  let artifactsSplit
  if (Array.isArray(artifacts)) {
    artifactsSplit = artifacts
  } else if (typeof artifacts === 'string') {
    artifactsSplit = artifacts?.split(',')
  } else if (typeof artifacts === 'object' && artifacts !== null) {
    artifactsSplit = Object.entries(artifacts).map(([key, value]) => `${key}: ${value}`)
  }

  if (artifactsSplit) {
    artifactsSplit?.forEach(artifact => {
      detailsContent += `- ${artifact}\n`
    })
  }

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={LandPlot}
      isLast={isLast}
      isInteractive={isInteractive}
      title="Reflect & plan"
    >
      <AActionContentGenericLayout>
        <AActionContentDescription content={content} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

ReflectAndPlanAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
  isInteractive: PropTypes.bool,
}
