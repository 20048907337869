import GenericAction from '@/components/actions/GenericAction.tsx'
import { SquareMousePointer } from 'lucide-react'
import AActionPrompt from '@/components/actions/components/AActionPrompt.tsx'
import { ITERATION_STATUSES } from '@/const/const.js'
import { getOutputVersion } from '@/lib/action-utils'
import { AddressBar, BrowserWindow } from './BroswerAction/BrowserAction.tsx'
import AActionTextBox from './components/AActionTextBox.tsx'
import MImagePrevew from '../molecules/actions/MImagePreview.tsx'

export default function InteractiveWebUIAction({
  action,
  isLast = false,
  isInteractive = false,
}: {
  action: InteractiveWebUIActionPayload
  isLast?: boolean
  isInteractive?: boolean
}) {
  const imagePrompt = action?.args?.comment

  const isDone = action.status === ITERATION_STATUSES.DONE

  const operationalOutput = action.outputs.find(output => output.content.final_result !== null)
  const outputContent =
    getOutputVersion(operationalOutput) === 2
      ? operationalOutput?.content
      : (operationalOutput as unknown as OperationalOutput['content'])

  return (
    <GenericAction
      action={action}
      detailsContent={JSON.stringify(action, null, 2)}
      actionIcon={SquareMousePointer}
      isLast={isLast}
      isInteractive={isInteractive}
      title={action.args.comment ?? 'Interactive website check'}
    >
      <AActionTextBox title="Instructions">{imagePrompt}</AActionTextBox>
      {!isDone && (
        <BrowserWindow className="mt-5">
          <AddressBar url={action.args.url} />
        </BrowserWindow>
      )}
      {isDone && outputContent && outputContent.final_result && (
        <>
          <MImagePrevew
            className="my-5"
            src={outputContent.screenshot_public_url_large ?? ''}
            alt={`Screenshot of ${action.args.url}`}
            downloadUrl={outputContent.screenshot_public_url_original ?? ''}
            fileName={action.args.url}
          />
          <AActionTextBox title="Analysis">
            {outputContent.final_result.split('\n').map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </AActionTextBox>
        </>
      )}
    </GenericAction>
  )
}

// write typescript type for theAction
export type InteractiveWebUIActionPayload = {
  actionId: string
  args: {
    url: string
    query: string
    comment: string
  }
  elementId: string
  stepId: string
  startSnapshotHash: any
  name: string
  obsolete: boolean
  subtype: any
  index: number
  iterationId: string
  hideArgsFromPrompt: boolean
  id: string
  endSnapshotHash: any
  type: 'function'
  startCommitHash: string
  endCommitHash: string
  status: string
  outputs: InteractiveWebUIActionOutput[]
  createdAt: {
    _seconds: number
    _nanoseconds: number
  }
  endedAt: number
  startedAt: number
  elapsedTime: number
  updatedAt: {
    _seconds: number
    _nanoseconds: number
  }
  elementIndex: number
  stepIndex: number
  repoURI?: string
}

type ErrorOutput = {
  status: 'error'
  error: string
  message: string
  content: {
    final_result: null
  }
}

type FailedOutput = {
  status: 'error'
  error: string
  message: string
  content: {
    final_result: string
    screenshot_public_url_large: string | null
    screenshot_public_url_small: string | null
    screenshot_public_url_original: string | null
  }
}

type SuccessOutput = {
  status: 'success'
  error: null
  message: string
  content: {
    final_result: string

    screenshot_public_url_large: string
    screenshot_public_url_small: string
    screenshot_public_url_original: string
  }
}

type OperationalOutput = FailedOutput | SuccessOutput

type InteractiveWebUIActionOutput = OperationalOutput | ErrorOutput
