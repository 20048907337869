import { cn } from '@/lib/utils.ts'

export function ATabHeader({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}) {
  return (
    <div
      className={cn(
        'flex grow-0 items-center gap-4 border-b border-border bg-base-background',
        className
      )}
    >
      {children}
    </div>
  )
}
