/** @type {object} */
export const ACTION_NAMES = {
  RESEARCH: 'research',
  DOCUMENT: 'document',
  CREATE_FILE: 'create_file',
  OVERWRITE_FILE: 'overwrite_file',
  PRECISELY_EDIT_FILE: 'precisely_edit_file',
  DONE: 'done',
  STEP_DONE: 'step_done',
  TERMINAL_SEND: 'terminal_send',
  RUN_COMMAND: 'run_command',
  REFLECT: 'reflect',
  RUN_DAEMON: 'run_daemon',
  STOP_DAEMON: 'stop_daemon',
  GET_FILE_CONTENTS: 'get_file_contents',
  LIST_FILES: 'list_files',
  LLM_REQUEST: 'llm-request',
  LLM_RESPONSE: 'llm-response',
  BROWSER: 'browser',
  HINTS: 'hint',
  EXTRACT_BRAND_COLORS: 'extract_brand_colors',
  USER_SUGGESTION: 'user_suggestion',
  GENERATE_IMAGE: 'generate_image',
  GENERATE_IMAGE_LIST: 'generate_image_list',
  EXTRACT_BRAND_LOGOTYPE: 'extract_brand_logotype',
  REFLECTOR: 'reflector',
  UPDATE_ENV_VARIABLE: 'dotenv_update_variable',
  CONTENTUFUL_UPDATE_PROMOTIONAL_ENTRIES: 'contentful_update_promotional_entries',
  CONTENTUFUL_UPLOAD_AND_LINK_IMAGES: 'contentful_upload_and_link_images',
  CREATE_COMPANY_BRIEF: 'create_company_brief',
  HTTP_REQUEST: 'http_request',
  WEBSITE_VISUAL_INSPECTOR: 'website_visual_inspector',
  INTERACTIVE_WEB_UI_TOOL: 'interactive_web_ui_tool',
}
