import { useCallback, useEffect } from 'react'

import { Button } from '@/components/ui/button'
import { ArrowUp } from 'lucide-react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import FormField from '@/components/ui/form-field.tsx'
import { ABorderBox } from '@/components/atoms/ABorderBox.tsx'
import ALogoSpinner from '@/components/atoms/ALogoSpinner.tsx'
import { useToast } from '@/components/ui/use-toast'
import { useSendUserCommand } from '@/lib/sendUserCommand.ts'
import { ITERATION_COMMANDS } from '@/const/const.ts'
import { cn } from '@/lib/utils.ts'

type MRunningChatProps = {
  iterationId: string
  className?: string
}

export default function MRunningChat({ iterationId, className }: MRunningChatProps) {
  const { toast } = useToast()
  const sendCommand = useSendUserCommand(iterationId)
  const form = useForm({
    resolver: zodResolver(promptSchema),
    defaultValues: {
      prompt: '',
    },
  })
  const sendIterationCommand = useCallback(
    async (prompt: string) => {
      sendCommand.mutate(
        {
          command: ITERATION_COMMANDS.SUGGESTION,
          commandArgs: { suggestion: prompt },
        },
        {
          onError(error) {
            console.error('Error creating user suggestion', error)
            toast({
              variant: 'destructive',
              title: 'Error adding user suggestion 😔',
              description: 'Try refreshing the page or contact Proofs team.',
            })
          },
          onSuccess() {
            form.setValue('prompt', '')
          },
        }
      )
    },
    [iterationId]
  )

  const onSubmit = useCallback(
    ({ prompt }: PromptFormData) => {
      sendIterationCommand(prompt)
    },
    [sendIterationCommand, prompt]
  )

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
        e.preventDefault()
        if (form.formState.isValid && !sendCommand.isPending) {
          form.handleSubmit(onSubmit)()
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [form.formState.isValid, sendCommand.isPending, form.handleSubmit, onSubmit])

  const isLoadingUserCommand = sendCommand.isPending
  const Icon = isLoadingUserCommand ? ALogoSpinner : ArrowUp
  // isDone as in: the iteration is done. So either we don't have a role or role is done.

  return (
    <div className={cn('space-y-2', className)}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="">
        <ABorderBox
          isEnabled={isLoadingUserCommand}
          borderClasname="text-border"
          className="rounded-md bg-white"
        >
          <FormField.Root control={form.control} name="prompt">
            <FormField.Textarea
              {...form.register('prompt')}
              autogrow
              disabled={isLoadingUserCommand}
              variant="ghost"
              className="resize-none bg-white shadow-none focus-visible:ring-0"
              placeholder="Leave suggestion..."
            />
          </FormField.Root>
          <div className="p-4 text-right">
            <Button className="ml-auto" disabled={isLoadingUserCommand} type="submit">
              <Icon className="size-4" />
            </Button>
          </div>
        </ABorderBox>
      </form>
    </div>
  )
}

const promptSchema = z.object({
  prompt: z.string().trim().min(3),
})
type PromptFormData = z.output<typeof promptSchema>
