import { stackTemplate, USECASE_ID } from '@/const/stackTemplates'
import nunjucks from 'nunjucks'

const BASE_PROMPT = `Let's build a POC`
export function constructPrompt(
  project: {
    techStack: string
    requirements: string
    prospectName: string
    prospectWebsite: string
  },
  templatePrompt: string
) {
  if (project == null) {
    return BASE_PROMPT
  }

  if (templatePrompt) {
    const env = new nunjucks.Environment(null, {
      autoescape: false,
    })
    return env.renderString(templatePrompt, {
      prospectName: project.prospectName,
      prospectWebsite: project.prospectWebsite,
    })
  } else {
    let newPrompt = ''
    if ([USECASE_ID.CUSTOM, USECASE_ID.NEXT_FORGE].includes(project?.techStack)) {
      newPrompt = stackTemplate[project?.techStack].promptExtras
    } else {
      newPrompt = `${BASE_PROMPT} for a new prospect, ${project.prospectName}.
Their website is ${project.prospectWebsite}`
      if (stackTemplate[project?.techStack]?.promptExtras != null) {
        newPrompt += `\n\n${stackTemplate[project?.techStack].promptExtras}`
      }
    }

    if (project?.requirements) {
      newPrompt += `\n\n${project.requirements}`
    }
    return newPrompt
  }
}
