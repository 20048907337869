import { useCallback, useState, useRef, useEffect } from 'react'
import { useToast } from '@/components/ui/use-toast'
import { ITERATION_ROLES, IterationRole } from '@/const/const'

import { Button } from '@/components/ui/button'
import { Check, Loader, MoveLeft, SquareArrowRight } from 'lucide-react'
import { Textarea } from '@/components/ui/textarea'
import { usePromptMutation } from '@/lib/iterations/usePromptMutation.ts'
import { RoleRegistry } from '@/services/firebase_shared_types.ts'

const usePromptFlow = (roleName: string, role: RoleRegistry[string], iterationId: string) => {
  const [isApproveStep, setIsApproveStep] = useState(true)
  const [isLoadingUserCommand, setIsLoadingUserCommand] = useState(false)

  const userCommandMutation = usePromptMutation(iterationId, roleName)

  const roleStatus = role?.status

  useEffect(() => {
    if (isLoadingUserCommand) {
      setIsApproveStep(true)
    }
  }, [isLoadingUserCommand])

  // Track when the the user sends a command and waits for
  // the result, i.e. updated specifications

  // loading state: when waiting for message and role status is waiting_for

  useEffect(() => {
    if (roleStatus === 'waiting_for_message' && isLoadingUserCommand) {
      setIsLoadingUserCommand(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleStatus /* isLoadingUserCommand uis ignored in purpose */])

  useEffect(() => {
    if (userCommandMutation.isLoading) {
      // user initialized a command
      setIsLoadingUserCommand(true)
    }
  }, [userCommandMutation.isLoading])

  return {
    isApproveStep,
    userCommandMutation,
    backToEdit: () => setIsApproveStep(false),
    backToRequest: () => setIsApproveStep(true),
    role,
    isLoadingUserCommand,
  }
}

export default function MBaseContinuationChat({
  iterationId,
  className,
  onFinished,
  role,
  roleName,
}: {
  iterationId: string
  className?: string | undefined
  onFinished: () => void
  roleName: string
  role: RoleRegistry[string]
}) {
  const { backToEdit, isApproveStep, userCommandMutation, isLoadingUserCommand, backToRequest } =
    usePromptFlow(roleName, role, iterationId)

  const { toast } = useToast()

  const [promptHistory, setPromptHistory] = useState<string[]>([])
  const handleSubmitPrompt = useCallback(
    async ({ prompt }: { prompt: string }) => {
      if (!prompt || prompt?.length === 0 || !iterationId) {
        toast({
          variant: 'destructive',
          title: 'We need instructions!',
          description: 'Please provide instructions on how to continue',
        })
        return
      }

      userCommandMutation.mutate(prompt)
      setPromptHistory([...promptHistory, prompt])
    },
    [iterationId, userCommandMutation, toast, promptHistory]
  )

  const handleValidate = useCallback(() => {
    userCommandMutation.validate()
    onFinished()
  }, [userCommandMutation, onFinished])

  const isLoading = isLoadingUserCommand

  return (
    <div className="bg-white">
      <RoleHeader roleName={roleName as IterationRole} />

      {isApproveStep ? (
        <div className="flex items-center justify-start gap-4 bg-white px-8 py-6">
          <Button variant="outline" onClick={backToEdit}>
            Request changes
          </Button>
          <Button className="w-full" onClick={handleValidate} disabled={isLoading}>
            {isLoading ? <Loader className="size-4 animate-spin" /> : <Check className="size-4" />}
            Approve
          </Button>
        </div>
      ) : (
        <PromptEditor isLoading={isLoading} onBack={backToRequest} onSubmit={handleSubmitPrompt} />
      )}
    </div>
  )
}

function PromptEditor({
  onBack,
  onSubmit,
  isLoading,
}: {
  isLoading: boolean
  onBack: () => void
  onSubmit: (props: { prompt: string }) => void
}) {
  const [prompt, setPrompt] = useState<string>('')
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus()
      const length = textareaRef.current.value.length
      textareaRef.current.setSelectionRange(length, length)
    }
  }, [])

  const handleOnSubmit = useCallback(() => {
    onSubmit({ prompt })
  }, [prompt, onSubmit])

  return (
    <div className="bg-white">
      <div className="px-6 py-5">
        <div className="flex w-full flex-col items-stretch gap-4">
          <Textarea
            ref={textareaRef}
            variant="ghost"
            resize={false}
            value={prompt}
            rows={3}
            onChange={e => setPrompt(e.target.value)}
            placeholder="Your instructions here..."
          />
        </div>
      </div>
      <div className="flex w-full gap-2 px-6 pb-5">
        <Button size="icon" variant="outline" className="size-10" onClick={onBack}>
          <MoveLeft className="size-4" />
        </Button>
        <Button
          disabled={isLoading}
          className="w-full tracking-tight"
          size="lg"
          onClick={handleOnSubmit}
        >
          <span>Request</span>
          {isLoading ? (
            <Loader className="ml-2 size-4 animate-spin" />
          ) : (
            <SquareArrowRight className="ml-2 size-4" />
          )}
        </Button>
      </div>
    </div>
  )
}

function RoleHeader({ roleName }: { roleName: IterationRole }) {
  switch (roleName) {
    case ITERATION_ROLES.HIGH_LEVEL_PLANNER:
      return <HighLevelPlannerHeader />
    case ITERATION_ROLES.ELEMENT_PLANNER:
      return <ElementPlannerHeader />

    default:
      return null
  }
}

function HighLevelPlannerHeader() {
  return <h3 className="my-2 px-6 text-lg font-medium">Please review the high-level plan.</h3>
}

function ElementPlannerHeader() {
  return (
    <h3 className="my-2 px-6 text-lg font-medium">
      Please review proposed steps for the current part of the plan.
    </h3>
  )
}
