import ALogoSpinner from '@/components/atoms/ALogoSpinner.tsx'
import { cn } from '@/lib/utils.ts'
import { AgentRole } from '@/services/firebase_shared_types.ts'
import { CheckCircle } from 'lucide-react'
import { useMemo } from 'react'

type AnalystStartupSequenceProps = {
  role: AgentRole | null
  isInitializing: boolean
}

export function AnalystStartupSequence({ role, isInitializing }: AnalystStartupSequenceProps) {
  const currentStatusIndex = useMemo(
    () => getStatusIndex(role?.status, role?.turn?.label),
    [role?.status, role?.turn?.label]
  )

  function isDone(status?: AgentStatus, turn?: AgentTurn) {
    if (currentStatusIndex === -1) {
      // it means it's none of the states.
      return true
    }
    return getStatusIndex(status, turn) < currentStatusIndex
  }
  function isPending(status?: AgentStatus, turn?: AgentTurn) {
    const minIndex = Math.max(
      getStatusIndex(role?.status, undefined),
      getStatusIndex(undefined, role?.turn?.label)
    )
    if (minIndex === -1) {
      return false
    }
    return getStatusIndex(status, turn) > currentStatusIndex
  }

  if (!isInitializing || !role) {
    return null
  }

  return (
    <div className="space-y-3">
      {trackedStates
        .filter(({ status, turn }) => !isPending(status, turn))
        .map(state => {
          return (
            <ChecklistItem
              key={state.status ?? '--' + state.turn ?? '--'}
              done={isDone(state.status, state.turn)}
            >
              {isDone(state.status, state.turn) ? state.titleDone : state.titleInProgress}
            </ChecklistItem>
          )
        })}
    </div>
  )
}

type AgentStatus = AgentRole['status']
type AgentTurn = NonNullable<AgentRole['turn']>['label']

function ChecklistItem({ children, done }: { children: React.ReactNode; done: boolean }) {
  const Icon = done ? CheckCircle : ALogoSpinner

  return (
    <div
      className={cn(
        'flex translate-y-0 items-center space-x-2 text-sm text-muted-foreground',
        'opacity-100 transition-[opacity,transform] starting:translate-y-4 starting:opacity-0'
      )}
    >
      <Icon className="size-4" />
      <span className={cn(!done && 'text-base-foreground')}>{children}</span>
    </div>
  )
}

const trackedStates: {
  titleDone: string
  titleInProgress: string
  status?: AgentStatus
  turn?: AgentTurn
}[] = [
  {
    titleDone: 'Analyst is ready for work',
    titleInProgress: 'Analyst is starting up...',
    status: 'not_started',
  },
  {
    titleDone: 'Analyzing the requirements and creating initial specifications',
    titleInProgress: 'Analyzing the requirements and creating initial specifications',
    status: 'in_progress',
    turn: 100,
  },
  {
    titleDone: 'Reflecting on the initial specifications',
    titleInProgress: 'Reflecting on the initial specifications',
    status: 'in_progress',
    turn: 200,
  },
  {
    titleDone: 'Making sure the specifications meet the requirements',
    titleInProgress: 'Making sure the specifications meet the requirements',
    status: 'in_progress',
    turn: 300,
  },
  {
    titleDone: 'Outputting a specification document',
    titleInProgress: 'Outputting a specification document',
    status: 'in_progress',
    turn: 400,
  },
]

function getStatusIndex(status?: AgentStatus, turn?: AgentTurn) {
  return Math.max(
    status ? trackedStates.findIndex(state => state.status === status) : -1,
    turn ? trackedStates.findIndex(state => state.turn === turn) : -1
  )
}
