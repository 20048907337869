// import { Dialog, DialogTrigger, DialogContent } from '@/components/catalyst/dialog.jsx'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogClose,
} from '@/components/ui/dialog.tsx'

import { useEffect, useState } from 'react'

import useStore from '@/stores/useStore'

import { useForm, useWatch } from 'react-hook-form'
import FormField from '@/components/ui/form-field.tsx'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx'
import { SiGithub } from '@icons-pack/react-simple-icons'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx'
import { Button } from '@/components/ui/button.tsx'
import { useIterationQuery } from '@/lib/iterations/useIterationQuery.ts'
import {
  GitHubFormData,
  linkGithubToProjectSchema,
  useConnectGithubToProject,
  useFetchGithubAuthorizationUrl,
  useGithubRepos,
  useInstallGithub,
} from '@/components/molecules/project-details/CreateProject/hooks.ts'
import { useConnectGithub } from '@/components/molecules/project-details/CreateProject/hooks.ts'
import { GitHubLogoIcon } from '@radix-ui/react-icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { convertGitHubSSHToHTTPS } from '@/lib/git-utils.js'

export default function ConnectGithubDialog({ iterationId }: { iterationId: string }) {
  const [isOpen, setIsOpen] = useState(false)
  const iterationQuery = useIterationQuery(iterationId)

  const user = useStore(state => state.user)
  const hasGithub = Boolean(user.github && Object.keys(user.github).length > 0)

  const [isConnected, setIsConnected] = useState(hasGithub)

  const onCancel = () => {
    setIsConnected(false)
  }

  const connectGithub = useConnectGithub()
  const installGithub = useInstallGithub()

  if (!iterationQuery.isSuccess) {
    return null
  }

  const iteration = iterationQuery.data

  const hasRepoSet = Boolean(iteration.repoURI)

  if (hasRepoSet) {
    return (
      <Button size="sm" variant="outline" asChild>
        <a
          target="_blank"
          rel="noreferrer"
          href={`${convertGitHubSSHToHTTPS(iteration.repoURI)}/tree/${iteration.branchName ?? 'main'}`}
        >
          <GitHubLogoIcon /> Repository
        </a>
      </Button>
    )
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button size="sm" variant="outline">
          <GitHubLogoIcon /> Connect Github
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Connect Github</DialogTitle>
          <DialogClose />
        </DialogHeader>
        <h2 className="text-sm tracking-tight text-muted-foreground">
          Connect this project to access the code.
        </h2>

        {!isConnected && (
          <RepositoryConnectionChoiceMethod
            onSelect={setIsConnected}
            hasGithub={hasGithub}
            connectGithub={connectGithub}
          />
        )}
        {isConnected && !hasGithub && <AwaitingGithubConnection onCancel={onCancel} />}
        {isConnected && hasGithub && (
          <RepositoryGithubForm
            onReauth={connectGithub}
            onInstall={installGithub}
            projectId={iterationQuery.data.projectId}
            iterationId={iterationId}
            onSubmit={() => {
              setIsOpen(false)
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

const AwaitingGithubConnection: React.FC<{ onCancel: () => void }> = ({ onCancel }) => {
  return (
    <div className="mx-auto text-center">
      <h3 className="font-medium">Authorizing with GitHub...</h3>
      <p className="mb-2 mt-4 text-center text-muted-foreground">
        Come back to this page once the GitHub application
        <br />
        has been authorized. It will refresh automatically.
      </p>
      <Button variant="secondary" onClick={onCancel}>
        Cancel
      </Button>
    </div>
  )
}

const RepositoryGithubForm: React.FC<{
  onReauth: () => void
  onInstall: () => void
  onSubmit: () => void
  projectId: string
  iterationId: string
}> = ({ onReauth, onInstall, onSubmit, projectId, iterationId }) => {
  const user = useStore(state => state.user)
  const logins = Object.keys(user.github)

  const form = useForm<GitHubFormData>({
    resolver: zodResolver(linkGithubToProjectSchema),
    defaultValues: {
      username: logins[0],
    },
  })

  const selectedLogin = useWatch({
    control: form.control,
    name: 'username',
  })

  useEffect(() => {
    const logins = Object.keys(user.github)
    if (!selectedLogin) {
      form.setValue('username', logins[0])
    }
  }, [selectedLogin, user.github, form.setValue])

  const repos = useGithubRepos(selectedLogin)

  const connectGithubToProject = useConnectGithubToProject({ projectId, iterationId })

  return (
    <form
      onSubmit={form.handleSubmit(form => connectGithubToProject.mutateAsync(form).then(onSubmit))}
    >
      <FormField.Root name="username" control={form.control}>
        <FormField.Label>GitHub account</FormField.Label>
        <FormField.Controller
          render={({ field }) => (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" className="w-full content-start !justify-start">
                  <div className="flex items-center justify-start gap-3">
                    <div className="inline-flex gap-3 font-['Inter'] text-sm font-medium leading-tight text-stone-900">
                      <SiGithub className="size-4" />
                      <span>
                        @{selectedLogin}
                        <span className="font-normal"> account connected</span>
                      </span>
                    </div>
                  </div>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                className="mt-1 w-[var(--radix-dropdown-menu-trigger-width)] rounded border border-border bg-white p-2 shadow-lg"
                align="start"
              >
                {logins
                  .filter(login => login !== selectedLogin)
                  .map(login => (
                    <DropdownMenuItem key={login} onSelect={() => field.onChange}>
                      Select @{login}
                    </DropdownMenuItem>
                  ))}
                <DropdownMenuItem onSelect={onReauth}>Add GitHub account</DropdownMenuItem>
                <DropdownMenuItem onSelect={onInstall}>Authorize repositories</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        />
      </FormField.Root>
      <FormField.Root name="repoURI" control={form.control}>
        <FormField.Label>
          Pick repository
          <span className="ml-2 font-normal text-muted-foreground">
            {' '}
            Not seeing your repositories?{' '}
            <button
              id="check-permissions-btn"
              className="underline"
              onClick={onInstall}
              type="button"
            >
              Check permissions
            </button>
          </span>
        </FormField.Label>
        <FormField.Controller
          render={({ field }) => (
            <Select
              onOpenChange={open => open && repos.refetch()}
              onValueChange={field.onChange}
              value={field.value}
            >
              <SelectTrigger
                id="repositories"
                onClick={e => e.stopPropagation()}
                tabIndex={0}
                className="mt-1"
              >
                <SelectValue placeholder="Select a repository..." />
              </SelectTrigger>
              <SelectContent className="w-full">
                {repos.isLoading && <div className="px-4 py-2 text-center text-sm">Loading...</div>}
                {repos.isError && (
                  <div className="px-4 py-2 text-center text-sm">
                    An error occured. Please refresh the page.
                  </div>
                )}
                {repos.isSuccess && repos.data.length === 0 && (
                  <div className="px-4 py-2 text-center text-sm">No repositories found.</div>
                )}
                {repos.isSuccess &&
                  repos.data.map(option => {
                    return (
                      <SelectItem key={option.clone_url} value={option.clone_url}>
                        <div className="flex items-center gap-3">
                          <img className="size-4 rounded-full" src={option.owner.avatar_url} />
                          <span className="text-sm font-medium leading-tight text-stone-900">
                            {option.full_name}
                          </span>
                        </div>
                      </SelectItem>
                    )
                  })}
              </SelectContent>
            </Select>
          )}
        />
      </FormField.Root>
      <div className="mt-4 flex justify-end">
        <Button type="submit" disabled={connectGithubToProject.isPending}>
          Connect
        </Button>
      </div>
    </form>
  )
}

const RepositoryConnectionChoiceMethod: React.FC<{
  hasGithub: boolean
  connectGithub: () => void
  onSelect: (value: boolean) => void
}> = ({ hasGithub, onSelect, connectGithub }) => {
  const urlResult = useFetchGithubAuthorizationUrl()

  function onConnectGithub() {
    if (!urlResult.data) {
      throw new Error('should be only able to run if data is present')
    }
    if (!hasGithub) {
      connectGithub()
    }
    onSelect(true)
  }

  return (
    <div className="mx-auto flex max-w-sm flex-col p-4 text-center">
      <p className="mb-4 mt-2 grow tracking-tight text-muted-foreground">
        Connect with all or selected GitHub repositories. Proofs automatically gets all details.
      </p>
      <Button disabled={!urlResult.isSuccess} onClick={onConnectGithub}>
        <SiGithub className="mr-2 size-4" />
        <span>{hasGithub ? 'Continue with GitHub' : 'Connect GitHub'}</span>
      </Button>
    </div>
  )
}
