import ALogoSpinner from '@/components/atoms/ALogoSpinner.tsx'
import { IterationStatus, GunslingerJobStatus } from '@/const/const.ts'
import { cn } from '@/lib/utils.ts'
import { CheckCircle } from 'lucide-react'
import { useMemo } from 'react'

export default function IterationStartupSequence({
  currentStatus,
  currentVmStatus,
}: {
  currentStatus: IterationStatus
  currentVmStatus: GunslingerJobStatus
}) {
  const currentStatusIndex = useMemo(
    () => getStatusIndex(currentStatus, currentVmStatus),
    [currentStatus, currentVmStatus]
  )

  function isDone(status?: IterationStatus, vmStatus?: GunslingerJobStatus) {
    if (currentStatusIndex === -1) {
      // it means it's none of the states.
      return true
    }
    return getStatusIndex(status, vmStatus) < currentStatusIndex
  }
  function isPending(status?: IterationStatus, vmStatus?: GunslingerJobStatus) {
    const minIndex = Math.max(
      getStatusIndex(currentStatus, undefined),
      getStatusIndex(undefined, currentVmStatus)
    )
    if (minIndex === -1) {
      return false
    }

    return getStatusIndex(status, vmStatus) > currentStatusIndex
  }

  return (
    <div className="space-y-3">
      {trackedStates
        .filter(({ status, vmStatus }) => !isPending(status, vmStatus))
        .map(state => {
          return (
            <IterationChecklistItem
              key={state.status ?? '--' + state.vmStatus ?? '--'}
              done={isDone(state.status, state.vmStatus)}
            >
              {isDone(state.status, state.vmStatus) ? state.titleDone : state.titleInProgress}
            </IterationChecklistItem>
          )
        })}
    </div>
  )
}

function IterationChecklistItem({ children, done }: { children: React.ReactNode; done: boolean }) {
  const Icon = done ? CheckCircle : ALogoSpinner

  return (
    <div
      className={cn(
        'flex translate-y-0 items-center space-x-2 text-sm text-muted-foreground',
        'starting:opacity-0 starting:translate-y-4 opacity-100 transition-[opacity,transform]'
      )}
    >
      <Icon className="size-4" />
      <span className={cn(!done && 'text-base-foreground')}>{children}</span>
    </div>
  )
}

const trackedStates: {
  titleDone: string
  titleInProgress: string
  status?: IterationStatus
  vmStatus?: GunslingerJobStatus
}[] = [
  {
    titleDone: 'Requested Virtual Machine',
    titleInProgress: 'Requesting Virtual Machine',
    status: 'RUN_REQUESTED',
  },
  {
    titleDone: 'Started Virtual Machine',
    titleInProgress: 'Starting Virtual Machine',
    vmStatus: 'STARTING',
  },
  {
    titleDone: 'Started Agents',
    titleInProgress: 'Starting Agents',
    vmStatus: 'STARTED',
    status: 'STARTING_UP',
  },
  {
    titleDone: 'Built Docker Container',
    titleInProgress: 'Building Docker Container',
    status: 'BUILDING_DOCKER_CONTAINER',
  },
  {
    titleDone: 'Synced Source Code',
    titleInProgress: 'Syncing Source Code',
    status: 'SYNCING_SOURCE_CODE',
  },
]

function getStatusIndex(status?: IterationStatus, vmStatus?: GunslingerJobStatus) {
  return Math.max(
    status ? trackedStates.findIndex(state => state.status === status) : -1,
    vmStatus ? trackedStates.findIndex(state => state.vmStatus === vmStatus) : -1
  )
}
