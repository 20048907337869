import { useEffect } from 'react'
import { CONTINUATION_CHAT_ROLES } from '@/const/const'

import { useIterationActiveRole } from '@/lib/iterations/useIterationActiveRole.ts'
import { usePromptMutation } from '@/lib/iterations/usePromptMutation.ts'
import { useIterationQuery } from '@/lib/iterations/useIterationQuery.ts'
import { useUserCommandQuery } from './userUserCommandQuery.ts'
import { useActiveRoleLoading } from './ActiveRoleLoadingContext.tsx'
import { useRoleMessageCommand } from '@/lib/iterations/analyst/useRoleMessageCommands.ts'

export const useAnalystFlow = (iterationId: string | null) => {
  const { isLoading, setIsLoading, isInitializing } = useActiveRoleLoading()

  const { data: iteration } = useIterationQuery(iterationId)
  const role = useIterationActiveRole(iterationId, CONTINUATION_CHAT_ROLES)

  const userCommandMutation = usePromptMutation(iterationId, role?.name ?? null)

  const roleStatus = role?.status
  const commandId = userCommandMutation.mutation.data?.id ?? null
  const userCommand = useUserCommandQuery(commandId)
  const lastUpdate = useRoleMessageCommand(iterationId)

  useEffect(() => {
    if (userCommandMutation.isLoading) {
      // user initialized a command
      setIsLoading(true)
    }
    const isUserCommandSuccessful =
      userCommand.isSuccess && userCommand.data && userCommand.data.status === 'SUCCESS'
    const roleIsNoLongerProcessing = (() => {
      if (
        lastUpdate?.command === 'ROLE_MESSAGE' &&
        lastUpdate.commandArgs.content.choice === 'validate'
      ) {
        return role?.status === 'done'
      }
      return role?.status !== 'in_progress'
    })()
    if (isUserCommandSuccessful && roleIsNoLongerProcessing) {
      setIsLoading(false)
    }
  }, [
    userCommand.isSuccess,
    userCommand.data,
    iteration?.specifications,
    userCommandMutation.isLoading,
    setIsLoading,
    roleStatus,
    role?.status,
  ])

  return {
    userCommandMutation,
    role,
    isLoadingUserCommand: isLoading,
    isInitializing,
  }
}
