import { Button } from '@/components/ui/button'
import { CircleStop } from 'lucide-react'
import { useCallback, useState } from 'react'
import { ITERATION_COMMANDS, ITERATION_STATUSES } from '@/const/const'
import { useUserCommandFlow } from '@/lib/iterations/useUserCommandFlow'
import { MConfirmationDialog } from '@/components/molecules/MConfirmationDialog.tsx'

export function CancelIterationButton({ iterationId }: { iterationId: string }) {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const { sendCommandMutation, isLoadingUserCommand } = useUserCommandFlow(
    iterationId,
    ITERATION_STATUSES.DONE
  )

  const pauseIteration = useCallback(async () => {
    sendCommandMutation.mutate({ command: ITERATION_COMMANDS.CANCEL })
  }, [sendCommandMutation])

  return (
    <>
      <Button
        variant="secondary"
        onClick={() => setShowConfirmDialog(true)}
        disabled={isLoadingUserCommand}
      >
        <CircleStop />
        {isLoadingUserCommand ? 'Aborting...' : 'Abort'}
      </Button>

      <MConfirmationDialog
        isOpen={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={() => {
          pauseIteration()
          setShowConfirmDialog(false)
        }}
        title="Stop project"
        description="Are you sure you want to stop this project? You'll keep your current progress, but loose plan for next actions. You'll be able to continue project with new instructions. This action cannot be undone."
        confirmLabel="Stop Project"
        variant="destructive"
        actionButtonClassName={'bg-red-500'}
      />
    </>
  )
}
