import { useState, useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { AlarmClock, Eye, EyeOff, Key } from 'lucide-react'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import { ITERATION_COMMANDS } from '@/const/const'
import { cn } from '@/lib/utils'
import { UserInputCommand, useSendUserCommand } from '@/lib/sendUserCommand'
import { Separator } from '@/components/ui/separator'
import { InputRequestCommand, InputRequestCommandSchema } from '@/services/firebase_shared_types'
import { useUserCommandQuery } from '@/lib/iterations/userUserCommandQuery.ts'

export function UserInputForm({
  initialData,
  iterationId,
  className,
}: {
  initialData: InputRequestCommand
  iterationId: string
  className?: string
}) {
  const [showPasswords, setShowPasswords] = useState<{ [key: number]: boolean }>({})
  const [shouldHide, setShouldHide] = useState(false)
  const { toast } = useToast()
  const sendCommand = useSendUserCommand(iterationId)

  const [commandId, setCommandId] = useState<string | null>(null)

  const form = useForm<InputRequestCommand>({
    resolver: zodResolver(InputRequestCommandSchema),
    defaultValues: {
      ...initialData,
      args: {
        requests: initialData.args.requests.sort(sortRequestsWithSecretsFirst),
      },
    },
  })

  const onSubmit = useCallback(
    async (data: InputRequestCommand) => {
      const command: UserInputCommand = {
        ...data,
        commandArgs: { ...data.args, request_id: initialData.id! },
        command: ITERATION_COMMANDS.INPUT_ANSWER,
        iterationId,
      }

      sendCommand.mutate(command, {
        onError(error) {
          console.error('Error creating an answer', error)
          toast({
            variant: 'destructive',
            title: 'Error creating an answer 😔',
            description: 'Try refreshing the page or contact Proofs team.',
          })
        },
        onSuccess({ id }) {
          setCommandId(id)
        },
      })
    },
    [toast, initialData, iterationId, sendCommand]
  )

  const commandQuery = useUserCommandQuery(commandId)

  useEffect(() => {
    if (commandQuery.data?.status === 'SUCCESS') {
      setShouldHide(true)
    }
  }, [commandQuery.data?.status])

  const isLoading =
    sendCommand.isPending || (sendCommand.isSuccess && commandQuery.data?.status !== 'SUCCESS')

  const togglePasswordVisibility = (index: number) => {
    setShowPasswords(prev => ({ ...prev, [index]: !prev[index] }))
  }

  if (shouldHide) {
    return null
  }

  const secretRequests = initialData.args.requests.filter(req => req.type === 'secret')
  const otherRequests = initialData.args.requests.filter(req => req.type !== 'secret')

  return (
    <div className={cn('rounded-lg bg-white', className)}>
      <div className="flex items-center justify-start gap-4 p-1">
        <div className="flex size-10 items-center justify-center rounded-md bg-orange-50 outline outline-1 -outline-offset-1 outline-orange-100">
          <AlarmClock className="size-4 text-orange-500" />
        </div>
        <div className="text-sm font-medium leading-tight text-base-foreground">
          We have some questions. Answer them to continue
        </div>
      </div>

      <div className="p-5">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            {secretRequests.length > 0 && (
              <div className="space-y-4">
                {otherRequests.length > 0 && (
                  <div className="flex items-center gap-4">
                    <div className="font-mono text-sm font-medium uppercase leading-none text-stone-900">
                      CREDENTIALS
                    </div>
                    <Separator className="flex-1" />
                  </div>
                )}
                <div className="grid grid-cols-2 gap-4">
                  {secretRequests.map((request, index) => (
                    <FormField
                      key={index}
                      control={form.control}
                      name={`args.requests.${index}.user_value`}
                      render={({ field }) => (
                        <FormItem className="contents">
                          <FormLabel className="line-clamp-2 self-center text-sm font-normal text-gray-900">
                            {request.request}
                          </FormLabel>
                          <div className="flex flex-col justify-center">
                            <FormControl>
                              <div className="relative">
                                <Input
                                  {...field}
                                  type={!showPasswords[index] ? 'password' : 'text'}
                                  placeholder={request.user_value}
                                />
                                <Button
                                  type="button"
                                  variant="ghost"
                                  size="icon"
                                  className="absolute right-2 top-1/2 -translate-y-1/2"
                                  onClick={() => togglePasswordVisibility(index)}
                                >
                                  {showPasswords[index] ? (
                                    <EyeOff className="size-4" />
                                  ) : (
                                    <Eye className="size-4" />
                                  )}
                                  <span className="sr-only">
                                    {showPasswords[index] ? 'Hide password' : 'Show password'}
                                  </span>
                                </Button>
                              </div>
                            </FormControl>
                            <FormMessage />
                          </div>
                        </FormItem>
                      )}
                    />
                  ))}
                </div>
              </div>
            )}

            {otherRequests.length > 0 && (
              <div className="space-y-4">
                {secretRequests.length > 0 && (
                  <div className="flex items-center gap-4">
                    <div className="font-mono text-sm font-medium uppercase leading-none text-stone-900">
                      OTHER FIELDS
                    </div>
                    <Separator className="flex-1" />
                  </div>
                )}
                {otherRequests.map((request, index) => (
                  <FormField
                    key={index}
                    control={form.control}
                    name={`args.requests.${index + secretRequests.length}.user_value`}
                    render={({ field }) => (
                      <FormItem className="">
                        <FormLabel className="text-sm font-normal text-stone-900">
                          {request.request}
                        </FormLabel>
                        <FormControl>
                          <div className="relative">
                            <Input {...field} type="text" placeholder={request.user_value} />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ))}
              </div>
            )}

            <div className="flex gap-4">
              <Button className="w-full" type="submit" disabled={isLoading}>
                <Key />
                {isLoading ? 'Submitting...' : 'Submit'}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  )
}

function sortRequestsWithSecretsFirst(
  a: InputRequestCommand['args']['requests'][number],
  b: InputRequestCommand['args']['requests'][number]
) {
  return a.type === 'secret' ? -1 : b.type === 'secret' ? 1 : 0
}
