import { ELEMENT_STATUSES, ElementStatus } from '@/const/const.ts'
import { cn } from '@/lib/utils.ts'
import { cva } from 'class-variance-authority'
import { AlarmClock, Ban, CheckCheck, CircleDashed, XOctagon } from 'lucide-react'
import ALogoSpinner from '@/components/atoms/ALogoSpinner.tsx'

export type IconStatus = ElementStatus | typeof ROLE_REGISTRY_STATUS

export function MStatusIconBox({ status }: { status: IconStatus }) {
  const IconComponent = IconComponents[status]
  if (!IconComponent) {
    console.warning('No IconComponent found for status: ', status)
    return
  }

  return (
    <div className={cn(statusVariants({ status }))}>
      <IconComponent className={cn('size-4')} />
    </div>
  )
}

export const ROLE_REGISTRY_STATUS = 'ROLE_REGISTRY' as const

const statusVariants = cva('grid size-9 place-items-center rounded-md border', {
  variants: {
    status: {
      [ROLE_REGISTRY_STATUS]: 'bg-orange-50 text-orange-500 border-orange-100',
      [ELEMENT_STATUSES.DRAFT]: 'bg-base-muted text-stone-400 border-border',
      [ELEMENT_STATUSES.AWAITING]: 'bg-base-muted text-stone-400 border-border',
      [ELEMENT_STATUSES.RUNNING]: 'bg-base-accent border-border text-stone-400',
      [ELEMENT_STATUSES.DONE]: 'bg-[#22C55E14] text-green-500 border-[#22C55E14]',
      [ELEMENT_STATUSES.FAILED]: 'bg-red-50 text-red-500 border-red-100',
      [ELEMENT_STATUSES.CANCELED]: 'bg-base-muted text-stone-400 border-border',
    },
  },
})

const IconComponents: Record<
  ElementStatus | IconStatus,
  React.ComponentType<{ className?: string }>
> = {
  [ROLE_REGISTRY_STATUS]: AlarmClock,
  [ELEMENT_STATUSES.DRAFT]: CircleDashed,
  [ELEMENT_STATUSES.AWAITING]: CircleDashed,
  [ELEMENT_STATUSES.RUNNING]: ALogoSpinner,
  [ELEMENT_STATUSES.DONE]: CheckCheck,
  [ELEMENT_STATUSES.FAILED]: XOctagon,
  [ELEMENT_STATUSES.CANCELED]: Ban,
}
