import ReactMarkdown from 'react-markdown'
import rehypeSanitize from 'rehype-sanitize'
import React, { useEffect, useMemo, useState } from 'react'

import pako from 'pako'
import { cn } from '@/lib/utils.ts'
import { useQuery } from '@tanstack/react-query'

const defaultComponents = {
  h1: ({ node, ...props }: MarkdownNodeProps) => (
    <h1 className="mb-0 mt-6 text-lg font-medium text-base-foreground first:mt-0" {...props} />
  ),
  h2: ({ node, ...props }: MarkdownNodeProps) => (
    <h2 className="mb-0 mt-6 text-base font-medium text-base-foreground" {...props} />
  ),
  h3: ({ node, ...props }: MarkdownNodeProps) => (
    <h3 className="mb-0 mt-4 text-sm font-medium text-base-foreground" {...props} />
  ),
  ul: ({ node, ...props }: MarkdownNodeProps) => (
    <ul
      className="mb-0 list-disc pl-5 text-base-foreground marker:text-base-foreground"
      {...props}
    />
  ),
  ol: ({ node, ...props }: MarkdownNodeProps) => (
    <ol className="mb-0 mt-4 pl-5 text-base-foreground marker:text-base-foreground" {...props} />
  ),
  li: ({ node, ...props }: MarkdownNodeProps) => (
    <li className="mb-0 mt-1 text-sm font-normal leading-tight text-base-foreground" {...props} />
  ),
  p: ({ node, ...props }: MarkdownNodeProps) => (
    <p className="mb-0 mt-2 text-sm font-normal text-base-foreground" {...props} />
  ),
  pre: ({ node, ...props }: MarkdownNodeProps) => (
    <pre
      className="bg-base-accent overflow-x-auto whitespace-pre-wrap border border-border p-2.5 font-dm-mono text-sm font-normal leading-tight text-base-foreground"
      {...props}
    />
  ),
  strong: ({ node, ...props }: MarkdownNodeProps) => <strong className="font-medium" {...props} />,
  code: CodeComponent,
}

export const MarkdownContent = ({
  detailsContent,
  components: customComponents = {},
}: {
  detailsContent?: string | null
  components?: Partial<typeof defaultComponents>
}) => {
  // Default components

  // Merge default and custom components
  const mergedComponents = useMemo(
    () => ({ ...defaultComponents, ...customComponents }),
    [customComponents]
  )

  const contentWithEscapedNewLines = useMemo(() => {
    if (typeof detailsContent !== 'string') {
      return null
    }
    return detailsContent.replace(/\\n/g, '\n')
  }, [detailsContent])

  if (typeof detailsContent !== 'string') {
    console.error('detailsContent is not a string', detailsContent)
    return null
  }

  return (
    <div className="prose w-full max-w-full">
      <ReactMarkdown
        className="tracking-tight [&_pre]:!overflow-x-auto [&_pre]:whitespace-pre-wrap"
        rehypePlugins={[rehypeSanitize]}
        components={mergedComponents}
      >
        {contentWithEscapedNewLines}
      </ReactMarkdown>
    </div>
  )
}

export function CodeComponent({ node, className, ...props }: MarkdownNodeProps) {
  if ((className ?? '').includes('language-mermaid')) {
    return <MermaidChart>{props.children}</MermaidChart>
  }

  return (
    <code
      className={cn(
        'bg-base-accent overflow-x-auto whitespace-pre-wrap font-dm-mono text-sm font-normal leading-tight text-secondary-foreground before:hidden after:hidden',
        isInline(props.children) && 'm-0 self-baseline px-1 py-0.5 align-text-bottom leading-none'
      )}
      {...props}
    />
  )
}

function MermaidChart({ children }: MarkdownNodeProps) {
  // const code = useGetUrlFromContent(children ?? null)
  // if (!code) return null // should not happen really, but we never know with LLMs
  const query = useMermaidChart(typeof children !== 'string' ? null : children)

  if (!query.data) return null

  return (
    <div
      dangerouslySetInnerHTML={{ __html: query.data }}
      className="*:mx-auto *:w-auto *:max-w-full"
    />
  )
}

// detect if code block is inline or not by checking if it contains a newline.
// i.e. difference between `console.info('hello')` and ```console.info('hello\nworld')```
function isInline(node: React.ReactNode) {
  return (typeof node === 'string' && !node.includes('\n')) || typeof node === 'number'
}

// get arbitrary text and convert it to gzipped url-safe base64 string
function useGetUrlFromContent(content: React.ReactNode | null) {
  const [base64, setBase64] = useState<string | null>(null)
  useEffect(() => {
    if (!content || typeof content !== 'string') return
    const buffer = pako.deflate(content)
    bufferToBase64(buffer).then(setBase64)
  }, [content])
  return base64
}

async function bufferToBase64(buffer: Uint8Array) {
  const base64url = await new Promise<string>(resolve => {
    const reader = new FileReader()
    reader.onload = () => resolve((reader.result as string) || '')
    reader.readAsDataURL(new Blob([buffer]))
  })
  // Convert base64 to base64url by replacing characters
  const base64urlSafe = base64url.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
  // remove the `data:...;base64,` part from the start
  return base64urlSafe.slice(base64url.indexOf(',') + 1)
}

type MarkdownNodeProps = {
  node?: unknown
  className?: string
  children?: React.ReactNode
}

function useMermaidChart(content: string | null) {
  const query = useQuery({
    queryKey: ['mermaid-chart', mermaidStyles + content],
    enabled: Boolean(content),
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    retry: false,
    queryFn: async () => {
      const res = await fetch(`https://kroki.io/mermaid`, {
        method: 'POST',
        body: mermaidStyles + content,
        headers: {
          'Content-Type': 'text/plain',
          Accept: 'image/svg+xml',
        },
      })
      if (!res.ok) {
        console.warn(`Could not render mermaid chart:\n${content}`)
        throw new Error('Failed to fetch mermaid chart')
      }
      return res.text()
    },
  })

  return query
}

const mermaidStyles = `---
config:
  theme: base
  themeVariables:
    background: '#F5F5F4'
    fontSize: 14px
    primaryColor: '#E7E5E4'
    primaryBorderColor: '#E7E5E4'
    primaryTextColor: '#1C1917'
    lineColor: '#E7E5E4'
    edgeLabelBackground: '#F5F5F4'
  look: classic
---
`
