import { cn } from '@/lib/utils.ts'
import { CheckCheck, OctagonX } from 'lucide-react'
import { ElementType } from 'react'

export default function PromptBubble({
  children,
  className,
  icon,
  status = 'success',
}: {
  children: React.ReactNode
  className?: string
  icon?: ElementType
  status?: 'success' | 'failed'
}) {
  const Icon = icon ?? (status === 'failed' ? OctagonX : CheckCheck)
  return (
    <div
      className={cn(
        'ml-auto flex w-auto max-w-[90%] items-start gap-3 rounded-xl bg-base-accent px-4 py-3 tracking-tighter',
        'opacity-100 transition-[opacity,transform] starting:translate-y-4 starting:opacity-0',
        status === 'failed' && 'bg-red-50',
        className
      )}
    >
      <Icon className={cn('mt-0.5 size-4', status === 'failed' && 'text-red-500')} />
      <p className="whitespace-pre-line text-sm text-base-foreground">{children}</p>
    </div>
  )
}
