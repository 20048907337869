import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useSearchParams } from 'react-router-dom'

import OIterationDetails from '@/components/organisms/project-details/OIterationDetails/OIterationDetails.tsx'
import OIterationDetailsV2 from '@/components/organisms/project-details/OIterationDetails/OIterationDetailsV2.tsx'

import useStore from '@/stores/useStore'
import IterationHeader from '@/components/molecules/project-details/IterationHeader.tsx'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid/index.js'
import classNames from 'classnames'
import { Badge } from '@/components/catalyst/badge.jsx'
import { DateTime } from 'luxon'
import { FaceFrownIcon } from '@heroicons/react/24/outline/index.js'
import { doesIterationHaveActions, getIterationComputedStatus } from '@/lib/iteration-utils.ts'
import { InteractiveStateContextProvider } from '@/components/molecules/iteration-details/InteractiveStateContext.tsx'
import { ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable.tsx'
import { PanelResizeHandle } from 'react-resizable-panels'
import Logo from '@/assets/svg-components/Logo.jsx'
import { ArrowLeft } from 'lucide-react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { ActiveRoleLoadingProvider } from '@/lib/iterations/ActiveRoleLoadingContext.tsx'
import ALogoSpinner from '@/components/atoms/ALogoSpinner.tsx'
import { ScrolledArea } from './AScrolledArea.tsx'
import { ORightPanel } from './ORightPanel.tsx'
import { ATabHeader } from './ATabHeader.tsx'
import { useIterationQuery } from '@/lib/iterations/useIterationQuery.ts'

export default function OIterationsListAndDetails({
  organization,
  team,
  project,
  iterations,
  iterationsLoading,
  lastTick,
}) {
  const [searchParams, setSearchParams] = useSearchParams()

  // needed by the iteration picker
  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState('')

  const setActiveIterationStatus = useStore(state => state.setActiveIterationStatus)

  const iterationId = useMemo(() => {
    return searchParams.get('iteration')
  }, [searchParams])

  const [activeIterationId, setActiveIterationId] = useState(iterationId)

  // Set active iteration id from URL
  useEffect(() => {
    setActiveIterationId(iterationId)
  }, [iterationId])

  const filteredIterations = useMemo(() => {
    return iterations?.filter(iteration => {
      return (
        iteration?.id?.toLowerCase().includes(query.toLowerCase()) ||
        iteration?.prompt?.toLowerCase().includes(query.toLowerCase()) ||
        iteration?.createdBy?.toLowerCase().includes(query.toLowerCase())
      )
    })
  }, [query, iterations])

  const iterationsGroupedByDate = useMemo(() => {
    const grouped = filteredIterations?.reduce((acc, iteration) => {
      const date = DateTime.fromJSDate(iteration?.createdAt?.toDate()).toISODate()
      acc[date] = acc[date] || []
      acc[date].push(iteration)
      return acc
    }, {})

    return Object.fromEntries(Object.entries(grouped).sort(([a], [b]) => b.localeCompare(a)))
  }, [filteredIterations])

  useEffect(() => {
    const down = e => {
      if (e.key === 'j' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setOpen(open => !open)
      }
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  const activeIterationMeta = useMemo(() => {
    if (!activeIterationId || !iterations) {
      return null
    }
    return iterations.find(iteration => iteration.id === activeIterationId)
  }, [activeIterationId, iterations])

  const handleActiveIterationSelected = useCallback(
    selectedIterationId => {
      setSearchParams({ iteration: selectedIterationId }, { replace: true })
      setActiveIterationId(selectedIterationId)
      setOpen(false)
    },
    [setSearchParams]
  )

  useEffect(() => {
    if (!activeIterationId && iterations?.length > 0) {
      handleActiveIterationSelected(iterations?.[0]?.id)
    }
  }, [iterations, activeIterationId, handleActiveIterationSelected])

  useEffect(() => {
    const computedStatus = getIterationComputedStatus(
      activeIterationMeta?.status,
      activeIterationMeta?.vmStatus
    )
    setActiveIterationStatus(computedStatus)

    return () => setActiveIterationStatus(null)
  }, [activeIterationMeta, setActiveIterationStatus])

  const isActiveIterationLatestIteration = useMemo(() => {
    if (iterations?.length > 0) {
      return activeIterationId === iterations?.[0]?.id
    }
    return false
  }, [iterations, activeIterationId])

  const handleIterationCreated = useCallback(
    data => {
      handleActiveIterationSelected(data.iterationId)
    },
    [handleActiveIterationSelected]
  )

  const getUrlFromText = useCallback(text => {
    if (!text) {
      return []
    }
    const urlRegex = /(https?:\/\/[^\s]+)/g
    const urls = text.match(urlRegex)
    return urls
  }, [])

  const isSplitPanel = useStore(state => state.isSplitPanel)

  const { hasActions, ...iterationActions } = useIterationHasActions(activeIterationMeta?.id)

  if (!project || iterationActions.isLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white/40">
        <ALogoSpinner className="size-8" />
      </div>
    )
  }

  if (isSplitPanel) {
    return (
      <InteractiveStateContextProvider iterationId={activeIterationId}>
        <ResizablePanelGroup direction="horizontal">
          <ElementPanel projectName={project.name || 'Draft project'}>
            {activeIterationMeta && (
              <ActiveRoleLoadingProvider iterationId={activeIterationMeta.id}>
                <OIterationDetailsV2
                  iterationMeta={activeIterationMeta}
                  isNewestIteration={isActiveIterationLatestIteration}
                  lastTick={lastTick}
                  onIterationCreated={handleIterationCreated}
                />
              </ActiveRoleLoadingProvider>
            )}
          </ElementPanel>
          <PanelResizeHandle className="w-px border-l border-border" />
          {activeIterationMeta && hasActions && (
            <ORightPanel iterationId={activeIterationMeta.id} lastTick={lastTick} />
          )}
        </ResizablePanelGroup>
      </InteractiveStateContextProvider>
    )
  }

  return (
    <InteractiveStateContextProvider iterationId={activeIterationId}>
      <div className="w-[800px] py-8">
        <IterationHeader
          project={project}
          team={team}
          organization={organization}
          iterations={iterations}
          isLoading={iterationsLoading}
          activeIterationId={activeIterationId}
          onActiveSelected={handleActiveIterationSelected}
          onIterationCreated={handleIterationCreated}
          prompt={activeIterationMeta?.prompt}
        />
        {!!activeIterationMeta && (
          <OIterationDetails
            iterationMeta={activeIterationMeta}
            isNewestIteration={isActiveIterationLatestIteration}
            lastTick={lastTick}
            onIterationCreated={handleIterationCreated}
          />
        )}
        <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="mx-auto max-w-2xl transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                  <Combobox
                    onChange={item => {
                      handleActiveIterationSelected(item.id)
                    }}
                  >
                    <div className="relative">
                      <MagnifyingGlassIcon
                        className="pointer-events-none absolute left-4 top-3.5 size-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <Combobox.Input
                        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                        placeholder="Search by iteration id, creator id or part of prompt..."
                        onChange={event => setQuery(event.target.value)}
                        autoFocus
                      />
                    </div>

                    {filteredIterations.length > 0 && (
                      <Combobox.Options
                        static
                        className="max-h-[720px] scroll-pb-2 scroll-pt-11 space-y-2 overflow-y-auto pb-2"
                      >
                        {Object.entries(iterationsGroupedByDate).map(([category, items]) => (
                          <li key={category}>
                            <h2 className="bg-gray-100 px-4 py-2 font-mono text-xs font-semibold text-gray-900">
                              {category}
                            </h2>
                            <ul className="text-sm text-gray-800">
                              {items.map(item => (
                                <Combobox.Option
                                  key={item.id}
                                  value={item}
                                  className={({ active }) =>
                                    classNames(
                                      'cursor-default select-none border-b border-zinc-200 px-4 text-base',
                                      active && 'bg-zinc-100',
                                      activeIterationId === item.id && 'bg-zinc-800  text-zinc-50'
                                    )
                                  }
                                >
                                  <div className="flex flex-col px-4 py-6">
                                    <div className="flex items-start justify-between">
                                      <div className="flex flex-col ">
                                        <span className="font-mono text-lg font-semibold tracking-tight">
                                          {item.id}
                                        </span>
                                        <span className="mt-2 font-mono text-sm text-gray-600">
                                          {getUrlFromText(item.prompt)?.map((url, index) => (
                                            <span
                                              key={index}
                                              className={classNames(
                                                activeIterationId === item.id
                                                  ? 'text-zinc-50'
                                                  : 'text-blue-500'
                                              )}
                                            >
                                              {url}
                                            </span>
                                          ))}
                                        </span>
                                      </div>
                                      <div className="flex items-center justify-center">
                                        <Badge
                                          color={activeIterationId === item.id ? 'white' : 'zinc'}
                                        >
                                          {item.status}
                                        </Badge>
                                      </div>
                                    </div>
                                    <table
                                      className={classNames(
                                        'mt-4 font-mono text-xs',
                                        activeIterationId === item.id
                                          ? 'text-zinc-50'
                                          : 'text-zinc-700'
                                      )}
                                    >
                                      <tr className="h-6">
                                        <td className="w-28">Created by </td>{' '}
                                        <td className="font-bold">{item.createdBy || 'N/A'}</td>
                                      </tr>
                                      <tr className="h-6">
                                        <td className="w-28">Created on </td>
                                        <td className="font-bold">
                                          {DateTime.fromJSDate(item.createdAt.toDate()).toFormat(
                                            'LLL d, yyyy h:mm a'
                                          )}
                                        </td>
                                      </tr>
                                      <tr className="h-6">
                                        <td className="w-28">Last activity </td>
                                        <td className="font-bold">
                                          {DateTime.fromJSDate(item.updatedAt.toDate()).toFormat(
                                            'LLL d, yyyy h:mm a'
                                          )}
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </Combobox.Option>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </Combobox.Options>
                    )}

                    {query !== '' && filteredIterations.length === 0 && (
                      <div className="border-t border-gray-100 px-6 py-14 text-center text-sm sm:px-14">
                        <FaceFrownIcon
                          className="mx-auto size-6 text-gray-400"
                          aria-hidden="true"
                        />
                        <p className="mt-4 font-semibold text-gray-900">No iterations found</p>
                        <p className="mt-2 text-gray-500">
                          Search tries to match the iteration ID within this project. Try searching
                          for a different ID.
                        </p>
                      </div>
                    )}
                  </Combobox>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </InteractiveStateContextProvider>
  )
}

OIterationsListAndDetails.propTypes = {
  iterations: PropTypes.array,
  iterationsLoading: PropTypes.bool,
  lastTick: PropTypes.number,
}

function ElementPanel({
  children,
  projectName,
}: {
  children: React.ReactNode
  projectName: string
}) {
  return (
    <ResizablePanel defaultSize={50} minSize={35} className="mx-auto">
      <ScrolledArea
        className="flex flex-col"
        stickyElement={
          <div className="sticky top-0 z-10">
            <ATabHeader className="p-4">
              <Link to="/projects" className="group relative">
                <Logo className="size-6 transition-opacity delay-75 group-hover:opacity-0 group-hover:delay-0" />
                <ArrowLeft className="absolute inset-0 size-6 opacity-0 transition-opacity delay-100 group-hover:opacity-100 group-hover:delay-75" />
              </Link>
              <h1 className="text-sm font-medium">{projectName}</h1>
            </ATabHeader>
          </div>
        }
      >
        <div className="mx-auto flex min-h-full max-w-screen-md grow flex-col p-4 pb-0">
          {children}
        </div>
      </ScrolledArea>
    </ResizablePanel>
  )
}

// we can't rely on ResizeObserver, because container has an overflow, i.e. it's scrollable.
// this means we have to rely on the mutation observer to detect if something
// happened, and then check for the height
function useSizeObserver(ref: HTMLElement | null) {
  const [height, setHeight] = useState(() => ref?.scrollHeight ?? 0)
  useEffect(() => {
    setHeight(ref?.scrollHeight ?? 0)
  }, [ref])

  const mutationObserver = useMemo(
    () =>
      new MutationObserver(mutationList => {
        if (!ref) {
          return
        }
        setHeight(ref.scrollHeight)
      }),
    [setHeight, ref]
  )

  useEffect(() => {
    if (ref) {
      mutationObserver.observe(ref, { attributes: false, childList: true, subtree: true })
    }

    return () => mutationObserver.disconnect()
  }, [ref, mutationObserver])

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver(([e]) => {
        if (!ref) {
          return
        }
        setHeight(ref.scrollHeight)
      }),
    [setHeight, ref]
  )

  useEffect(() => {
    if (ref) {
      resizeObserver.observe(ref)
    }

    return () => resizeObserver.disconnect()
  }, [ref, resizeObserver])

  return height
}

function useIterationHasActions(iterationId: string) {
  const iterationQuery = useIterationQuery(iterationId)
  const iteration = iterationQuery.data

  const hasActions = useMemo(() => {
    return doesIterationHaveActions(iteration)
  }, [iteration])

  return { hasActions, isLoading: iterationQuery.isLoading }
}
