import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { Timestamp } from 'firebase/firestore'

import { useToast } from '@/components/ui/use-toast.js'
import {
  Check,
  Code,
  Columns2,
  ExternalLink,
  Loader,
  OctagonPause,
  OctagonX,
  SquareArrowUpRight,
  Terminal,
  TriangleAlert,
  UserSearch,
} from 'lucide-react'

import OiterationDetailsSkeleton from '@/components/organisms/project-details/OiterationDetailsSkeleton.jsx'
import { ANALYTIC_EVENTS, analyticsTrackEvent } from '@/services/Analytics.js'
import {
  CONTINUATION_CHAT_ROLES,
  ELEMENT_STATUSES,
  ITERATION_COMMANDS,
  ITERATION_ROLES,
  ITERATION_ROLES_LIST,
  ITERATION_STATUSES,
  IterationStatus,
} from '@/const/const'
import useStore, { useIsUserSuperAdmin } from '@/stores/useStore.js'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { clsx } from 'clsx'
import { useIterationQuery } from '@/lib/iterations/useIterationQuery.ts'
import { useIterationActiveRole } from '@/lib/iterations/useIterationActiveRole.ts'
import {
  areAnyUserCommandsPending,
  doesIterationHaveActions,
  generateGCPLoggingLink,
  getGrafanaUrl,
  toFirestoreConsoleLink,
} from '@/lib/iteration-utils.ts'
import { Link } from '@/components/catalyst/link'
import { useAggregatedRoles } from '@/lib/iterations/useAggregatedRoleRegistry.ts'
import { cn } from '@/lib/utils.ts'
import {
  isInputRequestCommand,
  Iteration,
  IterationElement,
  UserCommand,
} from '@/services/firebase_shared_types.ts'
import { setInteractiveMode } from '@/services/Firebase.ts'
import { useMutation } from '@tanstack/react-query'
import { MElement } from '@/components/molecules/iteration-details/MElement.tsx'
import { MStep } from '@/components/molecules/iteration-details/MStep.tsx'
import { UserInputForm } from '@/components/organisms/project-details/OIterationDetails/UserInputForm.tsx'
import MContinuationChatV2, { ProposedSpecifications } from './MContinuationChatV2.tsx'
import { MarkdownContent } from '@/components/actions/components/MarkdownContent.tsx'
import { RoleHistoryEntry, useRoleHistoryQuery } from '@/lib/iterations/useIterationRoleHistory.ts'
import PromptBubble from './MPromptBubble.tsx'
import IterationStartupSequence from './OIterationStartupSequence.tsx'
import {
  PromptHistoryEntry,
  useFirstIterationPromptQuery,
  useIterationPromptsQuery,
} from '@/lib/iterations/useIterationPromptsQuery.ts'
import { useUserCommandsQuery } from '@/lib/iterations/userUserCommandsQuery.ts'
import { useActiveRoleLoading } from '@/lib/iterations/ActiveRoleLoadingContext.tsx'
import { AnalystStartupSequence } from '../Analyst/AnalystStartupSequence.tsx'
import { useIsUpdatingSpecifications } from '@/lib/iterations/analyst/useIsUpdatingSpecifications.ts'
import { CurrentSpecifications } from '../Analyst/CurrentSpefications.tsx'
import { PastSpecifications } from '../Analyst/PastSpecifications.tsx'
import { LastUpdatePromptBubble } from '../Analyst/LastUpdatePromptBubble.tsx'
import MIterationStatusPill from '@/components/organisms/project-details/OIterationDetails/MIterationStatusPill.tsx'
import MRunningChat from '@/components/organisms/project-details/OIterationDetails/MRunningChat.tsx'
import { SuggestionPromptBubble } from '@/components/organisms/project-details/OIterationDetails/SuggestionPromptBubble.tsx'
import { PauseIterationButton } from '@/components/organisms/project-details/OIterationDetails/PauseIterationButton.tsx'
import { ResumeIterationButton } from './ResumeIterationButton'
import { CancelIterationButton } from '@/components/organisms/project-details/OIterationDetails/CancelIterationButton.tsx'
import { useInteractiveStateContext } from '@/components/molecules/iteration-details/InteractiveStateContext'
import MRollbackIteration from '@/components/organisms/project-details/OIterationDetails/MRollbackIterationV2.tsx'
import MRetryPrompt from '@/components/organisms/project-details/OIterationDetails/MRetryPrompt.tsx'
import { MElementSkeleton } from '@/components/molecules/iteration-details/MElementSkeleton.tsx'

type IterationMeta = {
  id: string
  projectId: Iteration['projectId']
  awaitingCommand: Iteration['awaitingCommand']
  teamId: Iteration['teamId']
  organizationId: Iteration['organizationId']
  status: Iteration['status']
  vmStatus: Iteration['vmStatus']
  isInteractiveMode: Iteration['isInteractiveMode']
  createdAt: Iteration['createdAt']
}

export default function OIterationDetailsV2({
  iterationMeta,
  lastTick = Date.now(),
}: {
  iterationMeta: IterationMeta
  lastTick: number
}) {
  const [lastIterationMeta, setLastIterationMeta] = useState(iterationMeta)
  const { isSplitPanel, setIsSplitPanel } = useStore(({ isSplitPanel, setIsSplitPanel }) => ({
    isSplitPanel,
    setIsSplitPanel,
  }))
  const iterationQuery = useIterationQuery(iterationMeta?.id)
  const { isLoading: isIterationLoading, data: iteration } = iterationQuery
  const [rolesDebugEnabled, setRolesDebugEnabled] = useState(false)

  const originalPrompt = useFirstIterationPromptQuery(iterationMeta?.id)
  const iterationPromptsQuery = useIterationPromptsQuery(iterationMeta?.id)
  const businesAnalystHistoryQuery = useRoleHistoryQuery(iterationMeta?.id, 'businessanalyst')
  const technicalAnalystHistoryQuery = useRoleHistoryQuery(iterationMeta?.id, 'technicalanalyst')
  const userCommandsQuery = useUserCommandsQuery(iterationMeta?.id)

  const isPromptInvalid = useMemo(() => {
    if (iteration?.prompt_validation) {
      return iteration?.prompt_validation.has_any_violation
    } else {
      return false
    }
  }, [iteration?.prompt_validation?.has_any_violation])

  const hasActions = useMemo(() => {
    return doesIterationHaveActions(iteration)
  }, [iteration])

  const analystLoadingState = useActiveRoleLoading()
  const continuationIterationRole = useIterationActiveRole(
    iterationMeta?.id,
    CONTINUATION_CHAT_ROLES
  )

  const [filterOutLLMActions, setFilterOutLLMActions] = useState(true)

  const isSuperAdmin = useIsUserSuperAdmin()
  const { toast } = useToast()

  const throttledRefetchIteration = useMemo(
    () =>
      _.throttle(() => {
        iterationQuery.refetch()
      }, 500),
    [iterationQuery.refetch]
  )

  useEffect(() => {
    if (!_.isEqual(iterationMeta, lastIterationMeta)) {
      setLastIterationMeta(iterationMeta)
      throttledRefetchIteration()
    }
  }, [iterationMeta, lastIterationMeta, iterationQuery])

  useEffect(
    () => {
      if (iterationQuery.status === 'success') {
        analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_OPEN, {
          iterationId: lastIterationMeta?.id,
          projectId: lastIterationMeta?.projectId || 'N/A',
          teamId: lastIterationMeta?.teamId || 'N/A',
          organizationId: lastIterationMeta?.organizationId || 'N/A',
          iterationStatus: lastIterationMeta.status,
          vmStatus: lastIterationMeta.vmStatus || 'N/A',
        })
      }
      if (iterationQuery.status === 'error') {
        analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_OPEN_ERROR, {
          iterationId: lastIterationMeta?.id,
          projectId: lastIterationMeta?.projectId || 'N/A',
          teamId: lastIterationMeta?.teamId || 'N/A',
          organizationId: lastIterationMeta?.organizationId || 'N/A',
          iterationStatus: lastIterationMeta.status,
          vmStatus: lastIterationMeta.vmStatus || 'N/A',
          error: iterationQuery.error.message,
        })
        toast({
          variant: 'destructive',
          title: 'Error loading iteration details 😔',
          description: 'Try refreshing the page or contact Proofs team.',
        })
      }
    },
    // run only when iterationQuery.status changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [iterationQuery.status, toast]
  )

  const elements = useElements(iteration ?? null)

  const groupedElements = useGroupedElements(iterationMeta?.id, iteration ?? null, lastTick)

  const logsUrl = useMemo(() => {
    if (iteration == null) {
      return generateGCPLoggingLink({})
    }

    const filters = {
      'jsonPayload.organization_id': iteration?.organizationId,
      'jsonPayload.project_id': iteration?.projectId,
      'jsonPayload.iteration_id': iterationMeta?.id,
    }
    return generateGCPLoggingLink(filters, iterationMeta?.createdAt)
  }, [iteration])

  const grafanaUrl = useMemo(() => {
    return getGrafanaUrl({
      iterationId: iterationMeta?.id,
      organizationId: iteration?.organizationId,
      fromTimestamp: iterationMeta?.createdAt,
    })
  }, [iteration, iterationMeta])

  const aggregatedRoles = useAggregatedRoles(iterationMeta?.id)

  const isInteractive = iterationMeta?.isInteractiveMode ?? false

  const isUsingAnalyst = iteration?.usesTechnicalAnalyst || iteration?.usesBusinessAnalyst
  const isFinished =
    iterationQuery.isSuccess &&
    ([ITERATION_STATUSES.DONE, ITERATION_STATUSES.FAILED] as IterationStatus[]).includes(
      iterationQuery.data.status
    )
  const shouldShowContinuationChat =
    !isPromptInvalid &&
    iteration?.prompt_validation &&
    !areAnyUserCommandsPending(userCommandsQuery.data, ITERATION_COMMANDS.RESTART) &&
    (isFinished || (analystLoadingState.isActive && !analystLoadingState.isInitializing))
  const shouldShowRunningInput =
    iterationQuery.isSuccess &&
    hasActions &&
    (
      [
        ITERATION_STATUSES.RUNNING,
        ITERATION_STATUSES.PAUSING,
        ITERATION_STATUSES.PAUSED,
      ] as IterationStatus[]
    ).includes(iterationQuery.data.status)

  const setInteractiveModeMutation = useMutation({
    mutationKey: ['setInteractiveMode', iterationMeta?.id],
    mutationFn: setInteractiveMode,
  })

  const enableRoles = async () => {
    setInteractiveModeMutation.mutate({
      iterationId: iterationMeta?.id,
      roles: ITERATION_ROLES_LIST.map(role => ({ name: role, interactive: true })),
    })
  }

  const disableRoles = async () => {
    setInteractiveModeMutation.mutate({
      iterationId: iterationMeta?.id,
      roles: ITERATION_ROLES_LIST.map(role => ({ name: role, interactive: false })),
    })
  }

  const highLevelPlanner = iteration?.role_registry?.[ITERATION_ROLES.HIGH_LEVEL_PLANNER]

  // element structure has to be more flexible. We need to be able to show something resembling a timeline.

  const prompts = useMemo(() => {
    if (!iterationPromptsQuery.isSuccess) {
      return []
    }
    return iterationPromptsQuery.data
  }, [iterationPromptsQuery.data, iterationPromptsQuery.isSuccess])

  const historyEntries = useMemo((): HistoryEntry[] => {
    if (!iteration) {
      return []
    }
    if (!businesAnalystHistoryQuery.isSuccess) {
      return []
    }
    if (!technicalAnalystHistoryQuery.isSuccess) {
      return []
    }
    if (!userCommandsQuery.data) {
      return []
    }
    const analystHistory = businesAnalystHistoryQuery.data ?? []
    const technicalAnalystHistory = technicalAnalystHistoryQuery.data ?? []
    const sequence = [
      ...analystHistory.map(entry => toSequenceElement(entry, 'analyst')),
      ...technicalAnalystHistory.map(entry => toSequenceElement(entry, 'analyst')),
      ...prompts.map(entry => toSequenceElement(entry, 'prompt')),
      ...groupedElements
        .filter(group => group.elements.length > 0)
        .map(group => toSequenceElement(group, 'element_group')),
      ...userCommandsQuery.data
        .filter(command => command.command === ITERATION_COMMANDS.ROLE_MESSAGE)
        .map(entry => toSequenceElement(entry, 'user_command_analyst')),
      ...userCommandsQuery.data
        .filter(command => command.command === ITERATION_COMMANDS.EXTEND)
        .map(entry => toSequenceElement(entry, 'user_command_extend')),
      ...userCommandsQuery.data
        .filter(command => command.command === ITERATION_COMMANDS.SUGGESTION)
        .map(entry => toSequenceElement(entry, 'user_command_suggestion')),
    ]
    sequence.sort((a, b) => a.createdAt - b.createdAt)

    return sequence
  }, [
    businesAnalystHistoryQuery.data,
    technicalAnalystHistoryQuery.data,
    groupedElements,
    iteration,
    businesAnalystHistoryQuery.isSuccess,
    technicalAnalystHistoryQuery.isSuccess,
    prompts,
    userCommandsQuery.data,
  ])

  // meaning: we already got a new entry in the history entries array, it's just not complete yet.
  const isWorkingOnUpdatedSolution = useIsUpdatingSpecifications(iterationMeta?.id)

  const inputFlow = useUserInputFlow(iterationMeta?.id)

  const isDoingStuff = useMemo(() => {
    return (
      isWorkingOnUpdatedSolution ||
      analystLoadingState.isLoading ||
      elements.some(e => e.status === ELEMENT_STATUSES.RUNNING) ||
      elements.length !== 0 ||
      (iteration?.status === ITERATION_STATUSES.RUNNING &&
        !analystLoadingState.isInitializing &&
        !analystLoadingState.isActive)
    )
  }, [isWorkingOnUpdatedSolution, analystLoadingState.isLoading, elements])

  const { rollbackAction, setRollbackAction } = useInteractiveStateContext()

  if (!iteration || isIterationLoading) {
    return <OiterationDetailsSkeleton />
  }

  function isFinalSpecForNow(index: number) {
    const next = historyEntries[index + 1]
    if (!next) {
      // weird case, but might happen.
      return false
    }
    return next.type === 'user_command_analyst' && next.data.args.content.choice === 'validate'
  }

  function didNotUpdateSpecs(index: number) {
    // we should be good as long as we don't have a new analyst entry in the past 2 entries.
    return !historyEntries
      .slice(Math.max(0, index - 2), index)
      .some(entry => entry.type === 'analyst')
  }

  return (
    <>
      <div className="flex min-h-full grow flex-col gap-6">
        {rolesDebugEnabled && (
          <details className="fixed left-1/2 top-10 z-50 w-1/3 rounded-lg border border-border bg-white p-2 font-dm-mono opacity-95 shadow-lg  open:w-[800px]">
            <summary>Roles</summary>

            <pre className="font-dm-mon max-h-[50vh] overflow-auto text-wrap ">
              <p>Iteration: </p>
              <p>{JSON.stringify(iteration.role_registry, null, 2)}</p>
              <p>{JSON.stringify(aggregatedRoles.iterationRole, null, 2)}</p>
              <p>Element: </p>
              <p>{JSON.stringify(aggregatedRoles.elementRole, null, 2)}</p>
              <p>Step: </p>
              <p>{JSON.stringify(aggregatedRoles.stepRole, null, 2)}</p>
            </pre>
          </details>
        )}
        {originalPrompt && (
          <PromptBubble status={isPromptInvalid ? 'failed' : 'success'} className="my-10">
            {originalPrompt}
          </PromptBubble>
        )}

        {isUsingAnalyst && elements.length === 0 && (
          <p className="flex w-fit gap-3 rounded-xl bg-orange-50 px-4 py-3.5 text-sm text-orange-500">
            <TriangleAlert className="size-4" /> Please wait to accept the project specifications.
            It will take around 1-2 minutes.
          </p>
        )}

        <IterationStartupSequence
          currentStatus={iteration.status}
          currentVmStatus={iteration.vmStatus}
        />

        <AnalystStartupSequence
          role={continuationIterationRole}
          isInitializing={analystLoadingState.isInitializing}
        />

        {historyEntries.map((item, index) => {
          return (
            <>
              {/* in practice, this happens only when user validates specs. */}
              {item.type === 'user_command_analyst' && (
                <ValidateAnalystMessageBubble userCommand={item.data} />
              )}
              {item.type === 'user_command_extend' && (
                <PromptBubble>{item.data.args.prompt}</PromptBubble>
              )}
              {item.type === 'user_command_suggestion' && (
                <SuggestionPromptBubble
                  suggestion={item.data.args.suggestion}
                  status={item.data.status}
                />
              )}

              {/* this happens only when user validates without updating specs. Thanks Alex for this wonderful data model.*/}
              {item.type === 'prompt' &&
                item.data.specifications?.data &&
                didNotUpdateSpecs(index) && (
                  <MarkdownContent
                    key={item.createdAt + 'final-specs'}
                    detailsContent={item.data.specifications.data.markdown}
                  />
                )}

              {/* We show analyst specification history here. Entry is omitted if we have a partial result (no output). See: "fake solution" below.  */}
              {item.type === 'analyst' && !(!item.data.output && isWorkingOnUpdatedSolution) && (
                <>
                  <PastSpecifications data={item.data.existing} key={item.createdAt + 'before'} />
                  <PromptBubble className="transition-none" key={item.createdAt + 'req'}>
                    {item.data.request}
                  </PromptBubble>
                  {isFinalSpecForNow(index) && (
                    <CurrentSpecifications key={item.createdAt + 'after'} data={item.data.output} />
                  )}
                </>
              )}

              {/* We show element groups here.  */}
              {item.type === 'element_group' && (
                <div>
                  <ul key={item.createdAt} className="space-y-4">
                    {item.data.elements.map((element, index) => {
                      const steps = Object.entries(element.steps).sort(
                        (a, b) => a[1].index - b[1].index
                      )
                      return (
                        <li key={element.index}>
                          <MElement key={'element' + (index + 1)} element={element} />
                          <ul className=" mt-1 space-y-1">
                            {steps.length === 0 && element.status === ELEMENT_STATUSES.RUNNING && (
                              <>
                                <li>
                                  <MElementSkeleton />
                                </li>
                                <li>
                                  <MElementSkeleton />
                                </li>
                              </>
                            )}
                            {steps.map(([key, value]) => {
                              return (
                                <li key={key}>
                                  <MStep idx={index + 1} step={value} showDetails={true} />
                                </li>
                              )
                            })}
                          </ul>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
            </>
          )
        })}

        {highLevelPlanner && highLevelPlanner?.status !== 'done' && (
          <ul className="space-y-4">
            <li>
              <MElementSkeleton />
            </li>
            <li>
              <MElementSkeleton />
            </li>
          </ul>
        )}

        {inputFlow.isWaitingForInput && (
          <UserInputForm
            initialData={inputFlow.latestRequestCommand}
            iterationId={iterationMeta?.id}
          />
        )}

        {/* Fake solution. Shotgun is not fast enough to show the specs, so we fake it. */}
        {isWorkingOnUpdatedSolution && (
          <>
            <PastSpecifications data={iteration.specifications ?? ''} forceOpen={true} />
            <LastUpdatePromptBubble iterationId={iterationMeta?.id} />
            <p className="text-center text-sm text-muted-foreground">
              Working on updated specifications...
            </p>
          </>
        )}
        {!isWorkingOnUpdatedSolution &&
          isUsingAnalyst &&
          !analystLoadingState.isInitializing &&
          analystLoadingState.isActive && (
            <ProposedSpecifications iterationId={iterationMeta?.id} />
          )}

        <div className="sticky bottom-0 z-10 mt-auto space-y-2 bg-base-background pb-4 pt-4">
          {areAnyUserCommandsPending(userCommandsQuery.data, ITERATION_COMMANDS.SUGGESTION) && (
            <PendingSuggestionsNotification />
          )}
          <div className="flex items-center justify-between">
            <div>
              {isPromptInvalid ? (
                <MIterationStatusPill status="WAITING_FOR_FEEDBACK" />
              ) : isFinished &&
                !areAnyUserCommandsPending(userCommandsQuery.data, ITERATION_COMMANDS.RESTART) &&
                iteration?.prompt_validation ? (
                <MIterationStatusPill status="DONE" />
              ) : inputFlow.isWaitingForInput ? (
                <MIterationStatusPill status="WAITING_FOR_FEEDBACK" />
              ) : iteration.status === ITERATION_STATUSES.PAUSED ? (
                <MIterationStatusPill status="PAUSED" />
              ) : iteration.status === ITERATION_STATUSES.PAUSING ? (
                <MIterationStatusPill status="PAUSING" />
              ) : analystLoadingState.isActive &&
                !analystLoadingState.isLoading &&
                !analystLoadingState.isInitializing ? (
                <MIterationStatusPill status="WAITING_FOR_FEEDBACK" />
              ) : isDoingStuff ? (
                <MIterationStatusPill status="WORKING" />
              ) : null}
            </div>
            <div className="space-x-2">
              {hasActions &&
                iterationMeta.status === ITERATION_STATUSES.RUNNING &&
                elements.some(e => e.status === ELEMENT_STATUSES.RUNNING) && (
                  <PauseIterationButton iterationId={iterationMeta?.id} />
                )}
              {iterationMeta.status === ITERATION_STATUSES.PAUSED &&
                !inputFlow.isWaitingForInput && (
                  <>
                    {!areAnyUserCommandsPending(
                      userCommandsQuery.data,
                      ITERATION_COMMANDS.CANCEL
                    ) && <ResumeIterationButton iterationId={iterationMeta?.id} />}
                    {!areAnyUserCommandsPending(
                      userCommandsQuery.data,
                      ITERATION_COMMANDS.RESUME
                    ) && <CancelIterationButton iterationId={iterationMeta?.id} />}
                  </>
                )}
            </div>
          </div>
          {rollbackAction && (
            <MRollbackIteration action={rollbackAction} onClose={() => setRollbackAction(null)} />
          )}
          {shouldShowContinuationChat && <MContinuationChatV2 iterationId={iterationMeta?.id} />}
          {shouldShowRunningInput &&
            elements.some(e => e.status === ELEMENT_STATUSES.RUNNING) &&
            !analystLoadingState.isInitializing &&
            !analystLoadingState.isActive &&
            !rollbackAction && <MRunningChat iterationId={iterationMeta?.id} />}
          {isPromptInvalid && (
            <MRetryPrompt
              iterationId={iterationMeta?.id}
              suggestedPrompt={iteration?.prompt_validation?.rewritten_prompt}
            />
          )}
        </div>
      </div>

      {isSuperAdmin && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              size="sm"
              className="fixed bottom-4 left-4 z-10 h-10 w-24 space-x-1.5 rounded-lg p-4 shadow-2xl drop-shadow-md"
            >
              <Code className="size-4" />
              <span>Debug</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="mb-4 w-48" align="end" side="top">
            <DropdownMenuItem
              className={cn(
                'flex cursor-pointer items-center space-x-2',
                isInteractive && 'font-semibold'
              )}
              onSelect={e => {
                e.preventDefault()
                return isInteractive ? disableRoles() : enableRoles()
              }}
            >
              {setInteractiveModeMutation.isPending ? (
                <Loader className="size-4 animate-spin" />
              ) : (
                <OctagonPause
                  className={clsx(
                    'size-4',
                    isInteractive ? 'animate-pulse text-green-500' : 'text-slate-300'
                  )}
                />
              )}
              <span>Interactive Mode</span>
            </DropdownMenuItem>

            <DropdownMenuItem
              className={cn(
                'flex cursor-pointer items-center space-x-2',
                rolesDebugEnabled && 'font-semibold'
              )}
              onSelect={() => setRolesDebugEnabled(prev => !prev)}
            >
              <UserSearch
                className={clsx(
                  'size-4',
                  rolesDebugEnabled ? 'animate-pulse text-green-500' : 'text-slate-300'
                )}
              />
              <span>Roles debug panel</span>
            </DropdownMenuItem>

            <DropdownMenuSeparator />

            <DropdownMenuItem
              className="flex cursor-pointer items-center space-x-2"
              onSelect={e => {
                e.preventDefault()
                setFilterOutLLMActions(prev => !prev)
              }}
            >
              <Check className={clsx('size-4', filterOutLLMActions && 'invisible')} />
              <span>Show LLM Actions</span>
            </DropdownMenuItem>
            <DropdownMenuItem className="">
              <Link href={logsUrl} target={'_blank'} className="flex items-center space-x-2 ">
                <Terminal className="size-4" />
                <span>Logs</span>
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem className="">
              <Link href={grafanaUrl} target={'_blank'} className="flex items-center space-x-2 ">
                <Terminal className="size-4" />
                <span>Grafana Logs</span>
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem className="">
              <Link
                href={toFirestoreConsoleLink('iterations', iterationMeta?.id)}
                target={'_blank'}
                className="flex items-center space-x-2 "
              >
                <ExternalLink className="size-4" />
                <span>Iteration</span>
              </Link>
            </DropdownMenuItem>

            <DropdownMenuSeparator />

            <DropdownMenuItem
              className={cn(
                'flex cursor-pointer items-center space-x-2',
                isSplitPanel && 'font-semibold'
              )}
              onSelect={() => setIsSplitPanel(!isSplitPanel)}
            >
              <Columns2 className={clsx('size-4')} />
              <span>Split view</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  )
}

function ValidateAnalystMessageBubble({ userCommand }: { userCommand: UserCommand }) {
  if (
    userCommand.command !== ITERATION_COMMANDS.ROLE_MESSAGE ||
    (userCommand.args.role !== 'businessanalyst' && userCommand.args.role !== 'technicalanalyst')
  ) {
    console.error('User command is not a role message', userCommand, userCommand.args.role)
    return null
  }
  if (userCommand.args.content.choice !== 'validate') {
    // updates are handled by role history.
    return null
  }
  return <PromptBubble>Accepted!</PromptBubble>
}

OIterationDetailsV2.propTypes = {
  iterationMeta: PropTypes.shape({
    id: PropTypes.string,
    iterationId: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    inputTokens: PropTypes.number,
    outputTokens: PropTypes.number,
    elapsedTime: PropTypes.number,
    prompt: PropTypes.string,
    createdAt: PropTypes.instanceOf(Timestamp),
    updatedAt: PropTypes.instanceOf(Timestamp),
    elements: PropTypes.object,
    projectId: PropTypes.string,
    environmentId: PropTypes.string,
    environment: PropTypes.object,
    repoURI: PropTypes.string,
    awaitingCommand: PropTypes.object,
    teamId: PropTypes.string,
  }),
  lastTick: PropTypes.number,
  onIterationCreate: PropTypes.func,
  isIterationCreating: PropTypes.bool,
  isNewestIteration: PropTypes.bool,
  onIterationCreated: PropTypes.func,
}

// very imperfect, but it's the best we can do right now without doing a massive refactor.
type ElementGroup = { elements: IterationElement[] }
type HistoryEntry =
  | {
      data: ElementGroup
      type: 'element_group'
      createdAt: number
    }
  | { data: RoleHistoryEntry; type: 'analyst'; createdAt: number }
  | { data: PromptHistoryEntry; type: 'prompt'; createdAt: number }
  | { data: UserCommand; type: 'user_command_analyst'; createdAt: number }
  | { data: UserCommand; type: 'user_command_extend'; createdAt: number }
  | { data: UserCommand; type: 'user_command_suggestion'; createdAt: number }

function toSequenceElement(
  data: ElementGroup | RoleHistoryEntry | PromptHistoryEntry,
  entryType:
    | 'element_group'
    | 'analyst'
    | 'prompt'
    | 'user_command_analyst'
    | 'user_command_extend'
    | 'user_command_suggestion'
): HistoryEntry {
  if (entryType === 'element_group') {
    const group = data as ElementGroup
    return {
      data: group,
      type: 'element_group',
      createdAt: group.elements[0].createdAt.valueOf(),
    }
  } else if (entryType === 'prompt') {
    const entry = data as PromptHistoryEntry
    return {
      data: entry,
      type: 'prompt',
      createdAt: entry.createdAt.valueOf(),
    }
  } else if (entryType === 'user_command_analyst') {
    const entry = data as unknown as UserCommand
    return {
      data: entry,
      type: 'user_command_analyst',
      createdAt:
        entry.args?.content?.choice === 'validate'
          ? entry.updatedAt.valueOf() + 1000 // thanks, shotgun
          : entry.createdAt.valueOf(),
    }
  } else if (entryType === 'user_command_extend') {
    const entry = data as UserCommand
    return {
      data: entry,
      type: 'user_command_extend',
      createdAt: entry.createdAt.valueOf(),
    }
  } else if (entryType === 'user_command_suggestion') {
    const entry = data as UserCommand
    return {
      data: entry,
      type: 'user_command_suggestion',
      createdAt: entry.createdAt.valueOf(),
    }
  }
  const entry = data as RoleHistoryEntry
  return {
    data: entry,
    type: 'analyst',
    createdAt: entry.createdAt.valueOf(),
  }
}

const useElementsWithRelativeTime = (iteration: Iteration | null, lastTick: number | null) => {
  const baseElements = useElements(iteration)

  const elementsWithRelativeTime = useMemo(() => {
    if (!lastTick) {
      return []
    }
    // lastTick is used to force a re-render every minute
    return baseElements.map(element => {
      const dateCreated = new Date(element.createdAt._seconds * 1000)
      const dateUpdated = new Date(element.updatedAt._seconds * 1000)
      return {
        ...element,
        updatedAt: dateUpdated,
        createdAt: dateCreated,
        // that should be done at component level tbh
        updatedAtRelative: DateTime.fromJSDate(dateUpdated).toRelative(),
        repoURI: iteration?.repoURI,
      }
    })
  }, [baseElements, iteration?.repoURI, lastTick])

  return elementsWithRelativeTime
}

// we need to group elements by commands, so we can display them in order along with other stuff.
// we're using "EXTEND" commands to detect the groupings.
function useGroupedElements(
  id: string | null,
  iteration: Iteration | null,
  lastTick: number | null
) {
  const elementsWithRelativeTime = useElementsWithRelativeTime(iteration, lastTick)

  const userCommandsQuery = useUserCommandsQuery(id)

  const extendCommands = (userCommandsQuery.data ?? []).filter(
    command => command.command === ITERATION_COMMANDS.EXTEND
  )

  const groupedElements = useMemo(() => {
    const result: { elements: IterationElement[] }[] = []
    if (!iteration) {
      return result
    }

    const firstCommand = extendCommands[0]
    const firstGroup = {
      elements: elementsWithRelativeTime.filter(
        element => !firstCommand || element.createdAt.valueOf() < firstCommand.createdAt.valueOf()
      ),
    }
    if (!firstCommand) {
      return [firstGroup]
    }

    const timespans = [...extendCommands.slice(1), null].map((command, idx) => {
      const lastCommand = extendCommands[idx]
      return {
        start: lastCommand.createdAt.valueOf(),
        end: command === null ? null : command.createdAt.valueOf(),
      }
    })

    const groups = timespans
      .map(({ start, end }) => {
        return {
          elements: elementsWithRelativeTime.filter(
            element =>
              element.createdAt.valueOf() >= start &&
              (end === null || element.createdAt.valueOf() <= end)
          ),
        }
      })
      .filter(group => group.elements.length > 0)

    return [firstGroup, ...groups]
  }, [extendCommands, elementsWithRelativeTime, iteration])

  return groupedElements
}

const useElements = (iteration: Iteration | null) => {
  const elements = useMemo(() => {
    if (!iteration) {
      return []
    }
    const elements = Object.entries(iteration.elements).map(([key, value]) => {
      return { id: key, ...value }
    })
    // also order elements by index
    return elements.sort((a, b) => a.index - b.index)
  }, [iteration])

  return elements
}

export function PendingSuggestionsNotification() {
  return (
    <div className="flex items-center space-x-1 p-1 text-sm leading-tight text-stone-500">
      <SquareArrowUpRight className="size-4" />
      <span>Suggestions requested, waiting to finish action</span>
    </div>
  )
}

function useUserInputFlow(iterationId: string) {
  const userCommandsQuery = useUserCommandsQuery(iterationId)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const latestResponseCommand = useMemo(() => {
    return userCommandsQuery.data?.find(
      command => command.command === ITERATION_COMMANDS.INPUT_ANSWER
    )
  }, [userCommandsQuery.data])

  const latestRequestCommand = useMemo(() => {
    return userCommandsQuery.data?.find(command => isInputRequestCommand(command))
  }, [userCommandsQuery.data])

  const isWaitingForInput = useMemo(() => {
    if (!latestRequestCommand) {
      return false
    }
    if (!latestResponseCommand) {
      return true
    }
    return (
      latestRequestCommand.createdAt.valueOf() > latestResponseCommand.createdAt.valueOf() ||
      latestResponseCommand.status !== 'SUCCESS'
    )
  }, [latestRequestCommand, latestResponseCommand])

  useEffect(() => {
    if (isWaitingForInput) {
      setIsSubmitting(true)
    }
  }, [isWaitingForInput])

  useEffect(() => {
    if (!isWaitingForInput && latestResponseCommand?.status === 'SUCCESS') {
      setIsSubmitting(false)
    }
  }, [isWaitingForInput, latestResponseCommand])

  if (!isWaitingForInput) {
    return {
      isWaitingForInput: false,
      latestRequestCommand: null,
      latestResponseCommand: null,
      isSubmitting,
    } as const
  }

  return {
    isWaitingForInput: true,
    latestRequestCommand: latestRequestCommand!,
    latestResponseCommand: latestResponseCommand!,
    isSubmitting,
  }
}
