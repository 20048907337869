import { Button } from '@/components/ui/button'
import { CirclePlay } from 'lucide-react'
import { useCallback } from 'react'
import { ITERATION_COMMANDS, ITERATION_STATUSES } from '@/const/const'
import { useUserCommandFlow } from '@/lib/iterations/useUserCommandFlow.ts'

export function ResumeIterationButton({ iterationId }: { iterationId: string }) {
  const { sendCommandMutation, isLoadingUserCommand } = useUserCommandFlow(
    iterationId,
    ITERATION_STATUSES.RUNNING
  )
  const resumeIteration = useCallback(async () => {
    sendCommandMutation.mutate({ command: ITERATION_COMMANDS.RESUME })
  }, [iterationId, sendCommandMutation])

  return (
    <Button variant="secondary" onClick={resumeIteration} disabled={isLoadingUserCommand}>
      <CirclePlay />
      {isLoadingUserCommand ? 'Resuming...' : 'Resume'}
    </Button>
  )
}
