import { Outlet, Route, Routes, useParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { collection, orderBy, query } from 'firebase/firestore'
import { useCollection } from 'react-firebase-hooks/firestore'
import MFourOFour from '@/components/molecules/MFourOFour.jsx'
import useStore from '@/stores/useStore'
import { isSuperadmin as isSuperAdminFn } from '@/lib/acl-utils'
import { COLLECTIONS, projectsCollectionRef } from '@/services/Firebase'
import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics'
import OProjectHintListAndDetails from '@/components/organisms/project-details/OProjectHintListAndDetails.jsx'
import OIterationsListAndDetails from '@/components/organisms/project-details/OIterationsListAndDetails.tsx'

const MINUTE_IN_MS = 60000
const INTERFACE_STATES = {
  LOADING: 'loading',
  ERROR: 'error',
  NO_ITERATIONS: 'no-iterations',
  HAS_ITERATIONS: 'has-iterations',
  PROJECT_NOT_FOUND: 'project-not-found',
}

export default function PRojectDetails() {
  const { projectId } = useParams()
  const [lastTick, setLastTick] = useState(Date.now())
  const [interfaceState, setWhatToShow] = useState(INTERFACE_STATES.LOADING)

  const { projects, getProjectById, organizations, teams, getOrgById, getTeamById, userRoles } =
    useStore(state => ({
      projects: state.projects,
      getProjectById: state.getProjectById,
      organizations: state.organizations,
      teams: state.teams,
      getOrgById: state.getOrgById,
      getTeamById: state.getTeamById,
      userRoles: state.userRoles,
    }))

  // Ticks every minute to update relative times in the UI
  useEffect(() => {
    const interval = setInterval(() => {
      setLastTick(Date.now())
    }, MINUTE_IN_MS)
    return () => clearInterval(interval)
  }, [])

  const isSuperAdmin = useMemo(() => {
    return isSuperAdminFn(userRoles)
  }, [userRoles])


  const project = useMemo(() => {
    if (projects.length > 0) {
      return getProjectById(projectId)
    } else {
      return null
    }
  }, [projects, getProjectById, projectId])

  const viewableIterationsQuery = useMemo(() => {
    // dont fetch collection if project is not present.
    // it screwes up security rules
    if (!projectId || !project) {
      return null
    }
    return query(
      collection(projectsCollectionRef, projectId, COLLECTIONS.projectsViewableIterationsMeta),
      orderBy('createdAt', 'desc')
    )
  }, [projectId, project])

  // Actual iterations retrieval
  const [viewableIterationSnapshot, viewableIterationsLoading] =
    useCollection(viewableIterationsQuery)

  const viewableIterations = useMemo(() => {
    if (!viewableIterationSnapshot) {
      return []
    }
    return viewableIterationSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  }, [viewableIterationSnapshot])

  const organization = useMemo(() => {
    if (organizations.length > 0) {
      return getOrgById(project?.organizationId)
    } else {
      return null
    }
  }, [organizations, getOrgById, project])

  const team = useMemo(() => {
    if (teams.length > 0) {
      return getTeamById(project?.teamId)
    } else {
      return null
    }
  }, [teams, getTeamById, project])

  useEffect(() => {
    if (viewableIterationsLoading) {
      setWhatToShow(INTERFACE_STATES.LOADING)
    } else if (projects?.length === 0 || organizations?.length === 0 || teams?.length === 0) {
      setWhatToShow(INTERFACE_STATES.LOADING)
    } else if (!project) {
      setWhatToShow(INTERFACE_STATES.PROJECT_NOT_FOUND)
    } else if (!viewableIterationsLoading && viewableIterationSnapshot?.empty) {
      setWhatToShow(INTERFACE_STATES.NO_ITERATIONS)
    } else {
      setWhatToShow(INTERFACE_STATES.HAS_ITERATIONS)
    }
  }, [
    projects,
    organizations,
    teams,
    viewableIterationsLoading,
    viewableIterationSnapshot,
    project,
  ])

  useEffect(() => {
    if (project?.id) {
      analyticsTrackEvent(ANALYTIC_EVENTS.PROJECT_OPEN, {
        projectId: project?.id,
        projectName: project?.name,
        organizationId: project?.organizationId,
        teamId: project?.teamId,
      })
    }
  }, [project?.id, project?.name, project?.organizationId, project?.teamId])

  // if (interfaceState === INTERFACE_STATES.LOADING) {
  //   return (
  //     <div className="fixed inset-0 flex items-center justify-center bg-white/80">
  //       <div className="size-8 animate-spin rounded-full border-4 border-gray-200 border-t-blue-600" />
  //     </div>
  //   )
  // }

  // if (interfaceState === INTERFACE_STATES.PROJECT_NOT_FOUND) {
  //   return (
  //     <MFourOFour
  //       title="Project not found"
  //       description={`Project doesn't exist or you don't have access to it`}
  //     />
  //   )
  // }

  // if (interfaceState === INTERFACE_STATES.NO_ITERATIONS) {
  //   return <div></div>
  // }

  return (
    <>
      <Routes>
        {isSuperAdmin && (
          <Route
            path="hints"
            exact
            element={
              <OProjectHintListAndDetails
                key={projectId}
                organizationId={project?.organizationId}
                projectId={projectId}
                lastTick={lastTick}
              />
            }
          />
        )}
        <Route
          path="*"
          element={
            <OIterationsListAndDetails
              key={projectId}
              iterations={viewableIterations}
              iterationsLoading={viewableIterationsLoading}
              lastTick={lastTick}
              project={project}
              team={team}
              organization={organization}
            />
          }
        />
      </Routes>
      <Outlet />
    </>
  )
}
