import { cn } from '@/lib/utils.ts'
import { CircleDashed } from 'lucide-react'

export function MElementSkeleton() {
  return (
    <div className="flex w-full flex-row items-center gap-4 p-1">
      <ElementIconBox />
      <div className="h-1 w-3/4 animate-pulse rounded bg-gradient-to-r from-stone-100 to-stone-200" />
    </div>
  )
}

function ElementIconBox() {
  return (
    <div
      className={cn(
        'to-sto-200 grid size-9 place-items-center rounded-md border bg-gradient-to-r from-stone-100 to-stone-200 text-stone-400 '
      )}
    >
      <CircleDashed className={cn('size-4')} />
    </div>
  )
}
