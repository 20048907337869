import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.tsx'
import { actionShape } from '@/components/propTypes.js'
import { detectProgrammingLanguageFromFilePath } from '@/components/actions/utils.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import { FileSymlink } from 'lucide-react'
import AActionContentTerminal from '@/components/actions/components/AActionContentTerminal'
import { getLanguageBlock } from '@/lib/iteration-utils.ts'
import { getOutputVersion } from '@/lib/action-utils'

export default function GetFileContentsAction({ action, isLast = false, isInteractive = false }) {
  const filePath = action?.args?.path
  let detailsContent = `## PATH:\n${action?.args?.path}\n\n## COMMENT:\n${action?.args?.comment}\n\n`
  const language = detectProgrammingLanguageFromFilePath(filePath)

  const terminalOutputs = []

  action?.outputs?.forEach(output => {
    if (output == null) {
      return
    }
    let outputContent = output
    if (getOutputVersion(output) === 2) {
      outputContent = output?.content
    }
    const languageBlock = getLanguageBlock(language, outputContent)
    terminalOutputs.push(`${languageBlock} \n`)
    detailsContent += '## FILE CONTENTS:\n' + languageBlock + '\n\n\n'
  })

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={FileSymlink}
      isLast={isLast}
      isInteractive={isInteractive}
      title={action?.args?.comment ?? 'Read file'}
    >
      <AActionContentGenericLayout>
        <AActionContentTerminal
          content={filePath}
          terminalOutputs={terminalOutputs}
          errored={action?.outputs?.error}
          language={language}
        />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

GetFileContentsAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
  isInteractive: PropTypes.bool,
}
