import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { InformationCircleIcon } from '@heroicons/react/24/solid/index.js'
import { Button } from '@/components/catalyst/button.jsx'

import { ANALYTIC_EVENTS, analyticsTrackEvent } from '@/services/Analytics'
import { useSearchParams } from 'react-router-dom'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid/index.js'
import { actionShape } from '@/components/propTypes.js'
import PropTypes from 'prop-types'
import { ACTION_STATUSES } from '@/const/const'
import {
  FlagTriangleRight,
  GitCompare,
  InfoIcon,
  MoreVertical,
  Redo2,
  XOctagon,
} from 'lucide-react'
import Logo from '@/assets/svg-components/Logo.jsx'
import colors from 'tailwindcss/colors.js'
import { safeParseInt } from '@/lib/params-utils.js'
import { Button as ShadCNButton } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { convertGitHubSSHToHTTPS, hasValidCommitHashes } from '@/lib/git-utils.js'
import { Link } from '@/components/catalyst/link.jsx'
import MRestartFromAction from '@/components/molecules/iteration-details/MRestartFromAction.jsx'
import MActionDetailsInspector from '@/components/molecules/iteration-details/MActionDetailsInspector.jsx'
import { useIsUserSuperAdmin } from '@/stores/useStore.js'
import { cn } from '@/lib/utils.ts'
import MAddUserSuggestion from '@/components/molecules/iteration-details/MAddUserSuggestion'
import { useInteractiveStateContext } from '@/components/molecules/iteration-details/InteractiveStateContext'
import ALogoSpinner from '../atoms/ALogoSpinner.tsx'

export default function GenericAction({
  action,
  title,
  detailsContent,
  actionIcon,
  shouldBeExpanded = false,
  customOptions = {},
  isLast = false,
  isInteractive = false,
  children,
}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [showRestore, setShowRestore] = useState(false)
  const [showAddUserSuggestion, setShowAddUserSuggestion] = useState(false)
  const selectedActionIndex = safeParseInt(searchParams.get('action'))
  const [isJSONViewOpen, setIsJSONViewOpen] = useState(false)
  const [isLLMPlaygrounOpen, setIsLLMPlaygrounOpen] = useState(false)
  const [isActionExpanded, setIsActionExpanded] = useState(
    selectedActionIndex === action?.index || shouldBeExpanded
  )
  const isUserSuperAdmin = useIsUserSuperAdmin()
  const { setRollbackAction } = useInteractiveStateContext()

  const actionDataToDisplayOnHover = useMemo(() => {
    return _.omit(action, [
      // 'actionId',
      // 'elementId',
      // 'stepId',
      // 'iterationId',
      'projectId',
      'index',
      'status',
      'updatedAt',
      'createdAt',
      'startedAt',
      'endedAt',
      'updatedAtRelative',
      'shouldBeDeleted',
    ])
  }, [action])

  const shouldAllowLLMPlayground = useMemo(() => {
    return action?.type === 'llm' && action?.subtype === 'request'
  }, [action])

  const ActionIcon = useMemo(() => {
    return actionIcon ?? InformationCircleIcon
  }, [actionIcon])

  const handleShowDetails = useCallback(() => {
    if (!isJSONViewOpen) {
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_ACTION_DETAILS, {
        iterationId: action?.iterationId,
        elementIndex: action?.elementIndex,
        stepIndex: action?.stepIndex,
        actionIndex: action?.index,
        actionType: action?.type,
        actionName: action?.name,
        status: action?.status,
      })
    }
    setIsJSONViewOpen(true)
  }, [isJSONViewOpen, action])

  const handleDeepLinkAction = () => {
    setSearchParams(
      prevParams => {
        const newParams = new URLSearchParams(prevParams)
        if (action?.index) {
          newParams.set('action', action.index)
        } else {
          newParams.delete('action')
        }
        newParams.set('element', action.elementIndex)
        newParams.set('step', action.stepIndex)
        return newParams
      },
      { replace: false }
    )
  }

  const [ActionStatusIcon, actionTextColor, actionStatusIconBg] = useMemo(() => {
    let actionStatus = action?.status
    if (
      actionStatus === ACTION_STATUSES.DONE &&
      action?.type === 'function' &&
      action?.outputs?.at(-1)?.status === 'failed'
    ) {
      actionStatus = ACTION_STATUSES.FAILED
    }
    if (isInteractive) {
      return [ActionIcon, 'text-stone-500', 'bg-orange-50']
    }
    switch (actionStatus) {
      case ACTION_STATUSES.AWAITING:
        return [ActionIcon, 'text-stone-500', 'bg-stone-100']
      case ACTION_STATUSES.RUNNING:
        return [ALogoSpinner, 'text-violet-600', 'bg-violet-50']
      case ACTION_STATUSES.DONE:
        return [ActionIcon, 'text-green-600', 'bg-green-100']
      case ACTION_STATUSES.FAILED:
        return [XOctagon, 'text-red-600', 'bg-red-100']
      default:
        return [ActionIcon, 'text-stone-400', 'bg-stone-50']
    }
  }, [action?.status, isInteractive])

  const handleToggleExpandAction = isExpanded => {
    if (isExpanded) {
      handleDeepLinkAction()
    }
    setIsActionExpanded(isExpanded)
  }

  const actionRef = useRef(null)
  const isSticky = useIsSticky(actionRef.current)

  const showCodeChangesForIteration = hasValidCommitHashes(action)
  const showActionMenu = isUserSuperAdmin || showCodeChangesForIteration

  const actionsMenu = (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <ShadCNButton variant="ghost" className="size-8 p-0">
          <MoreVertical className="size-4" />
        </ShadCNButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {showCodeChangesForIteration && (
          <DropdownMenuItem>
            <Link
              href={`${convertGitHubSSHToHTTPS(action?.repoURI)}/compare/${action?.startCommitHash}...${action?.endCommitHash}`}
              target="_blank"
              className="flex items-center"
              onClick={() => {
                analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CLICK_REPO_CHANGES_STEP_ACTION, {
                  iterationId: action?.iterationId,
                  elementIndex: action?.elementIndex,
                  stepIndex: action?.stepIndex,
                  actionIndex: action?.index,
                  actionType: action.type,
                  actionName: action.name,
                  status: action?.status,
                })
              }}
            >
              <GitCompare className="mr-2 size-4" />
              <span>Code changes</span>
            </Link>
          </DropdownMenuItem>
        )}
        {isUserSuperAdmin && (
          <DropdownMenuItem>
            <div className="flex cursor-pointer items-center " onClick={handleShowDetails}>
              <InfoIcon strokeWidth={3} className="mr-2  h-6 w-4 text-zinc-600" />
              <span>Show details</span>
            </div>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem>
          <div
            className="flex cursor-pointer items-center "
            onClick={() => setShowAddUserSuggestion(true)}
          >
            <FlagTriangleRight strokeWidth={3} className="mr-2  h-6 w-4 text-zinc-600" />
            <span>Add suggestion</span>
          </div>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <div
            className="flex cursor-pointer items-center"
            onClick={() => setRollbackAction(action)}
          >
            <Redo2 strokeWidth={3} className="mr-2  h-6 w-4 text-zinc-600" />
            <span>Rollback to here</span>
          </div>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )

  return (
    <div className="starting-fade-in mx-4 border border-t-0 border-border bg-white last:rounded-b">
      <MRestartFromAction
        action={action}
        showUserInput={showRestore}
        setShowUserInput={setShowRestore}
      />
      <div
        className={cn(
          'w-full space-y-2 bg-white transition-all duration-300',
          'z-10',
          'p-1 pr-1.5',
          'sticky top-[var(--element-top-offset,0)]',
          'border-b-transparent shadow-lg ring-1',
          'shadow-transparent ring-transparent',
          isSticky && 'border-b-border shadow-white ring-border'
        )}
        ref={actionRef}
        data-index={action?.index}
      >
        <div
          className={cn(
            'flex max-w-full items-center gap-3 text-sm text-muted-foreground *:shrink-0',
            isInteractive && 'ring-1 ring-orange-300'
          )}
        >
          <ActionStatusIcon
            className={cn('size-9 rounded-md p-2.5', actionStatusIconBg, actionTextColor)}
          />
          <div className="!shrink grow overflow-hidden text-ellipsis text-nowrap">
            <span className="font-pp-supply-sans text-muted-foreground">
              {action.elementIndex}.{action.stepIndex}.{action.index}.
            </span>
            <span className="ml-3 font-medium text-base-foreground">{title ?? action.name}</span>
          </div>

          <div className="flex gap-2">
            <div className="flex h-8 items-center justify-center gap-2 rounded-md bg-white/0">
              {showActionMenu && actionsMenu}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="w-full p-4">{children}</div> */}
      {/* pending actions occupy too much of the screen. To be discussed if we leave it that way. */}
      {action.status !== ACTION_STATUSES.AWAITING && action.status !== ACTION_STATUSES.DRAFT && (
        <div className="w-full p-4">{children}</div>
      )}
      {showAddUserSuggestion && (
        <MAddUserSuggestion
          action={action}
          shouldShow={showAddUserSuggestion}
          setShouldShow={setShowAddUserSuggestion}
        />
      )}
      {isJSONViewOpen && (
        <MActionDetailsInspector
          title="Action details"
          content={children}
          isOpen={isJSONViewOpen}
          setIsOpen={setIsJSONViewOpen}
          code={detailsContent}
          language="markdown"
          actionObject={actionDataToDisplayOnHover}
        />
      )}
    </div>
  )
}

GenericAction.propTypes = {
  action: actionShape,
  title: PropTypes.string,
  detailsContent: PropTypes.string,
  actionIcon: PropTypes.elementType,
  customOptions: PropTypes.shape({
    iconStyle: PropTypes.string,
    actionBodyStyle: PropTypes.string,
  }),
  isLast: PropTypes.bool,
  children: PropTypes.node,
}

function getActionDuration(action) {
  if (!action?.elapsedTime) {
    return ''
  }
  const elapsedTime = _.round(action.elapsedTime, action.elapsedTime >= 10 ? 0 : 1)
  return `${elapsedTime} s`
}

function useIsSticky(el: Element | null) {
  const [isSticky, setIsSticky] = useState(false)

  const observer = useMemo(() => {
    if (!el) {
      return
    }
    const offset = window.getComputedStyle(el).getPropertyValue('--element-top-offset')
    if (!offset) {
      return
    }

    const offsetInt = parseInt(offset)
    return new IntersectionObserver(
      ([e]) => {
        setIsSticky(!e.isIntersecting)
      },
      {
        threshold: [1],
        rootMargin: `-${offsetInt + 1}px 0px 0px 0px`,
      }
    )
  }, [el])

  useEffect(() => {
    if (el) {
      observer?.observe(el)
    }
    return () => observer?.disconnect()
  }, [el, observer])
  return isSticky
}
