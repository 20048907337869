import { useCallback, useEffect, useState } from 'react'

import { Button } from '@/components/ui/button'
import { ArrowUp, CircleStop, SquarePen } from 'lucide-react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import FormField from '@/components/ui/form-field.tsx'
import { ABorderBox } from '@/components/atoms/ABorderBox.tsx'
import ALogoSpinner from '@/components/atoms/ALogoSpinner.tsx'
import { useToast } from '@/components/ui/use-toast'
import { sendUserCommand, UserInputCommand, useSendUserCommand } from '@/lib/sendUserCommand.ts'
import { ITERATION_COMMANDS } from '@/const/const.ts'
import { cn } from '@/lib/utils.ts'
import { useMutation } from '@tanstack/react-query'
import { retryPromptFirebaseFunction } from '@/services/Firebase.ts'
import { useUserCommandQuery } from '@/lib/iterations/userUserCommandQuery.ts'

type MRetryPrompt = {
  iterationId: string
  className?: string
  suggestedPrompt?: string
}

export default function MRetryPrompt({ iterationId, className, suggestedPrompt }: MRetryPrompt) {
  const { toast } = useToast()
  const [commandId, setCommandId] = useState<string | null>(null)
  const form = useForm({
    resolver: zodResolver(promptSchema),
    defaultValues: {
      prompt: '',
    },
  })
  const {
    mutate: retryPromptMutation,
    isPending: isMutationInProgress,
    isSuccess,
  } = useMutation({
    mutationFn: async (prompt: string) => {
      if (!iterationId) {
        // hooks can be only top level, so we can't have if (iterationId) { useSendUserCommand() }
        throw new Error('iterationId is required')
      }
      return retryPromptFirebaseFunction({ iterationId, prompt })
    },
    mutationKey: ['sendRetryPrompt', iterationId],
    onError: error => {
      console.error('Error retrying prompt:', error)
      toast({
        variant: 'destructive',
        title: 'Error retrying prompt 😔',
        description: error instanceof Error ? error.message : 'Please try again.',
      })
    },
    onSuccess: ({ data }) => {
      setCommandId(data.id)
    },
  })

  const commandQuery = useUserCommandQuery(commandId)

  const isLoading = isMutationInProgress || (isSuccess && commandQuery.data?.status !== 'SUCCESS')

  const onSubmit = useCallback(
    ({ prompt }: PromptFormData) => {
      retryPromptMutation(prompt)
      console.log('retry with prompt: ', prompt)
    },
    [retryPromptMutation]
  )

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
        e.preventDefault()
        if (form.formState.isValid && !isLoading) {
          form.handleSubmit(onSubmit)()
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [form.formState.isValid, isMutationInProgress, form.handleSubmit, onSubmit])

  const fillInSuggestedPrompt = useCallback(() => {
    if (suggestedPrompt) {
      form.setValue('prompt', suggestedPrompt)
    }
  }, [form])

  const hasSuggestedPrompt = suggestedPrompt && suggestedPrompt !== ''
  const Icon = isLoading ? ALogoSpinner : ArrowUp
  // isDone as in: the iteration is done. So either we don't have a role or role is done.

  return (
    <div className={cn('relative space-y-2', className)}>
      {hasSuggestedPrompt && (
        <Button
          variant="secondary"
          // size="sm"
          className="absolute -top-10 right-0"
          onClick={() => fillInSuggestedPrompt()}
          disabled={!hasSuggestedPrompt || isLoading}
        >
          <SquarePen />
          Suggest prompt
        </Button>
      )}
      <form onSubmit={form.handleSubmit(onSubmit)} className="">
        <ABorderBox
          isEnabled={isLoading}
          borderClasname="text-border"
          className="rounded-md bg-white"
        >
          <FormField.Root control={form.control} name="prompt">
            <FormField.Textarea
              {...form.register('prompt')}
              autogrow
              disabled={isLoading}
              variant="ghost"
              className="resize-none bg-white shadow-none focus-visible:ring-0"
              placeholder="Build Shopify Ecommerce with 10 products for ASOS"
            />
          </FormField.Root>
          <div className="p-4 text-right">
            <Button variant="ghost" className="ml-auto" disabled={isLoading} type="submit">
              <Icon className="size-4" />
            </Button>
          </div>
        </ABorderBox>
      </form>
    </div>
  )
}

const promptSchema = z.object({
  prompt: z.string().trim().min(3),
})
type PromptFormData = z.output<typeof promptSchema>
