import { useState, useCallback, useEffect, useRef } from 'react'

import { Textarea } from '@/components/ui/textarea.tsx'
import { Button } from '@/components/ui/button.tsx'

import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics'
import { useSendUserCommand } from '@/lib/sendUserCommand.ts'
import { ITERATION_COMMANDS } from '@/const/const.ts'
import { useToast } from '@/components/ui/use-toast'
import { Redo2 } from 'lucide-react'
import { ABorderBox } from '@/components/atoms/ABorderBox.tsx'
import FormField from '@/components/ui/form-field.tsx'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

export default function MRollbackIteration({ action, onClose }) {
  const { toast } = useToast()
  const sendCommand = useSendUserCommand(action.iterationId)

  const form = useForm({
    resolver: zodResolver(promptSchema),
    defaultValues: {
      prompt: '',
    },
  })
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const sendIterationCommand = useCallback(
    async (prompt: string) => {
      try {
        if (prompt && prompt !== '') {
          await sendCommand.mutateAsync({
            command: ITERATION_COMMANDS.SUGGESTION,
            commandArgs: { suggestion: prompt },
          })
          analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_ADD_USER_SUGGESTION, {
            iterationId: action.iterationId,
            elementIndex: action.elementIndex,
            stepIndex: action.stepIndex,
            actionIndex: action.index,
            suggestion: prompt,
          })
        }

        await sendCommand.mutateAsync({
          command: ITERATION_COMMANDS.ROLLBACK,
          commandArgs: {
            iteration_id: action.iterationId,
            element_index: action.elementIndex,
            step_index: action.stepIndex,
            action_index: action.index,
          },
        })

        form.setValue('prompt', '')
        onClose()
        analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_ROLLBACK_NO_COMMENT, {
          iterationId: action.iterationId,
          elementIndex: action.elementIndex,
          stepIndex: action.stepIndex,
          actionIndex: action.index,
          suggestion: prompt,
        })
      } catch (error) {
        console.error('Error during rollback process', error)
        toast({
          variant: 'destructive',
          title: 'Error performing operation 😔',
          description: 'Try refreshing the page or contact Proofs team.',
        })
      }
    },
    [action, onClose]
  )

  useEffect(() => {
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus()
        const length = textareaRef.current.value.length
        textareaRef.current.setSelectionRange(length, length)
      }
    }, 200)
  }, [])

  const onSubmit = useCallback(
    ({ prompt }: PromptFormData) => {
      sendIterationCommand(prompt)
    },
    [sendIterationCommand, prompt]
  )

  const isLoadingUserCommand = sendCommand.isPending

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
        e.preventDefault()
        if (form.formState.isValid && !isLoadingUserCommand) {
          form.handleSubmit(onSubmit)()
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [form.formState.isValid, isLoadingUserCommand, form.handleSubmit, onSubmit])

  return (
    <div className="space-y-2 py-4 text-sm text-stone-500">
      <div className="font-medium text-stone-900">
        {`Restart from action: ${action.elementIndex}.${action.stepIndex}.${action.index}`}
      </div>

      <form onSubmit={form.handleSubmit(onSubmit)} className="">
        <ABorderBox
          isEnabled={isLoadingUserCommand}
          borderClasname="text-border"
          className="rounded-md bg-white"
        >
          <FormField.Root control={form.control} name="prompt">
            <FormField.Textarea
              {...form.register('prompt')}
              ref={textareaRef}
              autogrow
              disabled={isLoadingUserCommand}
              variant="ghost"
              className="resize-none bg-white shadow-none focus-visible:ring-0"
              placeholder="Leave suggestion..."
            />
          </FormField.Root>
          <div className="flex gap-2">
            <Button variant="outline" disabled={isLoadingUserCommand} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="destructive"
              disabled={isLoadingUserCommand}
              className="w-full flex-1"
              type="submit"
            >
              <Redo2 />
              {isLoadingUserCommand ? 'Restarting...' : 'Restart'}
            </Button>
          </div>
        </ABorderBox>
      </form>
    </div>
  )
}

const promptSchema = z.object({
  prompt: z.string().trim().min(3).optional(),
})
type PromptFormData = z.output<typeof promptSchema>
