import {
  CONTINUATION_CHAT_ROLES,
  GUNSLINGER_JOB_STATUSES,
  GunslingerJobStatus,
  ITERATION_COMMANDS,
  ITERATION_STATUSES,
  IterationComputedStatus,
  IterationStatus,
} from '@/const/const.ts'
import { SUPPORTED_LANGUAGES } from '@/const/defaults'
import { DateTime } from 'luxon'
import { Iteration, RoleRegistry } from '@/services/firebase_shared_types.ts'

const VM_OVERRIDE_STATUSES = [
  GUNSLINGER_JOB_STATUSES.STOPPED,
  GUNSLINGER_JOB_STATUSES.STOPPING,
  GUNSLINGER_JOB_STATUSES.ERROR,
] as const

export function getIterationComputedStatus(
  iterationStatus: IterationStatus | null,
  vmStatus: GunslingerJobStatus | null | undefined,
  active_role?: RoleRegistry[string] | null
): IterationComputedStatus | null {
  if (active_role && checkIsRoleActive(active_role)) {
    return ITERATION_STATUSES.AWAITING
  }
  if (vmStatus && VM_OVERRIDE_STATUSES.includes(vmStatus)) {
    if (![ITERATION_STATUSES.DONE, ITERATION_STATUSES.FAILED].includes(iterationStatus))
      return vmStatus
  }

  return iterationStatus
}

export function formatIterationStatus(status: IterationComputedStatus): string {
  if (!status) {
    return ''
  }
  return status.replace(/_/g, ' ')
}

export function generateGCPLoggingLink(
  filters: { [s: string]: unknown } | ArrayLike<unknown>,
  startTime?: any
) {
  // We do that so the project can easily change from the envs
  const gcpProjectId = import.meta.env.VITE_GCP_LOGS_PROJECT_ID
  const baseUrl = 'https://console.cloud.google.com/logs/query'
  const queryParts = Object.entries(filters).map(([key, value]) => `${key}="${value}"`)
  const query = queryParts.join(' AND ')
  const encodedQuery = encodeURIComponent(query)
  const dateRange = startTime
    ? `;startTime=${DateTime.fromJSDate(startTime?.toDate()).toISO()}`
    : ''
  const link = `${baseUrl};query=${encodedQuery}${dateRange}?project=${gcpProjectId}`
  return link
}

export function getGrafanaUrl({ iterationId, organizationId, fromTimestamp, orgId = 1 }) {
  const projectId = import.meta.env.VITE_GCP_LOGS_PROJECT_ID
  const panesObject = {
    ojm: {
      datasource: 'grafanacloud-logs',
      queries: [
        {
          refId: 'A',
          expr: `{project_id="${projectId}", proofs_iteration_id="${iterationId}", proofs_organization_id="${organizationId}"}`,
          queryType: 'range',
          datasource: {
            type: 'loki',
            uid: 'grafanacloud-logs',
          },
          editorMode: 'code',
          direction: 'backward',
        },
      ],
      range: {
        from: fromTimestamp,
        to: 'now',
      },
      panelsState: {
        logs: {
          visualisationType: 'logs',
        },
      },
    },
  }

  const encodedPanes = encodeURIComponent(JSON.stringify(panesObject))

  return `https://proofs.grafana.net/explore?schemaVersion=1&panes=${encodedPanes}&orgId=${orgId}`
}

function formatJSON(data) {
  return JSON.stringify(
    data,
    (key, value) => {
      if (typeof value === 'string') {
        try {
          return JSON.parse(value)
        } catch (e) {
          return value
        }
      }
      return value
    },
    2
  )
}

export function prettyPrintField(field: any) {
  let content = null

  if (field == null) {
    content = null
  } else {
    if (Array.isArray(field) && field.length === 1) {
      const singleItem = field[0]
      if (singleItem) {
        content = formatJSON(singleItem)
      } else {
        content = singleItem
      }
    } else {
      content = formatJSON(field)
    }
  }

  return content
}

export function toFirestoreConsoleLink(collectionName: string, docId: string): string {
  const encodedCollection = encodeURIComponent(collectionName).replace(/%2F/gi, '~2F')
  const encodedDocId = encodeURIComponent(docId).replace(/%2F/gi, '~2F')

  const projectId = import.meta.env.VITE_FIREBASE_PROJECT_ID

  return `https://console.firebase.google.com/project/${projectId}/firestore/databases/-default-/data/~2F${encodedCollection}~2F${encodedDocId}`
}

export function isVmDead(vmStatus: typeof GUNSLINGER_JOB_STATUSES) {
  return [GUNSLINGER_JOB_STATUSES.STOPPED, GUNSLINGER_JOB_STATUSES.ERROR].includes(vmStatus)
}

export function getLanguageBlock(language, content) {
  if (Object.values(SUPPORTED_LANGUAGES).includes(language)) {
    return content
  } else {
    return '```' + language + '\n' + content + '\n```'
  }
}

export function isVMreadyForPreview(vmStatus) {
  return ![GUNSLINGER_JOB_STATUSES.STOPPED, GUNSLINGER_JOB_STATUSES.ERROR].includes(vmStatus)
}

export function areAnyUserCommandsPending(
  userCommands: any,
  commandType: (typeof ITERATION_COMMANDS)[keyof typeof ITERATION_COMMANDS]
) {
  return userCommands?.some(
    userCommand =>
      userCommand.command === commandType &&
      !['SUCCESS', 'FAILED', 'REJECTED'].includes(userCommand.status)
  )
}

export function checkIsRoleActive(role: RoleRegistry[string] | null) {
  if (!role) {
    return false
  }
  return Boolean(
    CONTINUATION_CHAT_ROLES.includes(role.name) &&
      role?.turn?.label &&
      ['in_progress', 'waiting_for_message'].includes(role.status) &&
      role.turn.label !== 100
  )
}

export function doesIterationHaveActions(iteration?: Iteration): boolean {
  if (!iteration) {
    return false
  }

  return Object.values(iteration.elements).some(element =>
    Object.values(element.steps ?? {}).some(step => Object.keys(step.actions ?? {}).length > 0)
  )
}
