import { cn } from '@/lib/utils.ts'

export default function ALogoSpinner({ className }: { className?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('proofs-logo', className)}
    >
      <circle cx="60" cy="60" r="44" stroke="currentColor" strokeWidth="30" className="spinner" />
      <circle cx="60" cy="60" r="44" stroke="currentColor" strokeWidth="28" className="stripes" />
    </svg>
  )
}
