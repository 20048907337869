import { BrowserWindow } from '@/components/actions/BroswerAction/BrowserAction.tsx'
import { useCallback, useState } from 'react'
import { useMemo } from 'react'
import { ResizablePanel } from '@/components/ui/resizable.tsx'
import { ScrolledArea } from './AScrolledArea.tsx'
import { ATabHeader } from './ATabHeader.tsx'
import { ListTodo, TvMinimal, Server, RotateCw } from 'lucide-react'
import { useIterationQuery } from '@/lib/iterations/useIterationQuery.ts'
import { TabContent } from './MPanelTabs.tsx'
import { AddressBar } from '@/components/actions/BroswerAction/BrowserAction.tsx'
import OIterationActions from './OIterationDetails/OIterationActions.tsx'
import { PanelContext } from './MPanelTabs.tsx'
import { TabButton } from './MPanelTabs.tsx'
import ConnectGithubDialog from './OIterationDetails/ConnectGithubDialog.tsx'
import { Button } from '@/components/ui/button.tsx'
import { cn } from '@/lib/utils.ts'

export function ORightPanel({ iterationId, lastTick }: { iterationId: string; lastTick: number }) {
  const [activeTab, setActiveTab] = useState(ACTIONS_TAB_NAME)

  const previewUrls = usePreviewUrls(iterationId)

  const buttonPanels: { value: string; label: string; icon: React.ReactNode }[] = useMemo(() => {
    const base = [{ value: ACTIONS_TAB_NAME, label: 'Actions', icon: <ListTodo /> }]

    if (!previewUrls) {
      return base
    }

    return [
      ...base,
      ...previewUrls.map(preview => ({
        value: preview.label,
        label: preview.label,
        icon: preview.is_api ? <Server /> : <TvMinimal />,
      })),
    ]
  }, [previewUrls])

  return (
    <PanelContext.Provider value={activeTab}>
      <ResizablePanel defaultSize={50} minSize={30}>
        <ScrolledArea
          stickyElement={
            <ATabHeader className="sticky top-0 z-20">
              <div className="flex items-center gap-1.5 px-4 py-3">
                {buttonPanels.map(buttonPanel => (
                  <TabButton
                    key={buttonPanel.value}
                    active={activeTab === buttonPanel.value}
                    onClick={() => setActiveTab(buttonPanel.value)}
                  >
                    {buttonPanel.icon} {buttonPanel.label}
                  </TabButton>
                ))}
              </div>
              <div className="ml-auto px-4 py-3">
                <ConnectGithubDialog iterationId={iterationId} />
              </div>
            </ATabHeader>
          }
        >
          <TabContent name={ACTIONS_TAB_NAME}>
            <OIterationActions iterationId={iterationId} lastTick={lastTick} />
          </TabContent>

          <PreviewTabs iterationId={iterationId} key={Math.floor((lastTick ?? 0) / 60)} />
        </ScrolledArea>
      </ResizablePanel>
    </PanelContext.Provider>
  )
}

const usePreviewUrls = (iterationId: string) => {
  const iterationQuery = useIterationQuery(iterationId)

  return useMemo(() => {
    if (!iterationQuery.data) {
      return null
    }

    const previewUrls = iterationQuery.data.preview_urls_enhanced?.urls ?? null

    return previewUrls
  }, [iterationQuery.data])
}

const ACTIONS_TAB_NAME = 'actions'

function PreviewTabs({ iterationId }: { iterationId: string }) {
  const previewUrls = usePreviewUrls(iterationId)

  if (previewUrls === null) {
    return null
  }

  return previewUrls.map(preview => (
    <TabContent key={preview.label} name={preview.label}>
      <div className="grid h-full place-items-stretch p-4">
        <PreviewWindow url={preview.url} />
      </div>
    </TabContent>
  ))
}

function PreviewWindow({ url }: { url: string }) {
  const [refreshCount, setRefreshCount] = useState(0) // used to trigger a re-render of the iframe thanks to `key` property.

  const refresh = useCallback(() => {
    setRefreshCount(refreshCount + 1)
  }, [refreshCount, setRefreshCount])

  return (
    <BrowserWindow>
      <AddressBar url={url}>
        <Button size="icon" variant="ghost" onClick={refresh}>
          <RotateCw
            key={refreshCount}
            className={cn('size-3', refreshCount > 0 && 'animate-[spin_500ms_ease-in-out_1]')}
          />
        </Button>
      </AddressBar>
      <iframe key={refreshCount} className="size-full bg-white" src={url}></iframe>
    </BrowserWindow>
  )
}
