import { Button } from '@/components/ui/button.tsx'
import { CirclePause } from 'lucide-react'
import { useCallback } from 'react'
import { ITERATION_COMMANDS, ITERATION_STATUSES } from '@/const/const.ts'
import { useUserCommandFlow } from '@/lib/iterations/useUserCommandFlow.ts'

export function PauseIterationButton({ iterationId }: { iterationId: string }) {
  const { sendCommandMutation, isLoadingUserCommand } = useUserCommandFlow(
    iterationId,
    ITERATION_STATUSES.PAUSING
  )
  const pauseIteration = useCallback(async () => {
    sendCommandMutation.mutate({ command: ITERATION_COMMANDS.PAUSE })
  }, [iterationId, sendCommandMutation])
  return (
    <Button variant="secondary" onClick={pauseIteration} disabled={isLoadingUserCommand}>
      <CirclePause />
      {isLoadingUserCommand ? 'Pausing' : 'Pause'}
    </Button>
  )
}
