import { Button } from '@/components/ui/button.tsx'
import { cn } from '@/lib/utils.ts'
import { createContext } from 'react'
import { useContext } from 'react'

export function TabContent({ children, name }: { children: React.ReactNode; name: string }) {
  const activeTab = usePanelContext()

  const isActive = activeTab === name

  return (
    <div className={cn('contents', activeTab !== name && 'hidden')}>{isActive && children}</div>
  )
}

export function TabButton({
  active,
  onClick,
  children,
}: {
  active: boolean
  onClick: () => void
  children: React.ReactNode
}) {
  return (
    <Button
      variant={'ghost'}
      className={cn(active && 'bg-base-accent')}
      size="sm"
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

export function usePanelContext() {
  return useContext(PanelContext)
}

export const PanelContext = createContext<string>('actions')
