import { useQuery } from '@tanstack/react-query'
import { iterationPromptsRepository } from '@/services/Firebase.ts'
import { onSnapshot, getDocs, Timestamp } from 'firebase/firestore'
import { useEffect, useMemo } from 'react'
import { useIterationQuery } from './useIterationQuery.ts'

export const useIterationPromptsQuery = (iterationId: string | null) => {
  const query = useQuery({
    enabled: Boolean(iterationId),
    queryKey: ['iteration-prompts', iterationId],
    queryFn: async () => {
      if (!iterationId) {
        throw new Error('iterationId is required')
      }

      const result = await getDocs(iterationPromptsRepository(iterationId))
      const data: PromptHistoryEntry[] = result.docs
        .map(doc => doc.data() as PromptHistoryEntryInput)
        .sort((a, b) => a.index - b.index)
        .map(item => ({
          ...item,
          createdAt: new Date(item.createdAt.seconds * 1000),
          updatedAt: new Date(item.updatedAt.seconds * 1000),
        }))
      return data
    },
  })
  useLiveIterationPrompts(iterationId, query.refetch)
  return query
}

export function useFirstIterationPromptQuery(iterationId: string | null) {
  const iterationQuery = useIterationQuery(iterationId)

  const iterationPromptsQuery = useIterationPromptsQuery(iterationId)

  const basePrompt = useMemo(() => {
    return iterationQuery.data?.prompt
  }, [iterationQuery.data?.prompt])

  const historyPrompt = useMemo(() => {
    if (!iterationPromptsQuery.data || iterationPromptsQuery.data.length === 0) {
      return null
    }
    return iterationPromptsQuery.data[0].user_prompt
  }, [iterationPromptsQuery.data])

  if (iterationQuery.isLoading || iterationPromptsQuery.isLoading) {
    return null
  }

  return historyPrompt ?? basePrompt
}

function useLiveIterationPrompts(iterationId: string | null, onChange: () => void) {
  useEffect(() => {
    if (!iterationId) {
      return
    }
    const unsubscribe = onSnapshot(
      iterationPromptsRepository(iterationId),
      snapshot => {
        snapshot.docChanges() // whatever, we just want to refetch.
        onChange()
      },
      error => {
        console.error(
          '[Error] listener encountered an error when querying live iteration prompts:',
          error.code,
          error.message
        )
      }
    )

    return unsubscribe
  }, [iterationId, onChange])
}

type PromptHistoryEntryInput = {
  updatedAt: Timestamp
  createdAt: Timestamp
  user_prompt: string
  final_prompt: string
  id: string
  index: number
  prompt_validation: {
    has_any_violation: boolean
    intents: {
      name: string
      violation: boolean
      violation_reason: string
    }[]
  }
}

export type PromptHistoryEntry = {
  updatedAt: Date
  createdAt: Date
  user_prompt: string
  final_prompt: string
  id: string
  index: number

  specifications?: {
    // old projects don't have this.
    data: {
      markdown: string
      promp_summary: string
      title: string
    }
  }

  prompt_validation: {
    has_any_violation: boolean
    intents: {
      name: string
      violation: boolean
      violation_reason: string
    }[]
  }
}
