import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.tsx'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'
import { LandPlot } from 'lucide-react'
import { useIsUserSuperAdmin } from '@/stores/useStore.js'
import { ActionSectionHeader } from './ActionSectionHeader.tsx'

function ReflectorItem({ title, items, descriptionFieldName = 'description' }) {
  return (
    <div className="px-4 text-base-foreground mt-5">
      <ActionSectionHeader>{title}</ActionSectionHeader>
      <ul className="list-disc space-y-1 px-6 mt-2.5">
        {items.map((item, index) => (
          <li key={`reflection-${item?.id || index}`}>
            {item[descriptionFieldName] || 'No relevant info found'}
          </li>
        ))}
      </ul>
    </div>
  )
}

function ReflectorContent({ plan, advices, reflections, stepBoundary, hasIssues, mainIssue }) {
  const descriptionContent = []
  const isSuperAdmin = useIsUserSuperAdmin()

  if (hasIssues) {
    descriptionContent.push(
      <div
        key="step-main-issue"
        className="mt-5 space-y-1 whitespace-pre-line px-4  text-base-foreground"
      >
        <ActionSectionHeader>Main issue</ActionSectionHeader>
        <ul className="list-disc space-y-1 px-6 mt-2.5">
          <li className="">
            <div className="font-dm-mono">Issue analysis:</div>
            <div className="">{mainIssue.issue_analysis}</div>
          </li>
          <li className="">
            <div className="font-dm-mono">Issue resolution:</div>
            <div className="">{mainIssue.issue_resolution}</div>
          </li>
        </ul>
      </div>
    )
  }

  if (reflections && Array.isArray(reflections) && reflections.length > 0 && isSuperAdmin) {
    descriptionContent.push(<ReflectorItem title="Reflections" items={reflections} />)
  }

  if (stepBoundary && isSuperAdmin) {
    descriptionContent.push(
      <div
        key="step-boundry"
        className="space-y-1 whitespace-pre-line  px-4 text-base-foreground"
      >
        <ActionSectionHeader>Step Boundary</ActionSectionHeader>
        <div className="">{stepBoundary}</div>
      </div>
    )
  }

  if (Array.isArray(plan) && plan.length > 0) {
    descriptionContent.push(<ReflectorItem title="Plan" items={plan} />)
  }

  if (advices && Array.isArray(advices) && advices.length > 0 && isSuperAdmin) {
    descriptionContent.push(
      <ReflectorItem title="Advice" items={advices} descriptionFieldName="relevant_info" />
    )
  }

  return <AActionContentDescription content={descriptionContent} />
}

export default function ReflectorAction({ action, isLast = false, isInteractive = false }) {
  const reflections = action?.args?.reflection || action?.args?.Reflection
  const reflection = action?.args?.reflection_summary || 'No reflection summary'
  const advices = action?.args?.advices || []
  const plan = action?.args?.plan || []
  const stepBoundary = action?.args?.step_boundary
  const hasIssues = action?.args?.has_issues || false
  const mainIssue = action?.args?.main_issue

  const descriptionContent = [
    <div key="reflection" className="whitespace-pre-line">
      {reflection}
    </div>,
  ]

  return (
    <GenericAction
      title={action?.args?.comment ?? 'Reflector'}
      action={action}
      actionIcon={LandPlot}
      isLast={isLast}
      isInteractive={isInteractive}
    >
      <AActionContentGenericLayout>
        <AActionContentDescription content={descriptionContent} />
        <ReflectorContent
          reflections={reflections}
          advices={advices}
          plan={plan}
          stepBoundary={stepBoundary}
          hasIssues={hasIssues}
          mainIssue={mainIssue}
        />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

ReflectorAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
  isInteractive: PropTypes.bool,
}
