import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.tsx'
import { NotepadText } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import { MarkdownContent } from '@/components/actions/components/MarkdownContent'

export default function DocumentAction({ action, isLast = false, isInteractive = false }) {
  const detailsContent = action.args?.documentation || 'No documentation provided'
  return (
    <GenericAction
      action={action}
      actionIcon={NotepadText}
      isLast={isLast}
      isInteractive={isInteractive}
      title={action.args?.comment || 'Create Stage documentation'}
    >
      <AActionContentGenericLayout>
        <MarkdownContent detailsContent={detailsContent} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

DocumentAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
  isInteractive: PropTypes.bool,
}
