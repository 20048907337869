import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.tsx'
import { FilePen } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import { MarkdownContent } from '@/components/actions/components/MarkdownContent'
import { getOutputVersion } from '@/lib/action-utils'

export default function CreateCompanyBriefAction({
  action,
  isLast = false,
  isInteractive = false,
}) {
  let details = []
  if (action.outputs && action.outputs.length > 0) {
    action.outputs.forEach(output => {
      const outputContent = getOutputVersion(output) === 2 ? output?.content : output
      details.push(outputContent.company_brief)
    })
  } else {
    details.push('No documentation provided')
  }
  const detailsContent = details.join('\n')
  return (
    <GenericAction
      action={action}
      actionIcon={FilePen}
      isLast={isLast}
      isInteractive={isInteractive}
      title={action.args?.comment || 'Create company brief'}
    >
      <AActionContentGenericLayout>
        <MarkdownContent detailsContent={detailsContent} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

CreateCompanyBriefAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
  isInteractive: PropTypes.bool,
}
