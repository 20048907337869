import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.tsx'
import { FileJson2 } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentTerminal from '@/components/actions/components/AActionContentTerminal.jsx'
import { getOutputVersion } from '@/lib/action-utils'

export default function PreciselyEditFileAction({ action, isLast = false, isInteractive = false }) {
  const filePath = action?.args?.path
  let detailsContent = `## PATH:\n${filePath}\n\n## COMMENT:\n${action?.args?.comment}\n\n## EDIT INSTRUCTIONS:\n${action?.args?.edit_instructions}`

  if (action.outputs) {
    detailsContent += '## OUTPUTS:\n'
    action?.outputs?.reduceRight((_, output) => {
      if (output == null) {
        return
      }
      let outputContent = output
      if (getOutputVersion(output) === 2) {
        outputContent = output?.content
        detailsContent += `${output?.message} \n`
      }
      if (outputContent) {
        if (typeof outputContent === 'object') {
          const outputEntries = Object.entries(outputContent).map(
            ([key, value]) => `${key}: ${value}`
          )
          outputEntries.forEach(entry => {
            detailsContent += `${entry}`
          })
        } else {
          detailsContent += `${outputContent}`
        }
      }
    }, null)
  }

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={FileJson2}
      isLast={isLast}
      isInteractive={isInteractive}
      title={action?.args?.comment}
    >
      <AActionContentGenericLayout>
        <AActionContentTerminal
          content={action?.args?.path}
          terminalOutputs={action?.args?.edit_instructions}
          errored={action?.outputs?.error}
        />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

PreciselyEditFileAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
  isInteractive: PropTypes.bool,
}
