import PropTypes from 'prop-types'

const actionShape = PropTypes.shape({
  actionId: PropTypes.string,
  iterationId: PropTypes.string,
  projectId: PropTypes.string,
  index: PropTypes.number,
  status: PropTypes.string,
  stepId: PropTypes.string,
  elementId: PropTypes.string,
  startedAt: PropTypes.number,
  endedAt: PropTypes.number,
  updatedAtRelative: PropTypes.string,
  shouldBeDeleted: PropTypes.bool,
  elapsedTime: PropTypes.number,
  name: PropTypes.string,
  repoURI: PropTypes.string,
  startCommitHash: PropTypes.string,
  endCommitHash: PropTypes.string,
  args: PropTypes.shape({
    messages: PropTypes.arrayOf(PropTypes.string),
    tools: PropTypes.arrayOf(PropTypes.string),
  }),
})

export { actionShape }
export const colorDictShape = PropTypes.shape({
  color: PropTypes.string,
  description: PropTypes.string,
})

export const contentShape = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object])),
])
