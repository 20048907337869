import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.tsx'
import { SquareTerminal } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentTerminal from '@/components/actions/components/AActionContentTerminal.jsx'
import { getOutputVersion } from '@/lib/action-utils'

export default function RunCommandAction({ action, isLast = false, isInteractive = false }) {
  const terminalOutputs = []
  let detailsContent = `## ACTION:\n${action?.args?.comment}\n\n## COMMAND:\n${action?.args?.command || action?.args?.cmd}\n\n`

  const outputs = action?.outputs
  let didError = false
  if (Array.isArray(outputs)) {
    detailsContent += '## OUTPUTS:\n'
    outputs?.reduceRight((_, output) => {
      console.info(output)
      if (output == null) {
        return
      }
      let outputContent = output
      if (getOutputVersion(output) === 2) {
        outputContent = output?.content
        terminalOutputs.push(`${output?.message} \n\n`)
      }
      if (typeof outputContent === 'object' && outputContent !== null) {
        const outputEntries = Object.entries(outputContent)
          .filter(([, v]) => v != null && v !== '')
          .map(([key, value]) => `${key}: ${value}`)
        outputEntries.forEach(entry => {
          detailsContent += `- ${entry}\n`
          terminalOutputs.push(`- ${entry}\n`)
        })
        if (!didError) {
          didError = output.status !== 'success'
        }
      } else {
        terminalOutputs.push(`\n${output?.content} \n`)
      }
    }, null)
  }

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      terminalOutputs={terminalOutputs}
      actionIcon={SquareTerminal}
      isLast={isLast}
      isInteractive={isInteractive}
      title={action?.args?.comment ?? 'Run command'}
    >
      <AActionContentGenericLayout>
        <AActionContentTerminal
          content={'$ ' + action?.args?.cmd}
          terminalOutputs={terminalOutputs}
          errored={didError}
        />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

RunCommandAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
  isInteractive: PropTypes.bool,
}
