import PropTypes from 'prop-types'
import { FlagTriangleRight } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import GenericAction from '@/components/actions/GenericAction.tsx'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'

export default function UserSuggestionAction({ action, isLast = false, isInteractive = false }) {
  const suggestion = action?.args?.text
  return (
    <GenericAction
      action={action}
      detailsContent={suggestion}
      actionIcon={FlagTriangleRight}
      isLast={isLast}
      isInteractive={isInteractive}
      title="Suggestion"
    >
      <AActionContentGenericLayout>
        <AActionContentDescription content={suggestion} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}


UserSuggestionAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
  isInteractive: PropTypes.bool,
}
