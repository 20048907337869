import { userCommandsCollectionRef } from '@/services/Firebase.ts'
import { UserCommandSchema } from '@/services/firebase_shared_types.ts'
import { useQuery } from '@tanstack/react-query'
import { getDocs, onSnapshot, query, where } from 'firebase/firestore'
import { useEffect } from 'react'

export function useUserCommandsQuery(iterationId: string | null) {
  const query = useQuery({
    queryKey: ['user-commands', iterationId],
    queryFn: async () => {
      if (!iterationId) {
        return []
      }
      const query = queryUserCommands(iterationId)
      const result = await getDocs(query)
      const parsedData = result.docs
        .map(doc => doc.data())
        .map(data => UserCommandSchema.safeParse(data))

      parsedData
        .filter(result => !result.success)
        .forEach(result => console.error('failed to parse user command.', result.error))

      return parsedData
        .filter(result => result.success)
        .map(result => result.data)
        .sort((a, b) => a.createdAt.valueOf() - b.createdAt.valueOf()) // yes, it might be out of order for some reason
    },
  })

  useLiveUserCommands(iterationId, query.refetch)

  return query
}

function useLiveUserCommands(iterationId: string | null, onChange: () => void) {
  useEffect(() => {
    if (!iterationId) {
      return
    }

    const userCommandsQuery = queryUserCommands(iterationId)

    const unsubscribe = onSnapshot(
      userCommandsQuery,
      snapshot => {
        snapshot.docChanges() // whatever, we just want to refetch.
        onChange()
      },
      error => {
        console.error(
          '[Error] Listener encountered an error when quering for live user commands:',
          error.code,
          error.message
        )
      }
    )

    return unsubscribe
  }, [iterationId, onChange])
}

function queryUserCommands(iterationId: string) {
  return query(userCommandsCollectionRef, where('iterationId', '==', iterationId))
}
