import React, { useMemo } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import ReflectorAction from '@/components/actions/ReflectorAction.jsx'
import { actionShape } from '@/components/propTypes.js'
import CreateFileAction from '@/components/actions/CreateFileAction/CreateFileAction.tsx'
import RunCommandAction from '@/components/actions/RunCommandAction/RunCommandAction.tsx'
import DoneAction from '@/components/actions/DoneAction.jsx'
import GetFileContentsAction from '@/components/actions/GetFileContentsAction/GetFileContentsAction.tsx'
import GenerateImageAction from '@/components/actions/GenerateImageAction'
import WebsiteVisualInspectorAction from '@/components/actions/WebsiteVisualInspectorAction.tsx'
import InteractiveWebUIAction from '@/components/actions/InteractiveWebUIAction.tsx'
import GenerateImageListAction from '@/components/actions/GenerateImageListAction'
import DocumentAction from '@/components/actions/DocumentAction/DocumentAction.tsx'
import ExtractBrandColorsAction from '@/components/actions/ExtractBrandColorsAction/ExtractBrandColorsAction.tsx'
import RunDaemonAction from '@/components/actions/RunDaemonAction/RunDaemonAction.tsx'
import StepDoneAction from '@/components/actions/StepDoneAction.jsx'
import ReflectAndPlanAction from '@/components/actions/ReflectAndPlanAction.jsx'
import DefaultAction from '@/components/actions/DefaultAction/DefaultAction.tsx'
import BrowserAction from '@/components/actions/BroswerAction/BrowserAction.tsx'
import UserSuggestionAction from '@/components/actions/UserSuggestionAction.jsx'
import ResearchAction from '@/components/actions/ResearchAction/ResearchAction.tsx'
import ListFilesAction from '@/components/actions/ListFilesAction/ListFilesAction.tsx'
import LlmRequestAction from '@/components/actions/LlmRequestAction.jsx'
import LlmResponseAction from '@/components/actions/LlmResponseAction.jsx'
import HintsAction from '@/components/actions/HintsAction.jsx'
import UpdateEnvVariableAction from '@/components/actions/UpdateEnvVariableAction/UpdateEnvVariableAction.tsx'
import ContentfulUpdatePromotionalEntriesAction from '@/components/actions/ContentfulUpdatePromotionalEntriesAction.jsx'
import ContenfulUploadAndLinkImagesAction from '@/components/actions/ContenfulUploadAndLinkImagesAction.jsx'
import StopDaemonAction from '@/components/actions/StopDaemonAction/StopDaemonAction.tsx'
import PreciselyEditFileAction from '@/components/actions/PreciselyEditFileAction/PreciselyEditFileAction.tsx'
import { ActionErrorBoundary } from '@/components/actions/components/ActionErrorBoundary.tsx'
import CreateCompanyBriefAction from '@/components/actions/CreateCompanyBriefAction/CreateCompanyBriefAction.tsx'
import { ACTION_NAMES } from '@/const/actions.ts'
import ExtractBrandLogotypeAction from '@/components/actions/ExtractBrandLogotypeAction.tsx'
import HttpRequestAction from '@/components/actions/HttpRequestAction/HttpRequestAction.tsx'
import TerminalSendAction from '@/components/actions/TerminalSendAction/TerminalSendAction.tsx'
import OverwriteFileAction from '@/components/actions/OverwriteFileAction/OverwriteFileAction.tsx'

import { ACTION_STATUSES } from '@/const/const'
import { useIsUserSuperAdmin } from '@/stores/useStore.js'

const ACTION_COMPONENTS = {
  [ACTION_NAMES.REFLECTOR]: ReflectorAction,
  [ACTION_NAMES.CREATE_FILE]: CreateFileAction,
  [ACTION_NAMES.OVERWRITE_FILE]: OverwriteFileAction,
  [ACTION_NAMES.RUN_COMMAND]: RunCommandAction,
  [ACTION_NAMES.TERMINAL_SEND]: TerminalSendAction,
  [ACTION_NAMES.DONE]: DoneAction,
  [ACTION_NAMES.GET_FILE_CONTENTS]: GetFileContentsAction,
  [ACTION_NAMES.GENERATE_IMAGE]: GenerateImageAction,
  [ACTION_NAMES.GENERATE_IMAGE_LIST]: GenerateImageListAction,
  [ACTION_NAMES.DOCUMENT]: DocumentAction,
  [ACTION_NAMES.EXTRACT_BRAND_COLORS]: ExtractBrandColorsAction,
  [ACTION_NAMES.RUN_DAEMON]: RunDaemonAction,
  [ACTION_NAMES.STOP_DAEMON]: StopDaemonAction,
  [ACTION_NAMES.STEP_DONE]: StepDoneAction,
  [ACTION_NAMES.REFLECT]: ReflectAndPlanAction,
  [ACTION_NAMES.BROWSER]: BrowserAction,
  [ACTION_NAMES.USER_SUGGESTION]: UserSuggestionAction,
  [ACTION_NAMES.RESEARCH]: ResearchAction,
  [ACTION_NAMES.LIST_FILES]: ListFilesAction,
  [ACTION_NAMES.LLM_REQUEST]: LlmRequestAction,
  [ACTION_NAMES.LLM_RESPONSE]: LlmResponseAction,
  [ACTION_NAMES.EXTRACT_BRAND_LOGOTYPE]: ExtractBrandLogotypeAction,
  [ACTION_NAMES.HINTS]: HintsAction,
  [ACTION_NAMES.UPDATE_ENV_VARIABLE]: UpdateEnvVariableAction,
  [ACTION_NAMES.CONTENTUFUL_UPDATE_PROMOTIONAL_ENTRIES]: ContentfulUpdatePromotionalEntriesAction,
  [ACTION_NAMES.CONTENTUFUL_UPLOAD_AND_LINK_IMAGES]: ContenfulUploadAndLinkImagesAction,
  [ACTION_NAMES.PRECISELY_EDIT_FILE]: PreciselyEditFileAction,
  [ACTION_NAMES.CREATE_COMPANY_BRIEF]: CreateCompanyBriefAction,
  [ACTION_NAMES.HTTP_REQUEST]: HttpRequestAction,
  [ACTION_NAMES.WEBSITE_VISUAL_INSPECTOR]: WebsiteVisualInspectorAction,
  [ACTION_NAMES.INTERACTIVE_WEB_UI_TOOL]: InteractiveWebUIAction,
  default: DefaultAction,
}

function MActionListItem({ action, isLast = false, isInteractive = false }) {
  const isSuperAdmin = useIsUserSuperAdmin()
  const ActionComponent = useMemo(() => {
    if (ACTION_COMPONENTS[action.name]) {
      return ACTION_COMPONENTS[action.name]
    } else {
      let actionTypeAndSubtype = `${action.type}-${action.subtype}`
      if (ACTION_COMPONENTS[actionTypeAndSubtype]) {
        return ACTION_COMPONENTS[actionTypeAndSubtype]
      }
      return ACTION_COMPONENTS['default']
    }
  }, [action])

  if (!isSuperAdmin && action.status === ACTION_STATUSES.AWAITING) {
    return null
  }

  return (
    <ActionErrorBoundary action={action}>
      {ActionComponent && (
        <ActionComponent action={action} isLast={isLast} isInteractive={isInteractive} />
      )}
    </ActionErrorBoundary>
  )
}

MActionListItem.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
  isInteractive: PropTypes.bool,
}

/**
 * Checks if props for the action list item changed or not
 * @param {object} newProps
 * @param {object} odProps
 * @returns {boolean}
 */
function areActionPropsEqual(odProps, newProps) {
  // use lodash deep compare to check if action props are equal
  return _.isEqual(odProps, newProps)
}

export default React.memo(MActionListItem, areActionPropsEqual)
