import { useToast } from '@/components/ui/use-toast.js'
import { ITERATION_COMMANDS } from '@/const/const.ts'
import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics.js'
import { useCallback, useState } from 'react'
import { UserInputCommand, useSendUserCommand } from '../sendUserCommand.ts'

export function usePromptMutation(iterationId: string | null, roleName: string | null) {
  const { toast } = useToast()
  const sendCommand = useSendUserCommand(iterationId, 'prompt')

  const callSendCommand = useCallback(
    async (prompt: string) => {
      console.info('call send command to rolemessage', prompt)
      const command: UserInputCommand = {
        command: ITERATION_COMMANDS.ROLE_MESSAGE,
        commandArgs: {
          role: roleName,
          content: {
            choice: 'update',
            message: prompt,
          },
        },
      }
      if (!roleName) {
        console.error('call send command: no role name provided')
        return
      }
      sendCommand.mutate(command, {
        onError(error) {
          console.error('Error creating continuation prompt', error)
          toast({
            variant: 'destructive',
            title: 'Error extending iteration 😔',
            description: 'Try refreshing the page or contact Proofs team.',
          })
        },
        onSuccess() {
          analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_EXTEND, {
            iterationId: iterationId,
            prompt: prompt,
          })
        },
      })
    },
    [sendCommand, toast, iterationId, roleName]
  )

  const extend = useCallback(
    async (prompt: string) => {
      const command: UserInputCommand = {
        command: ITERATION_COMMANDS.EXTEND,
        commandArgs: {
          iteration_id: iterationId,
          prompt,
        },
      }
      sendCommand.mutate(command, {
        onError(error) {
          console.error('Error creating continuation prompt', error)
          toast({
            variant: 'destructive',
            title: 'Error extending iteration 😔',
            description: 'Try refreshing the page or contact Proofs team.',
          })
        },
        onSuccess() {
          analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_EXTEND, {
            iterationId: iterationId,
            prompt: prompt,
          })
        },
      })
    },
    [sendCommand, toast, iterationId]
  )

  const validate = useCallback(async () => {
    console.info('validate')
    if (!roleName) {
      console.error('validate: no role name provided')
      return
    }
    const command: UserInputCommand = {
      command: ITERATION_COMMANDS.ROLE_MESSAGE,
      commandArgs: {
        role: roleName,
        content: {
          choice: 'validate',
          message: '',
        },
      },
    }
    sendCommand.mutate(command, {
      onError(error) {
        console.error('Error validating iteration plan', error)
        toast({
          variant: 'destructive',
          title: 'Error extending iteration 😔',
          description: 'Try refreshing the page or contact Proofs team.',
        })
      },
      onSuccess() {
        analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_EXTEND, {
          iterationId: iterationId,
          prompt: prompt,
        })
      },
    })
  }, [sendCommand, toast, iterationId, roleName])

  return {
    mutation: sendCommand,
    mutate: callSendCommand,
    extend,
    validate,
    isLoading: sendCommand.isPending,
  }
}
