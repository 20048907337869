import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.tsx'
import { FileStack } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentTerminal from '@/components/actions/components/AActionContentTerminal.jsx'
import { getOutputVersion } from '@/lib/action-utils'

export default function ListFilesAction({ action, isLast = false, isInteractive = false }) {
  let detailsContent = null
  action?.outputs?.reduceRight((_, output) => {
    if (output == null) {
      return
    }
    detailsContent = `${detailsContent ?? ''}`
    let outputContent = output
    if (getOutputVersion(output) === 2) {
      detailsContent += `${output.message}\n\n`
      outputContent = output.content
    }
    outputContent.files.forEach((file, index) => {
      detailsContent += `${index + 1}: ${file}\n`
    })
    detailsContent += '\n'
  }, null)

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={FileStack}
      isLast={isLast}
      isInteractive={isInteractive}
      title={action?.args?.comment ?? 'List files'}
    >
      <AActionContentGenericLayout>
        <AActionContentTerminal
          content={'$ ' + action?.args?.path}
          terminalOutputs={detailsContent}
          errored={action?.outputs?.error}
        />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

ListFilesAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
