import { MarkdownContent } from '@/components/actions/components/MarkdownContent.tsx'
import { RoleHistoryEntry } from '@/lib/iterations/useIterationRoleHistory.ts'

export function CurrentSpecifications({ data }: { data: RoleHistoryEntry['output'] }) {
  const specifications =
    typeof data === 'string' ? data : (data.specifications ?? data.markdown ?? '')
  const changeSummary = typeof data === 'object' ? data?.update_summary : undefined

  return (
    <div>
      <MarkdownContent detailsContent={specifications} />
      {changeSummary && <p className="text-sm text-muted-foreground">{changeSummary}</p>}
    </div>
  )
}
