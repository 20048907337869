import { MarkdownContent } from '@/components/actions/components/MarkdownContent.tsx'
import { CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible.tsx'
import { Collapsible } from '@/components/ui/collapsible.tsx'
import { RoleHistoryEntry } from '@/lib/iterations/useIterationRoleHistory.ts'
import { cn } from '@/lib/utils.ts'
import { ChevronRight } from 'lucide-react'
import { useState } from 'react'

export function PastSpecifications({
  data,
  forceOpen = false,
}: {
  data: RoleHistoryEntry['existing']
  forceOpen?: boolean
}) {
  const [isOpenState, setIsOpenState] = useState(false)

  const specifications =
    typeof data === 'string' ? data : (data.specifications ?? data.markdown ?? '')
  const changeSummary = typeof data === 'object' ? data?.update_summary : undefined

  const isOpen = isOpenState || forceOpen
  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpenState}>
      <CollapsibleTrigger className="flex items-center space-x-2 text-muted-foreground">
        <ChevronRight className={cn('size-4', isOpen && 'rotate-90')} /> Previous specifications
      </CollapsibleTrigger>
      <CollapsibleContent>
        <MarkdownContent detailsContent={specifications} />
        {changeSummary && <p className="text-sm text-muted-foreground">{changeSummary}</p>}
      </CollapsibleContent>
    </Collapsible>
  )
}
