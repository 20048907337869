import { useMemo, useRef } from 'react'
import _, { cloneDeep } from 'lodash'
import { DateTime } from 'luxon'
import { Timestamp } from 'firebase/firestore'

import { MStep } from '@/components/molecules/iteration-details/MStep.tsx'
import MActionsList from '@/components/molecules/iteration-details/MActionsList.jsx'

import { useIterationQuery } from '@/lib/iterations/useIterationQuery.ts'
import { IterationAction, IterationStep } from '@/services/firebase_shared_types.ts'
import { ACTION_STATUSES, ActionStatus, STEP_STATUSES } from '@/const/const.ts'

export default function OIterationDetails({
  iterationId,
  lastTick = Date.now(),
}: {
  iterationId: string
  lastTick?: number
}) {
  const iterationQuery = useIterationQuery(iterationId)
  const { isLoading: isIterationLoading, data: iteration } = iterationQuery

  const elements = useMemo(() => {
    if (!iterationQuery.isSuccess) {
      return []
    }
    const elements = Object.entries(iterationQuery.data.elements).map(([key, value]) => {
      return { id: key, ...value }
    })
    // also order elements by index
    return elements.sort((a, b) => a.index - b.index)
  }, [iteration])

  const elementsWithRelativeTime = useMemo(() => {
    // lastTick is used to force a re-render every minute
    if (lastTick && elements) {
      return elements?.map(element => {
        const dateCreated = new Timestamp(element?.createdAt)?.toDate() || Date.now()
        const dateUpdated = new Timestamp(element?.updatedAt)?.toDate() || Date.now()
        return {
          ...element,
          updatedAt: dateUpdated,
          createdAt: dateCreated,
          updatedAtRelative: DateTime.fromJSDate(dateUpdated).toRelative(),
          repoURI: iteration?.repoURI,
        }
      })
    }
  }, [elements, iteration?.repoURI, lastTick])

  const groupedElements = useMemo(() => {
    const result = []
    if (iteration && elementsWithRelativeTime) {
      if (iteration?.continuationPrompts?.length ?? 0 > 0) {
        let lastContinuationPromptIndex = 0
        for (const continuationPrompt of iteration.continuationPrompts ?? []) {
          const afterElementIndex = continuationPrompt?.afterElementIndex
          // all elements with index less than the continuation prompt afterElementIndex should be grouped
          const elementsToGroup = elementsWithRelativeTime.filter(
            element =>
              element.index > lastContinuationPromptIndex && element.index <= afterElementIndex
          )
          result.push({
            elements: elementsToGroup,
            prompt: continuationPrompt?.prompt,
            createdBy: continuationPrompt?.createdBy,
          })
          lastContinuationPromptIndex = afterElementIndex
        }
      } else {
        // push all elements to first group
        result.push({ elements: elementsWithRelativeTime })
      }
    }

    return result
  }, [iteration, elementsWithRelativeTime])

  // to future me: restructure this so scroll snapping actually works?

  return (
    <div className="contents">
      {groupedElements.map((group, index) => {
        return (
          <div key={'group' + index} className="contents ">
            {group.elements.map((element, index) => {
              return (
                <div className="contents" key={'element' + index}>
                  {Object.values(element.steps)
                    .filter(
                      step =>
                        step.status !== STEP_STATUSES.AWAITING &&
                        step.status !== STEP_STATUSES.DRAFT
                    )
                    .sort((a, b) => a.index - b.index)
                    .map(step => {
                      return <StepWithActions key={'step' + step.index} step={step} />
                    })}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

function StepWithActions({ step }: { step: IterationStep }) {
  const stepRef = useRef<HTMLDivElement | null>(null)

  const rawHeight = stepRef.current?.clientHeight ?? 0
  const height = isNaN(rawHeight) ? 0 : rawHeight

  return (
    <div
      className="contents"
      style={{
        '--element-top-offset': `${57 + height}px`,
      }}
    >
      <div className="sticky top-[57px] z-20 bg-base-background p-4 pb-0" ref={stepRef}>
        <MStep idx={step.index + 1} step={step} showDetails={false} />
      </div>
      <ActionsWithSTDOUTCombined
        actions={Object.values(step.actions).sort((a, b) => a.index - b.index)}
      />
    </div>
  )
}

// whatever ths is.
function ActionsWithSTDOUTCombined({ actions }: { actions: IterationAction[] }) {
  const actionsWithSTDOUTCombined = useMemo(() => {
    // go over all actions. If action.subtype is `async_function_output`
    // add action.outputs.stdout to the action.terminal_outputs [] where actionId equals action.args.sourceActionId
    // don't mutate the original actions array and don't add async_function_output to filtered

    if (!actions) {
      return []
    }

    const withoutOutput = actions.filter(action => action.subtype !== 'async_function_output')

    const filtered = cloneDeep(
      withoutOutput
        // show just one pending action, so we don't fill the screen with pending ones.
        .filter((action, idx) => {
          if (idx === 0) {
            return true
          }
          const statusOfPrevAction = withoutOutput[idx - 1].status as ActionStatus
          const undesiredStatuses: ActionStatus[] = [
            ACTION_STATUSES.AWAITING,
            ACTION_STATUSES.DRAFT,
          ]
          if (
            undesiredStatuses.includes(action.status as ActionStatus) &&
            undesiredStatuses.includes(statusOfPrevAction)
          ) {
            return false
          }
          return true
        })
    )
    const asyncFunctionOutputs = actions.filter(
      action => action.subtype === 'async_function_output'
    )

    asyncFunctionOutputs.forEach(asyncFunctionOutput => {
      const action = filtered.find(
        action => action.actionId === asyncFunctionOutput?.args?.sourceActionId
      )
      if (action) {
        if (!action.terminal_outputs) {
          action.terminal_outputs = []
        }
        asyncFunctionOutput?.outputs.forEach(output => {
          action.terminal_outputs.push(output?.stdout || 'Std out missing')
        })
      }
    })
    return filtered
  }, [actions])

  return <MActionsList showAllActionsInStep actions={actionsWithSTDOUTCombined} />
}
