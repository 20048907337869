import { useEffect, useState } from 'react'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import { Button } from '@/components/ui/button.tsx'
import { ELEMENT_STATUSES, ElementStatus, STEP_STATUSES } from '@/const/const.ts'
import { getActiveRole } from '@/lib/iterations/useIterationActiveRole.ts'
import { cn } from '@/lib/utils.ts'
import { IterationStep } from '@/services/firebase_shared_types.ts'
import { Circle, ChevronDown } from 'lucide-react'
import { useIsUserSuperAdmin } from '@/stores/useStore'
import { MStatusIconBox, ROLE_REGISTRY_STATUS } from './MStatusIconBox.tsx'

export function MStep({ idx, step, showDetails = true }: MCompactStepProps) {
  const isSuperAdmin = useIsUserSuperAdmin()
  const [isExpanded, setIsExpanded] = useState(false)
  const roleRegistry = getActiveRole(step.role_registry)
  const isActiveRole = roleRegistry?.status === 'waiting_for_message'
  const status = (isActiveRole ? ROLE_REGISTRY_STATUS : step.status) as ElementStatus
  const isDone = status === ELEMENT_STATUSES.DONE
  const detailsTitle = isDone ? 'REPORT' : 'OBJECTIVES'

  const isRunninng = step.status === ELEMENT_STATUSES.RUNNING

  useEffect(() => {
    // if running, we want to expanded. If not running, we want to set it to collapsed.
    setIsExpanded(isRunninng)
  }, [isRunninng, setIsExpanded])

  if (!isSuperAdmin && step.status === ELEMENT_STATUSES.AWAITING) {
    return null
  }

  return (
    <Collapsible
      open={isExpanded}
      onOpenChange={setIsExpanded}
      className={cn(
        'animate-appear w-full rounded border text-base-foreground transition-colors',
        isExpanded || !showDetails ? 'breder-border bg-white' : 'bg-transparents border-transparent'
      )}
    >
      <CollapsibleTrigger asChild>
        <div className="flex w-full cursor-pointer flex-row items-center gap-4 p-1">
          <MStatusIconBox status={status} />
          <div className="flex flex-1 items-center justify-between text-sm">
            <h4 className="">
              <span
                className={cn(
                  'font-pp-supply-sans',
                  status === ELEMENT_STATUSES.FAILED ? 'text-red-400' : 'text-muted-foreground'
                )}
              >
                {step.elementIndex}.{step.index}.
              </span>{' '}
              <span
                className={cn(
                  'text-sm font-medium',
                  status === ELEMENT_STATUSES.FAILED
                    ? 'text-red-500'
                    : isExpanded || status === STEP_STATUSES.RUNNING || !showDetails
                      ? 'text-base-foreground'
                      : 'text-stone-500'
                )}
              >
                {step.name}
              </span>
            </h4>
            {showDetails && step.description && (
              <Button variant="ghost" size="icon" className="ml-auto shrink-0">
                <ChevronDown
                  className={cn(
                    'size-4 text-muted-foreground transition-transform',
                    isExpanded && 'rotate-180'
                  )}
                />
              </Button>
            )}
          </div>
        </div>
      </CollapsibleTrigger>

      {showDetails && (
        <CollapsibleContent>
          <div className="px-5 py-4 text-sm text-base-foreground">
            <p>{step.description}</p>
            {isDone && step.output && (
              <>
                <DetailsHeader title={detailsTitle} />
                <Report content={step.output} />
              </>
            )}
          </div>
        </CollapsibleContent>
      )}
    </Collapsible>
  )
}
function DetailsHeader({ title }: { title: string }) {
  return (
    <div className="mt-5 flex items-center gap-2 font-dm-mono text-xs text-stone-900">
      {title} <div className="grow border-b border-border" />
    </div>
  )
}

function Objectives({ objectives }: { objectives: string[] }) {
  return (
    <ul className="mt-2 space-y-1 text-sm">
      {objectives.map(objective => (
        <li key={objective} className="flex items-center gap-2">
          <Circle className="size-4 text-border" />
          {objective}
        </li>
      ))}
    </ul>
  )
}

type PydanticModel = {
  _type: 'pydantic'
  class: 'StepOutput'
  value: string // stringified json
}
function Report({ content }: { content: string | PydanticModel }) {
  if (content === null) {
    return null
  }
  let report: string
  if (typeof content === 'string') {
    report = content // doesn't really happen
  } else {
    // it's a serialized pydantic model
    const output = JSON.parse(content.value)
    report = output.report
  }

  return <div className="mt-2 text-sm">{report}</div>
}

type MCompactStepProps = {
  idx: number
  step: IterationStep
  showDetails?: boolean
}
